import React, { useEffect } from "react";
import { useSelector, useDispatch } from "react-redux";
import { getCircularGroups } from "./api";
import { Table, Alert } from "antd";
import ActionsRow from "./ActionsRow";
import { RootState } from "../../App";
const { Column } = Table;

export default function CircularGroupsTable() {
  const dispatch = useDispatch();

  const circularGroups = useSelector(
    (state: RootState) => state.circularGroups.all
  );
  const error = useSelector((state: RootState) => state.circularGroups.error);

  const retailerId = useSelector(
    (state: RootState) => state.currentRetailer.currentRetailer?.id
  );

  useEffect(() => {
    dispatch(getCircularGroups({ retailerId: retailerId }));
  }, [dispatch, retailerId]);

  return error ? (
    <Alert message="Error" description={error} type="error" showIcon />
  ) : (
    <Table
      pagination={{ hideOnSinglePage: true }}
      dataSource={[...circularGroups].reverse().map((el, index) => ({
        ...el,
        key: el.id,
        retailerName: el.retailer.name,
      }))}
    >
      <Column title="Circular Group Name" dataIndex="name" key="name" />
      <Column title="Retailer" dataIndex="retailerName" key="retailerName" />
      <Column
        title=""
        key="actions"
        render={(row: any) => {
          return <ActionsRow key={row.id} row={row} />;
        }}
      />
    </Table>
  );
}
