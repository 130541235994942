import React, { useState, useEffect } from "react";
import { DragDropContext, Droppable, Draggable } from "react-beautiful-dnd";
import { Row, Col, Button, Modal, Typography, Alert, Divider } from "antd";
import styled from "styled-components";
import { Circular, CircularPage } from "../models";
import { CircularGroup } from "../../circular-groups/models";
import { Thumbnail } from "../components/Thumbnail";
import { CircularPageListItem } from "../components/CircularPageListItem";

import { usePagesForCircular } from "./hooks";

const { Text } = Typography;

const FlexContainer = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: flex-start;
  align-items: flex-start;
  max-height: 80vh;
`;

const LeftContainer = styled.div`
  max-width: 480px;
  max-height: calc(80vh - 32px);
  flex: 1;
  padding-top: 8px;
`;

const RightContainer = styled.div`
  flex: 1;
  display: flex;
  flex-direction: column;
  max-height: 80vh;
  height: 80vh;
`;

export function EditButtonAndModal(props: {
  circular: Circular;
  circularGroup: CircularGroup;
}) {
  const { circular, circularGroup } = props;

  const [
    pages,
    ,
    getError,
    ,
    postPagesForCircular,
    postLoading,
    postError,
    //@ts-ignore
  ] = usePagesForCircular(circularGroup.id, circular.id);

  const [showModal, setShowModal] = useState(false);
  const [pagesInGroup, setPagesInGroup] = useState(pages as CircularPage[]);

  useEffect(() => {
    setPagesInGroup(pages);
  }, [pages]);

  const getItemStyle = (snapshot: any, draggableStyle: any) => {
    return {
      // some basic styles to make the items look a bit nicer
      userSelect: "none",
      margin: `0 0 8px 0`,

      // change background colour if dragging
      background: snapshot.isDragging ? "#ecf6fd" : "white",
      display: "flex",
      flexDirection: "row",
      justifyContent: "space-between",
      alignItems: "center",
      marginBottom: "16px",
      borderRadius: "4px",
      width: "100%",

      ...draggableStyle,
    };
  };

  const reorder = (
    list: CircularPage[],
    startIndex: number,
    endIndex: number
  ): CircularPage[] => {
    const result = Array.from(list);
    const [removed] = result.splice(startIndex, 1);
    result.splice(endIndex, 0, removed);

    return result;
  };

  const onDragEnd = (result: any) => {
    if (result.source.droppableId === "list" && result.destination === null) {
      return;
    }

    if (result.destination?.droppableId === "list") {
      if (result.source.droppableId === "list") {
        setPagesInGroup(
          reorder(pagesInGroup, result.source.index, result.destination.index)
        );
      }

      if (
        result.source.droppableId === "grid" &&
        result.destination.droppableId === "list"
      ) {
        const copy = [...pagesInGroup];

        const targetCP = props.circular.circularPages.find(
          (cp: CircularPage) => cp.id === result.draggableId
        );

        if (targetCP) {
          copy.splice(result.destination.index, 0, targetCP);
        }

        setPagesInGroup(copy);
      }
    }
  };

  const removeItem = (index: number) => {
    const copy = [...pagesInGroup];
    copy.splice(index, 1);
    setPagesInGroup(copy);
  };

  const handleAddAllPagesClick = () => {
    setPagesInGroup(circular.circularPages);
  };

  useEffect(() => {
    if (showModal) {
      document.querySelector("body")?.classList.add("dont-scroll");
    } else {
      document.querySelector("body")?.classList.remove("dont-scroll");
    }
  }, [showModal]);

  const handleModalClose = () => {
    setShowModal(false);
    //resetState
    setPagesInGroup(pages);
  };

  const handleModalOk = async () => {
    try {
      await postPagesForCircular(pagesInGroup);
      setShowModal(false);
    } catch (e) {}
  };

  return (
    <div
      style={{
        width: "100%",
        display: "flex",
        justifyContent: "flex-end",
        alignItems: "center",
      }}
    >
      <Button type="link" onClick={() => setShowModal(true)}>
        Edit
      </Button>
      <Modal
        maskClosable={false}
        title={`Edit Pages for ${props.circularGroup.name}`}
        visible={showModal}
        onCancel={handleModalClose}
        okText="Save"
        centered
        width="80vw"
        bodyStyle={{
          height: "80vh",
          maxHeight: "80vh",
          overflow: "hidden",
        }}
        onOk={handleModalOk}
        okButtonProps={postLoading ? { loading: true } : undefined}
        destroyOnClose
      >
        <DragDropContext onDragEnd={onDragEnd}>
          <FlexContainer>
            <LeftContainer>
              <div
                style={{
                  height: 32,
                  marginBottom: 16,
                  display: "flex",
                  alignItems: "center",
                }}
              >
                <h3 style={{ marginBottom: 0 }}>{circularGroup.name}</h3>
              </div>
              <Droppable droppableId="list">
                {(provided, snapshot) => (
                  <div
                    {...provided.droppableProps}
                    ref={provided.innerRef}
                    // style={getListStyle(snapshot.isDraggingOver)}
                    style={{
                      height: "calc(80vh - 88px)",
                      overflow: "scroll",
                      marginRight: 16,
                      padding: 16,
                      borderRadius: 5,
                      border: "2px dashed #cfcfcf",
                    }}
                  >
                    {getError && (
                      <>
                        <Alert
                          message="Error"
                          description={getError}
                          type="error"
                          showIcon
                        />
                        <Divider />
                      </>
                    )}
                    {postError && (
                      <>
                        <Alert
                          message="Error"
                          description={postError}
                          type="error"
                          showIcon
                        />
                        <Divider />
                      </>
                    )}
                    {pagesInGroup?.length ? (
                      pagesInGroup.map((cp: CircularPage, index: number) => {
                        return (
                          <Draggable
                            key={cp.id}
                            draggableId={cp.id}
                            index={index}
                          >
                            {(provided, snapshot) => (
                              <div
                                ref={provided.innerRef}
                                {...provided.draggableProps}
                                {...provided.dragHandleProps}
                                style={getItemStyle(
                                  snapshot,
                                  provided.draggableProps.style
                                )}
                              >
                                <CircularPageListItem
                                  circularPage={cp}
                                  index={index}
                                  onRemove={removeItem}
                                />
                              </div>
                            )}
                          </Draggable>
                        );
                      })
                    ) : (
                      <div
                        style={{
                          height: "100%",
                          display: "flex",
                          justifyContent: "center",
                          alignItems: "center",
                        }}
                      >
                        <Text>{`Drop circular pages here`}</Text>
                      </div>
                    )}
                    {provided.placeholder}
                  </div>
                )}
              </Droppable>
            </LeftContainer>
            <Droppable droppableId="grid" direction="horizontal" isDropDisabled>
              {(provided, snapshot) => (
                <RightContainer
                  {...provided.droppableProps}
                  ref={provided.innerRef}
                  style={{ height: "80vh", overflow: "scroll", padding: 8 }}
                >
                  <div
                    style={{
                      display: "flex",
                      justifyContent: "space-between",
                      alignItems: "center",
                      height: 32,
                      marginBottom: 16,
                    }}
                  >
                    <h3 style={{ marginBottom: 0 }}>Circular Pages</h3>
                    <Button
                      type="primary"
                      onClick={handleAddAllPagesClick}
                      disabled={
                        circular.circularPages.length === pagesInGroup.length
                      }
                    >
                      Add All Pages
                    </Button>
                  </div>
                  <Row gutter={32}>
                    {circular.circularPages
                      .filter(
                        (cp: CircularPage) =>
                          !pagesInGroup
                            .map((page: CircularPage) => page.id)
                            .includes(cp.id)
                      )
                      .map((cp: any, index: number) => (
                        <Col
                          xs={24}
                          sm={24}
                          md={24}
                          lg={12}
                          xl={12}
                          xxl={8}
                          key={cp.id}
                        >
                          <Draggable
                            key={cp.id}
                            draggableId={cp.id}
                            index={index}
                          >
                            {(provided, snapshot) => (
                              <div
                                ref={provided.innerRef}
                                {...provided.draggableProps}
                                {...provided.dragHandleProps}
                                style={getItemStyle(
                                  snapshot,
                                  provided.draggableProps.style
                                )}
                              >
                                <Thumbnail circularPage={cp} />
                              </div>
                            )}
                          </Draggable>
                        </Col>
                      ))}
                  </Row>
                  {provided.placeholder}
                </RightContainer>
              )}
            </Droppable>
          </FlexContainer>
        </DragDropContext>
      </Modal>
    </div>
  );
}
