import { createSlice, PayloadAction } from "@reduxjs/toolkit";
import { getConnections, addConnection, editConnection, deleteConnection, runNowConnection, scheduleNowConnection } from "./api";
import { Connection } from "./models";

export type ConnectionState = {
  all: Connection[];
  currentConnection?: Connection;
  loading: boolean;
  error: string | null;
  showAddModal: boolean;
  addConnectionLoading: boolean;
  addConnectionError: string | null;
  showEditModal: string | null;
  showSubmitModal: string | null;
  editConnectionLoading: boolean;
  editConnectionError: string | null;
  showDeleteModal: string | null;
  showViewModal: string | null;
  viewConnectionError: string | null;
  deleteConnectionLoading: boolean;
  deleteConnectionError: string | null;
  runNowConnectionLoading: boolean;
  runNowConnectionError: string | null;
  scheduleNowConnectionLoading: boolean;
  scheduleNowConnectionError: string | null;
  addSuccess: boolean;
  addError: boolean;
  editSuccess: boolean;
  editError: boolean;
  deleteSuccess: boolean;
  deleteError: boolean;
  scheduleSucces: boolean;
  scheduleError: boolean;
};

const initialState: ConnectionState = {
  all: [],
  currentConnection: undefined,
  loading: false,
  error: null,
  addConnectionError: null,
  showAddModal: false,
  showViewModal: null,
  addConnectionLoading: false,
  showEditModal: null,
  showSubmitModal: null,
  editConnectionLoading: false,
  editConnectionError: null,
  showDeleteModal: null,
  deleteConnectionLoading: false,
  deleteConnectionError: null,
  viewConnectionError: null,
  runNowConnectionLoading: false,
  runNowConnectionError: null,
  scheduleNowConnectionLoading: false,
  scheduleNowConnectionError: null,
  addSuccess: false,
  addError: false,
  editSuccess: false,
  editError: false,
  deleteSuccess: false,
  deleteError: false,
  scheduleSucces: false,
  scheduleError: false
};

export const connectionsSlice = createSlice({
  name: "connections",
  initialState,
  reducers: {
    setCurrentConnection: (state, action: PayloadAction<Connection>) => {
      state.currentConnection = action.payload;
      state.editConnectionError = null;
    },
    showAddConnectionModal: (state) => {
      state.showAddModal = true;
    },
    hideAddConnectionModal: (state) => {
      state.showAddModal = false;
      state.addConnectionError = null;
    },
    showEditConnectionModal: (state, action) => {
      state.showEditModal = action.payload;
    },
    showSubmitConnectionModal: (state, action) => {
      state.showSubmitModal = action.payload;
    },
    hideSubmitConnectionModal: (state, action) => {
      state.showSubmitModal = action.payload;
    },
    hideEditConnectionModal: (state) => {
      state.showEditModal = null;
      state.editConnectionError = null;
    },
    showViewConnectionModal: (state, action) => {
      state.showViewModal = action.payload;
    },
    hideViewConnectionModal: (state) => {
      state.showViewModal = null;
      state.viewConnectionError = null;
    },
    showDeleteConnectionModal: (state, action) => {
      state.showDeleteModal = action.payload;
    },
    hideDeleteConnectionsModal: (state) => {
      state.showDeleteModal = null;
      state.deleteConnectionError = null;
    },
    clearConnectionStatus: (state) => {
      state.addSuccess = false;
      state.addError = false;
      state.editSuccess = false;
      state.editError = false;
      state.deleteSuccess = false;
      state.deleteError = false;
      state.scheduleSucces = false;
      state.scheduleError = false;
    }
  },
  extraReducers: (builder) => {
    /* get connections */
    builder.addCase(getConnections.pending, (state) => {
      state.loading = true;
    });
    builder.addCase(getConnections.fulfilled, (state, action) => {
      state.all = action.payload;
      state.loading = false;
      state.editConnectionError = null;
      state.error = null;
    });
    builder.addCase(getConnections.rejected, (state, action) => {
      if (typeof action.payload === "string") {
        state.error = action.payload;
      }
    });

    /* add connection */
    builder.addCase(addConnection.pending, (state) => {
      state.addConnectionLoading = true;
      state.addConnectionError = null;
      state.addSuccess = false;
      state.addError = false;
    });
    builder.addCase(addConnection.fulfilled, (state, action) => {
      state.all = [...state.all, action.payload];
      state.addConnectionLoading = false;
      state.addConnectionError = null;
      state.showAddModal = false;
      state.addSuccess = true;
      state.addError = false;
    });
    builder.addCase(addConnection.rejected, (state, action) => {
      if (typeof action.payload === "string") {
        state.addConnectionError = action.payload;
        state.addConnectionLoading = false;
        state.addSuccess = false;
        state.addError = true;
      }
    });

    /* edit connection */
    builder.addCase(editConnection.pending, (state) => {
      state.editConnectionLoading = true;
      state.editConnectionError = null;
      state.editSuccess = false;
      state.editError = false;
    });
    builder.addCase(editConnection.fulfilled, (state, action) => {
      state.editConnectionLoading = false;
      state.editConnectionError = null;
      state.showEditModal = null;
      state.editSuccess = true;
      state.editError = false;

      state.all = state.all.map((item) => {
        return item;
        // return item.id === action.payload.id ? action.payload : item
      });
    });
    builder.addCase(editConnection.rejected, (state, action) => {
      if (typeof action.payload === "string") {
        state.editConnectionError = action.payload;
        state.editConnectionLoading = false;
        state.editSuccess = false;
        state.editError = true;
      }
    });

    /* delete connection */
    builder.addCase(deleteConnection.pending, (state) => {
      state.deleteConnectionLoading = true;
      state.deleteConnectionError = null;
      state.deleteSuccess = false;
      state.deleteError = false;
    });
    builder.addCase(deleteConnection.rejected, (state, action) => {
      if (typeof action.payload === "string") {
        state.deleteConnectionError = action.payload;
        state.deleteConnectionLoading = false;
        state.deleteSuccess = false;
        state.deleteError = true;
      }
    });
    builder.addCase(deleteConnection.fulfilled, (state, action) => {
      state.all = state.all.filter((item) => item.id !== action.payload);
      state.deleteConnectionLoading = false;
      state.showDeleteModal = null;
      state.deleteConnectionError = null;
      state.deleteSuccess = true;
      state.deleteError = false;
    });

    /* run Now connection */
    builder.addCase(runNowConnection.pending, (state) => {
      state.runNowConnectionLoading = true;
      state.runNowConnectionError = null;
      state.addSuccess = false;
      state.addError = false;
    });
    builder.addCase(runNowConnection.rejected, (state, action) => {
      if (typeof action.payload === "string") {
        state.runNowConnectionError = action.payload;
        state.runNowConnectionLoading = false;
        state.addSuccess = false;
        state.addError = true;
      }
    });
    builder.addCase(runNowConnection.fulfilled, (state, action) => {
      state.all = [...state.all, action.payload];
      state.runNowConnectionLoading = false;
      state.addConnectionError = null;
      state.showAddModal = false;
      state.addSuccess = true;
      state.addError = false;
    });

    /* run Now connection */
    builder.addCase(scheduleNowConnection.pending, (state) => {
      state.scheduleNowConnectionLoading = true;
      state.scheduleNowConnectionError = null;
      state.scheduleSucces = false;
      state.scheduleError = false;
    });
    builder.addCase(scheduleNowConnection.rejected, (state, action) => {
      if (typeof action.payload === "string") {
        state.scheduleNowConnectionError = action.payload;
        state.scheduleNowConnectionLoading = false;
        state.scheduleSucces = false;
        state.scheduleError = true;
      }
    });
    builder.addCase(scheduleNowConnection.fulfilled, (state, action) => {
      // state.all = state.all.filter((item) => item.id !== action.payload.id);
      state.all = [...state.all, action.payload];
      state.showAddModal = false;
      state.scheduleNowConnectionLoading = false;
      // state.showDeleteModal = null;
      state.scheduleNowConnectionError = null;
      state.scheduleSucces = true;
      state.scheduleError = false;
    });
  },
});

export const {
  showAddConnectionModal,
  hideAddConnectionModal,
  showEditConnectionModal,
  showSubmitConnectionModal,
  hideSubmitConnectionModal,
  hideEditConnectionModal,
  showDeleteConnectionModal,
  hideDeleteConnectionsModal,
  showViewConnectionModal,
  hideViewConnectionModal,
  clearConnectionStatus
} = connectionsSlice.actions;
