import React from "react";
import { useDispatch } from "react-redux";
import { showAddRetailerModal } from "./slice";
import { Button } from "antd";
import { PlusOutlined } from "@ant-design/icons";
import styled from "styled-components";
import AddRetailerModal from "./AddRetailerModal";
import RetailersTable from "./RetailersTable";

const Container = styled.div`
  width: 100%;
  padding: 32px;
`;

const HeaderContainer = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
`;

export default function Retailers() {
  const dispatch = useDispatch();

  const handleAddButton = () => {
    dispatch(showAddRetailerModal());
  };

  return (
    <>
      <Container>
        <HeaderContainer>
          <h1>Retailers</h1>
          <Button
            type="primary"
            icon={<PlusOutlined />}
            onClick={handleAddButton}
          >
            Add Retailer
          </Button>
        </HeaderContainer>
        <RetailersTable />
      </Container>
      <AddRetailerModal />
    </>
  );
}
