import React, { useState, useEffect } from "react";
import { useSelector, useDispatch } from "react-redux";
//import { getCirculars } from "../api";
import { getCircularsByid } from "../api";
import { RootState } from "../../../App";
import styled from "styled-components";
import moment from "moment";
import { Button, Spin } from "antd";
import { PlusOutlined } from "@ant-design/icons";
import { Link } from "react-router-dom";
import { showEditCircularModal } from "../slice";
import { EditCircularModal } from "./EditCircularModal";
import { CircularGroupsList } from "./CircularGroupsList";
import { CircularPages } from "./CircularPagesGrid";
import { useParams } from "react-router-dom";
import { Circular } from "../models";
import { AddNewPagesModal } from "./AddNewPagesModal";
import { LoadingOutlined } from "@ant-design/icons";

const antIcon = <LoadingOutlined style={{ fontSize: 24 }} spin />;

const Container = styled.div`
  width: 100%;
  height: 100%;
  padding: 32px;
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
`;

const Header = styled.div`
  flex: 0;
  width: 100%;
  display: flex;
  justify-content: space-between;
  align-items: center;
`;

const HeaderLeft = styled.div`
  display: flex;
  flex-direction: column;
  align-items: flex-start;

  h1 {
    margin-bottom: 0px;
  }
`;

const HeaderRight = styled.div`
  display: flex;
  justify-content: flex-end;
  align-items: center;
`;

const Main = styled.div`
  display: flex;
  margin-top: 16px;
`;

export default function CircularEdit() {
  let { id } = useParams<{ id: string }>();
  const dispatch = useDispatch();
  const [firstRender, setFirstRender] = useState(true);

  const [addPagesModalVisible, setAddPagesModalVisible] = useState(false);

  // const retailerId = useSelector(
  //   (state: RootState) => state.currentRetailer.currentRetailer?.id
  const retailerId = useSelector(
    (state: RootState) => state.currentRetailer.currentRetailer?.id
  );



  useEffect(() => {
    dispatch(getCircularsByid({ id}));
    setFirstRender(false);
  }, [dispatch, retailerId]);

  const circular: Circular | undefined = useSelector((state: RootState) =>
    state.circulars.all.find((circular) => circular.id === id)
  );

  const handleEditClick = () => {
    dispatch(showEditCircularModal(circular?.id));
  };

  const handleAddNewPagesClick = () => {
    setAddPagesModalVisible(true);
  };

  const handleAddNewPagesCancel = () => {
    setAddPagesModalVisible(false);
  };

  return circular && !firstRender ? (
    <>
      <Container>
        <Header>
          <HeaderLeft>
            <h1>
              {circular.name} - {circular.title}
            </h1>
            <h3>
              {moment(circular.activeDate).format("MMM Do YYYY")} -{" "}
              {moment(circular.terminationDate).format("MMM Do YYYY")}
            </h3>
          </HeaderLeft>
          <HeaderRight>
            <Link to="/dashboard/circulars" style={{ marginLeft: 8 }}>
              <Button type="link">Back</Button>
            </Link>
            <Button
              type="primary"
              onClick={handleEditClick}
              style={{ marginLeft: 8 }}
            >
              Edit Circular Details
            </Button>
            <EditCircularModal circular={circular} />

            <Button
              type="primary"
              icon={<PlusOutlined />}
              style={{ marginLeft: 8 }}
              onClick={handleAddNewPagesClick}
            >
              Add Pages
            </Button>
            <AddNewPagesModal
              hideModal={handleAddNewPagesCancel}
              circular={circular}
              visible={addPagesModalVisible}
            />
          </HeaderRight>
        </Header>
        <Main>
          <CircularGroupsList circular={circular} />
          <CircularPages pages={circular.circularPages} />
        </Main>
      </Container>
    </>
  ) : (
    <Container>
      <Spin indicator={antIcon} />
    </Container>
  );
}
