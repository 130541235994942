import React, { useState, useEffect } from "react";
import { useSelector, useDispatch } from "react-redux";
import { RootState } from "../../App";
import { Typography, Row, Col, Button, Spin, Alert } from "antd";
import parse from "html-react-parser";
import { getRecipeOfTheWeek } from "./api";
import { RecipePreviewModal } from "./RecipePreviewModal";

const { Text } = Typography;

export const RecipeOfTheWeek: React.FC = () => {
  const dispatch = useDispatch();
  const recipe = useSelector(
    (state: RootState) => state.recipes.recipeOfTheWeek
  ) as any;
  const loading = useSelector(
    (state: RootState) => state.recipes.getRecipeOfTheWeekLoading
  );
  const [showModal, setShowModal] = useState(false);

  useEffect(() => {
    dispatch(getRecipeOfTheWeek());
  }, [dispatch]);

  return (
    <div style={{ marginBottom: 16 }}>
      <Spin spinning={loading}>
        {recipe ? (
          <>
            <h2>Recipe of the Week</h2>
            <h3>Category: {recipe.recipeCategory.name}</h3>
            <Row gutter={16}>
              <Col xs={24} sm={12}>
                <img src={recipe.image || recipe.content?.details?.images[0]?.hostedLargeUrl} alt="" style={{ maxWidth: "100%" }} />
              </Col>
              <Col xs={24} sm={12}>
                <div>
                  <h2>{recipe.title || recipe.content?.details?.name}</h2>

                  <h3>Preparation: {recipe.preparationMinutes || recipe.content?.details?.totalTime}'</h3>
                  <h3>Cooking: {recipe.cookingMinutes || recipe.content?.description?.text}'</h3>
                  <p>
                    <Text>
                      {/* {parse(recipe.summary, {
                        replace: (node: any) => {
                          if (node.name === "a") {
                            return <span>{node.children[0].data}</span>;
                          }
                        },
                      })} */}
                      {parse(typeof recipe.summary === 'string' ? recipe.summary : '', {
                        replace: (node: any) => {
                          if (node.name === "a") {
                            return <span>{node.children[0].data}</span>;
                          }
                        },
                      })}

                    </Text>
                  </p>
                  <Button type="primary" onClick={() => setShowModal(true)}>
                    Preview
                  </Button>

                  <RecipePreviewModal
                    visible={showModal}
                    onCancel={() => setShowModal(false)}
                    recipe={recipe}
                  />
                </div>
              </Col>
            </Row>
          </>
        ) : loading ? null : (
          <Alert
            message="Warning"
            description="No recipe of the week chosen. Please select one from the Edit Category menu"
            type="warning"
            showIcon
          // closable
          />
        )}
      </Spin>
    </div>
  );
};
