import React from "react";
import { useDispatch, useSelector } from "react-redux";
import { RootState } from "../../App";
import { Modal, Alert, Divider } from "antd";
import { hideDeleteStoreModal } from "./slice";
import { deleteStore } from "./api";

export default function DeleteStoreModal(props: any) {
  const { row } = props;
  const dispatch = useDispatch();

  const visible = useSelector(
    (state: RootState) => state.stores.showDeleteModal
  );

  const loading = useSelector((state: RootState) => state.stores.deleteLoading);
  const error = useSelector((state: RootState) => state.stores.deleteError);

  const handleDelete = () => {
    dispatch(deleteStore(row.id));
  };

  const handleCancel = () => {
    dispatch(hideDeleteStoreModal());
  };

  return (
    <Modal
      maskClosable={false}
      title="Delete Store"
      visible={visible === row.id}
      onCancel={handleCancel}
      okText="Delete"
      okButtonProps={{ danger: true, loading: loading ? loading : undefined }}
      onOk={handleDelete}
      destroyOnClose
    >
      {error && (
        <>
          <Alert message="Error" description={error} type="error" showIcon />
          <Divider />
        </>
      )}
      <h3>Are you sure you want to delete {row.name}?</h3>
    </Modal>
  );
}
