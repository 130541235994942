import React, { useEffect } from "react";
import { Modal, Alert, Divider, notification } from "antd";
import { useDispatch, useSelector } from "react-redux";
import { deleteRetailer } from "./api";
import { RootState } from "../../App";
import { hideDeleteRetailerModal } from "./slice";

export default function DeleteModal(props: {
  row: { name: string; slug: string; id: string };
}) {
  const { row } = props;
  const dispatch = useDispatch();
  const visible = useSelector(
    (state: RootState) => state.retailers.showDeleteModal === row.id
  );
  const loading = useSelector(
    (state: RootState) => state.retailers.deleteRetailerLoading
  );
  const error = useSelector(
    (state: RootState) => state.retailers.deleteRetailerError
  );

  const retailer = useSelector((state: RootState) => state.retailers);

  useEffect(() => {
    if (retailer.deleteSuccess && visible) {
      notification.success({
        message: 'Success Message',
        description: `${row.name} retailer deleted successfully.`
      })
    }
    if (retailer.deleteError && visible) {
      notification.error({
        message: 'Error Message',
        description: `Unable to delete ${row.name} retailer.`
      })
    }
  }, [retailer.deleteError, retailer.deleteSuccess]);

  
console.log("retailer.deleteError : ",retailer.deleteError);
console.log("retailer.deleteSuccess : ",retailer.deleteSuccess);



  const handleDelete = () => {
    dispatch(deleteRetailer(row));
  };

  const handleCancel = () => {
    dispatch(hideDeleteRetailerModal());
  };

  return (
    <Modal
      maskClosable={false}
      title="Delete Retailer"
      visible={visible}
      onCancel={handleCancel}
      okText="Delete"
      onOk={handleDelete}
      okButtonProps={{ danger: true, loading: loading ? loading : undefined }}
      destroyOnClose={true}
    >
      {/* {error && (
        <>
          <Alert message="Error" description={error} type="error" showIcon />
          <Divider />
        </>
      )} */}
      <h3>Are you sure you want to delete {row.name}?</h3>
    </Modal>
  );
}
