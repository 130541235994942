import React, { useEffect } from "react";
import { Modal, Form, Input, Divider, Alert, notification } from "antd";

import { useDispatch, useSelector } from "react-redux";
import { addRetailer } from "./api";
import { RootState } from "../../App";

import { hideAddRetailerModal } from "./slice";
import { slug } from "../common/utils/slug";

export default function AddRetailerModal(props: any) {
  const [form] = Form.useForm();

  const dispatch = useDispatch();

  const visible = useSelector(
    (state: RootState) => state.retailers.showAddModal
  );

  const loading = useSelector(
    (state: RootState) => state.retailers.addRetailerLoading
  );
  const error = useSelector(
    (state: RootState) => state.retailers.addRetailerError
  );
  const retailerAdd = useSelector((state : RootState) => state.retailers);
  const submitForm = (values: any) => {
    dispatch(addRetailer(values));
  };


  useEffect(()=>{
    if(retailerAdd.addSuccess){
      notification.success({
        message: 'Success Message',
        description: "New retailer created successfully."
      })
    }
    if(retailerAdd.addError){
      notification.error({
        message: 'Error Message',
        description: "Unable to create new retailer."
      })
    }
  },[retailerAdd.addSuccess,retailerAdd.addError])

  useEffect(() => {
    form.resetFields();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [visible]);

  const handleModalOk = () => {
    //validates values then sends them to submitForm()
    form
      .validateFields()
      .then((values) => {
        submitForm(values);
      })
      .catch((e) => {});
  };

  const handleCancel = () => {
    form.resetFields();
    dispatch(hideAddRetailerModal());
  };

  const handleFormChange = (changedValues: any, allValues: any) => {
    if (changedValues.name && slug(changedValues.name) !== allValues.slug) {
      form.setFieldsValue({
        name: allValues.name,
        slug: slug(allValues.name),
      });
    }
  };

  const layout = {
    labelCol: { span: 8 },
    wrapperCol: { span: 16 },
  };

  return (
    <Modal
      maskClosable={false}
      title="Add Retailer"
      visible={visible}
      okText="Create"
      onOk={handleModalOk}
      onCancel={handleCancel}
      okButtonProps={loading ? { loading } : undefined}
      destroyOnClose={true}
    >
      {error && (
        <>
          <Alert message="Error" description={error} type="error" showIcon />
          <Divider />
        </>
      )}
      <Form
        {...layout}
        form={form}
        name="addRetailer"
        initialValues={{ name: "" }}
        onValuesChange={handleFormChange}
      >
        <Form.Item
          label="Retailer name"
          name="name"
          rules={[{ required: true, message: "Retailer name is required!" }]}
        >
          <Input />
        </Form.Item>
        <Form.Item
          label="Slug"
          name="slug"
          rules={[{ required: true, message: "Slug is required!" }]}
        >
          <Input />
        </Form.Item>
        <Form.Item label="From" name="from">
          <Input />
        </Form.Item>
        <Form.Item label="Reply To" name="replyTo">
          <Input />
        </Form.Item>
        <Form.Item label="Site Name" name="siteName">
          <Input />
        </Form.Item>
        <Form.Item label="Site Url" name="siteUrl">
          <Input />
        </Form.Item>
        <Form.Item label="Logo url" name="logoUrl">
          <Input />
        </Form.Item>
      </Form>
    </Modal>
  );
}
