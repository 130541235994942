import React from "react";
import { useSelector } from "react-redux";
import { RootState } from "../../App";
import { Form, Input, Select } from "antd";
import { CircularGroup } from "./models";

const { Option } = Select;

const layout = {
  labelCol: { span: 8 },
  wrapperCol: { span: 16 },
};

export default function CircularGroupForm(props: {
  initialValues?: CircularGroup;
  form: any;
}) {
  const { initialValues, form } = props;

  const retailers = useSelector((state: RootState) => state.retailers.all);

  const currentRetailerId = useSelector(
    (state: RootState) => state.currentRetailer.currentRetailer?.id
  );

  return (
    <Form
      {...layout}
      form={form}
      name={`circularGroupForm#${initialValues?.id}`}
      initialValues={
        {
          ...initialValues,
          retailerId: currentRetailerId
            ? currentRetailerId
            : initialValues?.retailer.id,
        } || undefined
      }
    >
      <Form.Item
        label="Circular Group Name"
        name="name"
        rules={[
          { required: true, message: "Circular Group Name is required!" },
        ]}
      >
        <Input />
      </Form.Item>
      {!currentRetailerId && (
        <Form.Item
          label="Retailer"
          name="retailerId"
          rules={[{ required: true, message: "Retailer is required!" }]}
        >
          <Select>
            {retailers.map((retailer) => (
              <Option value={retailer.id}>{retailer.name}</Option>
            ))}
          </Select>
        </Form.Item>
      )}
    </Form>
  );
}
