import { createSlice } from "@reduxjs/toolkit";
import {
  getCircularGroups,
  addCircularGroup,
  editCircularGroup,
  deleteCircularGroup,
} from "./api";
import { CircularGroup } from "./models";

export type CircularGroupsState = {
  all: CircularGroup[];
  loading: boolean;
  error: string | null;
  showAddModal: boolean;
  addLoading: boolean;
  addError: string | null;
  showEditModal: string | null;
  editLoading: boolean;
  editError: string | null;
  showDeleteModal: string | null;
  deleteLoading: boolean;
  deleteError: string | null;
};

const initialState: CircularGroupsState = {
  all: [],
  loading: false,
  error: null,
  addError: null,
  showAddModal: false,
  addLoading: false,
  showEditModal: null,
  editLoading: false,
  editError: null,
  showDeleteModal: null,
  deleteLoading: false,
  deleteError: null,
};

export const circularGroupsSlice = createSlice({
  name: "circularGroups",
  initialState,
  reducers: {
    showAddCircularGroupModal: (state) => {
      state.showAddModal = true;
    },
    hideAddCircularGroupModal: (state) => {
      state.showAddModal = false;
      state.addError = null;
    },
    showEditCircularGroupModal: (state, action) => {
      state.showEditModal = action.payload;
    },
    hideEditCircularGroupModal: (state) => {
      state.showEditModal = null;
      state.editError = null;
    },
    showDeleteCircularGroupModal: (state, action) => {
      state.showDeleteModal = action.payload;
    },
    hideDeleteCircularGroupModal: (state) => {
      state.showDeleteModal = null;
      state.deleteError = null;
    },
  },
  extraReducers: (builder) => {
    /* get */
    builder.addCase(getCircularGroups.pending, (state) => {
      state.loading = true;
      state.error = null;
    });
    builder.addCase(getCircularGroups.fulfilled, (state, action) => {
      state.loading = false;
      state.all = action.payload;
      state.error = null;
    });
    builder.addCase(getCircularGroups.rejected, (state, action) => {
      state.loading = false;
      if (typeof action.payload === "string") {
        state.error = action.payload;
      }
    });

    /* add */
    builder.addCase(addCircularGroup.pending, (state) => {
      state.addLoading = true;
      state.addError = null;
    });
    builder.addCase(addCircularGroup.fulfilled, (state, action) => {
      state.addLoading = false;
      state.addError = null;
      state.showAddModal = false;
      state.all = [...state.all, action.payload];
    });
    builder.addCase(addCircularGroup.rejected, (state, action) => {
      state.addLoading = false;
      if (typeof action.payload === "string") {
        state.addError = action.payload;
      }
    });

    /* edit */
    builder.addCase(editCircularGroup.pending, (state) => {
      state.editLoading = true;
      state.editError = null;
    });
    builder.addCase(editCircularGroup.fulfilled, (state, action) => {
      state.editLoading = false;
      state.editError = null;
      state.showEditModal = null;
      state.all = state.all.map((item) =>
        item.id === action.payload.id ? action.payload : item
      );
    });
    builder.addCase(editCircularGroup.rejected, (state, action) => {
      state.editLoading = false;
      if (typeof action.payload === "string") {
        state.editError = action.payload;
      }
    });

    /* delete */
    builder.addCase(deleteCircularGroup.pending, (state) => {
      state.deleteLoading = true;
      state.deleteError = null;
    });
    builder.addCase(deleteCircularGroup.fulfilled, (state, action) => {
      state.all = state.all.filter((item) => item.id !== action.payload.id);
      state.deleteLoading = false;
      state.showDeleteModal = null;
      state.deleteError = null;
    });
    builder.addCase(deleteCircularGroup.rejected, (state, action) => {
      state.deleteLoading = false;
      if (typeof action.payload === "string") {
        state.deleteError = action.payload;
      }
    });
  },
});

export const {
  showAddCircularGroupModal,
  hideAddCircularGroupModal,
  showEditCircularGroupModal,
  hideEditCircularGroupModal,
  showDeleteCircularGroupModal,
  hideDeleteCircularGroupModal,
} = circularGroupsSlice.actions;
