import { createSlice } from "@reduxjs/toolkit";
import {
  deleteCategory,
  getCategories,
  postCategory,
  putCategory,
} from "./api";
import { Category } from "./models";
import * as R from "ramda";

const initialState = {
  addLoading: false,
  updateLoading: {
    id: "",
    loading: false,
  },
  deleteLoading: {
    id: "",
    loading: false,
  },

  // represents the action was taken and info should be triggered
  addDirty: false,
  updateDirty: false,
  deleteDirty: false,

  data: [] as Category[],
  deleteModalVisible: false,
  error: {
    dirty: false,
    message: "",
  },
};

export const categoriesSlice = createSlice({
  name: "categories",
  initialState,
  reducers: {
    addInfoShowed: (state) => {
      state.addDirty = false;
    },

    updateInfoShowed: (state) => {
      state.updateDirty = false;
    },

    deleteInfoShowed: (state) => {
      state.deleteDirty = false;
    },
    errorShowed: (state) => {
      state.error = {
        dirty: false,
        message: "",
      };
    },
  },
  extraReducers: (builder) => {
    builder.addCase(getCategories.fulfilled, (state, action) => {
      state.data = action.payload;
    });

    builder.addCase(postCategory.fulfilled, (state, action) => {
      const oldData = !!state.data
        ? [...state.data, action.payload]
        : [action.payload];
      state.data = R.sort(
        (a, b) => a.name.toLowerCase().localeCompare(b.name),
        oldData
      );
      state.addLoading = false;
      state.addDirty = true;
    });

    builder.addCase(postCategory.pending, (state, action) => {
      state.addLoading = true;
    });

    builder.addCase(postCategory.rejected, (state, action) => {
      state.addLoading = false;
      state.error = {
        dirty: true,
        message: "There was an error while adding a category",
      };
    });

    builder.addCase(putCategory.fulfilled, (state, action) => {
      state.updateDirty = true;
      state.updateLoading = {
        id: "",
        loading: false,
      };
    });
    builder.addCase(putCategory.pending, (state, action) => {
      state.updateLoading = {
        id: action.meta.arg.id,
        loading: true,
      };
    });
    builder.addCase(putCategory.rejected, (state, action) => {
      state.updateLoading = {
        id: "",
        loading: false,
      };
      state.error = {
        dirty: true,
        message: "There was an error while updating",
      };
    });

    builder.addCase(deleteCategory.fulfilled, (state, action) => {
      state.deleteDirty = true;
      state.deleteLoading = {
        id: "",
        loading: false,
      };
      state.data = R.filter((v) => {
        return v.id !== action.meta.arg.id;
      }, state.data);
    });
    builder.addCase(deleteCategory.pending, (state, action) => {
      state.deleteLoading = {
        id: action.meta.arg.id,
        loading: true,
      };
    });
    builder.addCase(deleteCategory.rejected, (state, action) => {
      state.deleteLoading = {
        id: "",
        loading: false,
      };
      state.error = {
        dirty: true,
        message: "There was an error while deleting",
      };
    });
  },
});
