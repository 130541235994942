import { createSlice, PayloadAction } from "@reduxjs/toolkit";
import { loginUser } from "./api";
import { LoginData, User } from "./models";
import { UserModel } from "../users/models";
type LoginTuple = [LoginData | undefined, User | undefined];

export type UserState = {
  user?: User;
  authData?: LoginData;
  loading: boolean;
  error?: string;
  token?: string;
  inEditCurrentUser?: Partial<UserModel>;
  editCurrentUserModalVisible: boolean;
  changePasswordModalVisible: boolean;
  SettingModalVisible: boolean;
};

const initialState: UserState = {
  user: undefined,
  authData: undefined,
  loading: false,
  error: undefined,
  inEditCurrentUser: undefined,
  editCurrentUserModalVisible: false,
  changePasswordModalVisible: false,
  SettingModalVisible: false,
};

export const loginSlice = createSlice({
  name: "login",
  initialState,
  reducers: {
    setChangePasswordModalVisible: (state, action: PayloadAction<boolean>) => {
      state.changePasswordModalVisible = action.payload;
    },
    setCurrentUserModalVisible: (state, action: PayloadAction<boolean>) => {
      state.editCurrentUserModalVisible = action.payload;
    },
    setSettingModalVisible: (state, action: PayloadAction<boolean>) => {
      state.SettingModalVisible = action.payload;
    },
    setInEditCurrentUser: (
      state,
      action: PayloadAction<Partial<UserModel>>
    ) => {
      state.inEditCurrentUser = action.payload;
    },
    setLoginCreds: (state, action: PayloadAction<LoginData>) => {
      state.authData = action.payload;
    },

  },
});

export const authSlice = createSlice({
  name: "auth",
  initialState,
  reducers: {
    setLoginCreds: (state, action: PayloadAction<LoginData>) => {
      state.authData = action.payload;
    },
    autoLoggedInUser: (
      state,
      action: PayloadAction<{ user: User; authData: LoginData }>
    ) => {
      state.user = action.payload.user;
      state.authData = action.payload.authData;
    },
    logOut: (state) => {
      localStorage.removeItem("loginData");
      localStorage.removeItem("user");
      state.user = undefined;
      state.authData = undefined;
    },
  },
  extraReducers: (builder) => {
    builder.addCase(loginUser.fulfilled, (state, action) => {
      const [loginData, user] = action.payload as LoginTuple;
      state.user = user;
      state.authData = loginData;
      state.error = undefined;
      state.loading = false;
    });
    builder.addCase(loginUser.rejected, (state, action) => {
      state.error = action.payload as string;
      state.loading = false;
    });
    builder.addCase(loginUser.pending, (state) => {
      state.loading = true;
    });
  },
});
