import React from "react";
import { Modal, Form, Input, Alert, Divider, Select } from "antd";

import { useDispatch, useSelector } from "react-redux";
import { RootState } from "../../App";

import { hideViewSubserviceModal } from "./slice";
import { slug } from "../common/utils/slug";
export default function ViewModal(props: {
    row: {
        name: string;
        notes: string;
        id: string;
        website: string;
        uniqueID: string;
        serviceID: number;
    };
}) {
    const { row } = props;
    const dispatch = useDispatch();

    const [form] = Form.useForm();

    const serviceOptions = useSelector((state: RootState) => state.services.all);
    
    const visible = useSelector(
        (state: RootState) => state.subservices.showViewModal === row.id
    );
    const loading = useSelector(
        (state: RootState) => state.subservices.editSubserviceLoading
    );
    const error = useSelector(
        (state: RootState) => state.subservices.editSubserviceError
    );

    //handle cancel
    const handleCancel = () => {
        form.resetFields();
        dispatch(hideViewSubserviceModal());
    };
    // handle  change form
    // const handleFormChange = (changedValues: any, allValues: any) => {
    //     if (changedValues.name && slug(changedValues.name) !== allValues.slug) {
    //         form.setFieldsValue({
    //             name: allValues.name,
    //             slug: slug(allValues.name),
    //         });
    //     }
    // };

    //layout object
    const layout = {
        labelCol: { span: 8 },
        wrapperCol: { span: 16 },
    };

    return (
        <Modal
            maskClosable={false}
            title="Subservice"
            visible={visible}
            cancelText="Close"
            onOk={handleCancel}
            onCancel={handleCancel}
            okButtonProps={loading ? { loading } : undefined}
            destroyOnClose={true}
        >
            {error && (
                <>
                    <Alert message="Error" description={error} type="error" showIcon />
                    <Divider />
                </>
            )}
            <Form
                {...layout}
                form={form}
                name={`editRetailer#${row.id}`}
                initialValues={{
                    name: row.name,
                    notes: row.notes,
                    website: row.website,
                    uniqueID: row.uniqueID,
                    serviceID: row.serviceID
                }}
            >
                <Form.Item
                    label="Subservice name"
                    name="name"
                    rules={[{ required: true, message: "Subservice name is required!" }]}
                >
                    <Input disabled value={row.name} />
                </Form.Item>
                <Form.Item
                    label="Service name"
                    name="serviceName"
                    rules={[{ required: true, message: "Service name is required!" }]}
                >
                    <Select
                        disabled
                        style={{ width: 315 }}
                        defaultValue={row.serviceID}
                    >
                        {serviceOptions.map((option) => <Select.Option key={option.id} value={option.id}>
                            {option.name}
                        </Select.Option>
                        )}
                    </Select>
                </Form.Item>

                <Form.Item
                    label="Website"
                    name="website"
                >
                    <Input disabled value={row.name} />
                </Form.Item>
                <Form.Item
                    label="Unique ID:"
                    name="uniqueID"
                    rules={[{ required: true, message: "Unique ID is required!" }]}
                >
                    <Input disabled value={row.name} />
                </Form.Item>

                <Form.Item label="Notes:" name="notes">
                    <Input.TextArea rows={6} disabled value={row.name} />
                </Form.Item>
            </Form>
        </Modal>
    );
}
