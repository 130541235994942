import React, { useEffect } from "react";
import { Modal, Form, Input, Alert, Divider } from "antd";
import { useDispatch, useSelector } from "react-redux";
import { addRecipesCategory } from "./api";
import { hideAddCategoryModal } from "./slice";
import { RootState } from "../../App";

export const AddRecipesCategoryModal = () => {
  const dispatch = useDispatch();
  const visible = useSelector(
    (state: RootState) => state.recipes.showAddCategoryModal
  );
  const loading = useSelector(
    (state: RootState) => state.recipes.addCategoryLoading
  );
  const error = useSelector(
    (state: RootState) => state.recipes.addCategoryError
  );
  const [form] = Form.useForm();

  const layout = {
    labelCol: { span: 4 },
    wrapperCol: { span: 20 },
  };

  useEffect(() => {
    form.resetFields();
  }, [form, visible]);

  const submitForm = (values: any) => {
    dispatch(addRecipesCategory(values));
  };

  const handleModalOk = () => {
    form
      .validateFields()
      .then((values: any) => submitForm(values))
      .catch((e) => {});
  };

  const handleCancel = () => {
    form.resetFields();
    dispatch(hideAddCategoryModal());
  };

  return (
    <Modal
      maskClosable={false}
      title="Add Recipe Category"
      visible={visible}
      okText="Create"
      onOk={handleModalOk}
      onCancel={handleCancel}
      okButtonProps={loading ? { loading } : undefined}
      destroyOnClose={true}
    >
      {error && (
        <>
          <Alert message="Error" description={error} type="error" showIcon />
          <Divider />
        </>
      )}
      <Form
        {...layout}
        form={form}
        name="addRaddRecipesCategory"
        initialValues={{ name: "" }}
      >
        <Form.Item
          label="Name"
          name="name"
          rules={[{ required: true, message: "Category name is required!" }]}
        >
          <Input />
        </Form.Item>
      </Form>
    </Modal>
  );
};
