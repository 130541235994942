import React from "react";
import { Modal, Form, Alert, Divider } from "antd";
import { UserForm } from "./UserForm";
import { UserModel } from "./models";
import { useDispatch, useSelector } from "react-redux";
import { RootState } from "../../App";
import { postUser, putUser } from "./api";

export const AddEditUserModal = (props: any) => {
  const { visible, onCancel } = props;

  const [form] = Form.useForm();
  const dispatch = useDispatch();
  const inEditUser = useSelector((state: RootState) => state.users.inEditUser);
  const loading = useSelector((state: RootState) => state.users.loading);
  const error = useSelector((state: RootState) => state.users.error);

  const handleSubmit = (values: Partial<UserModel>) => {
    if (inEditUser?.email) {
      dispatch(
        putUser({
          id: inEditUser.id,
          ...values,
        })
      );
    } else {
      dispatch(postUser(values));
    }
  };

  const handleModalOk = () => {
    form
      .validateFields()
      .then((values) => {
        handleSubmit(values);
      })
      .catch((e) => {});
  };

  const handleCancel = () => {
    form.resetFields();
    onCancel();
  };

  return (
    <Modal
      maskClosable={false}
      title={inEditUser?.email ? "Edit User" : "Add User"}
      visible={visible}
      onCancel={handleCancel}
      okText={inEditUser?.email ? "Update" : "Create"}
      okButtonProps={loading ? { loading: true } : undefined}
      confirmLoading={loading}
      onOk={handleModalOk}
    >
      {error && (
        <>
          <Alert message="Error" description={error} type="error" showIcon />
          <Divider />
        </>
      )}
      <UserForm
        form={form}
        onSubmit={handleSubmit}
        initialValues={inEditUser}
      />
    </Modal>
  );
};
