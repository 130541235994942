import { Properties, PropertiesWithoutId } from "./models";
import { epicApiClient } from "../../ApiClient";
import { createAsyncThunk } from "@reduxjs/toolkit";
import { createAuthConfig } from "../auth/api";

interface MyData {
  "property": []
}
// Get Properties
export const getProperties = createAsyncThunk(
  "/api/allproperties",
  async (
    _params: { retailerId?: string },
    thunkAPI
  ): Promise<Properties[] | ReturnType<typeof thunkAPI.rejectWithValue>> => {
    try {
      const propertyResponse = await epicApiClient.get<MyData>(
        // `api/Retailers?retailerId=${_params.retailerId ? _params.retailerId : ""
        // }`,
        `api/allproperties`,
        createAuthConfig()
      );
      const x: Properties[] = propertyResponse.data["property"];
      return x as Properties[];
    } catch (e) {
      if (e.response && e.response.data && e.response.data.status === 400) {
        return thunkAPI.rejectWithValue(
          [].concat.apply([], Object.values(e.response.data.errors)).join(" ")
        );
      }
      return thunkAPI.rejectWithValue(`Error getting properties  ${e?.response?.data?.error} ${e?.response?.data?.message}`);
    }
  }
);

// Add Property
export const addProperty = createAsyncThunk(
  "/api/addproperty",
  async (
    params: PropertiesWithoutId,
    thunkAPI
  ): Promise<Properties | ReturnType<typeof thunkAPI.rejectWithValue>> => {
    try {
      const propertyResponse = await epicApiClient.post<{ message: string, Property: Properties }>(
        "api/addproperty",
        params,
        createAuthConfig()
      );
      return propertyResponse.data.Property;
    } catch (e) {
      if (e.response && e.response.data && e.response.data.status === 400) {
        return thunkAPI.rejectWithValue(
          [].concat.apply([], Object.values(e.response.data.errors)).join(" ")
        );
      }
      return thunkAPI.rejectWithValue(`Error adding properties ${e?.response?.data?.error} ${e?.response?.data?.message}`);
    }
  }
);

// Edit Property
export const editProperty = createAsyncThunk(
  "/api/editproperty",
  async (
    params: Properties,
    thunkAPI
  ): Promise<Properties | ReturnType<typeof thunkAPI.rejectWithValue>> => {
    try {
      const retailerResponse = await epicApiClient.post<{ message: string, Property: Properties }>(
        `api/editproperty`,
        params,
        createAuthConfig()
      );
      return retailerResponse.data.Property;
    } catch (e) {
      if (e.response && e.response.data && e.response.data.status === 400) {
        return thunkAPI.rejectWithValue(
          [].concat.apply([], Object.values(e.response.data.errors)).join(" ")
        );
      }
      return thunkAPI.rejectWithValue(`Error editing property  ${e?.response?.data?.error} ${e?.response?.data?.message}`);
    }
  }
);

// Delete Property
export const deleteProperty = createAsyncThunk(
  "/api/deleteproperty",
  async (
    params: Partial<Properties>,
    thunkAPI
  ): Promise<string | undefined | ReturnType<typeof thunkAPI.rejectWithValue>> => {
    try {
      const response = await epicApiClient.post<{ message: string, ["Status code"]: number }>(
        `api/deleteproperty/${params.id}`,
        createAuthConfig()
      );

      return params.id;
    } catch (e) {
      if (e.response && e.response.data && e.response.data.status === 400) {
        return thunkAPI.rejectWithValue(
          [].concat.apply([], Object.values(e.response.data.errors)).join(" ")
        );
      }
      return thunkAPI.rejectWithValue(`Error deleting property  ${e?.response?.data?.error} ${e?.response?.data?.message}`);
    }
  }
);


export const getPropertiesBySubServiceId = createAsyncThunk(
  "/api/getPropertiesBySubServiceId",
  async (
    _params: { subserviceID?: number },
    thunkAPI
  ): Promise<Properties[] | ReturnType<typeof thunkAPI.rejectWithValue>> => {
    try {
      const propertyResponse = await epicApiClient.get<MyData>(
        // `api/Retailers?retailerId=${_params.retailerId ? _params.retailerId : ""
        // }`,
        `api/GetPropertiesDetail/${_params.subserviceID}`,
        createAuthConfig()
      );
      const x: Properties[] = propertyResponse.data["property"];
      return x as Properties[];
    } catch (e) {
      if (e.response && e.response.data && e.response.data.status === 400) {
        return thunkAPI.rejectWithValue(
          [].concat.apply([], Object.values(e.response.data.errors)).join(" ")
        );
      }
      return thunkAPI.rejectWithValue(`Error getting properties  ${e?.response?.data?.error} ${e?.response?.data?.message}`);
    }
  }
);
