import { Button, Table } from "antd";
import Column from "antd/es/table/Column";
import React from "react";
import { UserModel } from "./models";

export const UserTable = (props: {
  users: UserModel[];
  onEditUser: (user: UserModel) => void;
  onDeleteUser: (user: UserModel) => void;
  hasRetailer?: boolean;
}) => {
console.log(props)

  return (
    <Table
      dataSource={props.users.map((user) => ({ ...user, key: user.id }))}
      pagination={{ hideOnSinglePage: true }}
    >
      <Column title={"First Name"} dataIndex={"firstName"} />
      <Column title={"Last Name"} dataIndex={"lastName"} />
      <Column title={"Email"} dataIndex={"email"} />
      <Column title={"Role"} dataIndex={"role"} />
      {props.hasRetailer && (
        <Column
          title={"Retailer"}
          render={(row) => {
            return row.retailer?.name;
          }}
        />
      )}
      <Column
        title=""
        key="actions"
        render={(row: any) => {
          return (
            <StoresActionsRow
              key={row.id}
              user={row}
              onEditUser={props.onEditUser}
              onDeleteUser={props.onDeleteUser}
            />
          );
        }}
      />
    </Table>
  );
};

export const StoresActionsRow = (props: {
  user: UserModel;
  onEditUser: (user: UserModel) => void;
  onDeleteUser: (user: UserModel) => void;
}) => {
  return (
    <>
      <div style={{ display: "flex", justifyContent: "flex-end" }}>
        <Button
          type="link"
          onClick={() => {
            props.onEditUser(props.user);
          }}
        >
          Edit
        </Button>
        <Button
          type="link"
          danger
          onClick={() => {
            props.onDeleteUser(props.user);
          }}
        >
          Delete
        </Button>
      </div>
    </>
  );
};
