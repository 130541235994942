import React, { useEffect } from "react";
import { useSelector } from "react-redux";
import { RootState } from "../../App";
import { Form, Input } from "antd";
import { UserModel } from "../users/models";
import { FormInstance } from "antd/es/form";

export const EditCurrentUserForm = (props: {
  form: FormInstance;
  onSubmit: (values: Partial<UserModel>) => void;
}) => {
  const { form, onSubmit } = props;
  const initialValues = useSelector((state: RootState) => state.auth.user);

  useEffect(() => {
    props.form.resetFields();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [initialValues]);

  const formItemLayout = {
    labelCol: { span: 8 },
    wrapperCol: { span: 14 },
  };

  return (
    <Form
      {...formItemLayout}
      form={form}
      name="addRetailer"
      initialValues={initialValues}
      onFinish={onSubmit}
    >
      <Form.Item
        label="First Name"
        name="firstName"
        rules={[{ required: true, message: "First name is required!" }]}
      >
        <Input />
      </Form.Item>
      <Form.Item
        label="Last Name"
        name="lastName"
        rules={[{ required: true, message: "Last name is required!" }]}
      >
        <Input />
      </Form.Item>
    </Form>
  );
};
