import React from "react";
import { useSelector, useDispatch } from "react-redux";
import { Modal, Alert, Divider } from "antd";
import { RootState } from "../../App";
import { RecipesCategory } from "./models";
import { hideDeleteCategoryModal } from "./slice";
import { deleteRecipesCategory } from "./api";

interface DeleteRecipesCategoryModalProps {
  category: RecipesCategory;
}

export const DeleteRecipesCategoryModal: React.FC<DeleteRecipesCategoryModalProps> = ({
  category,
}) => {
  const dispatch = useDispatch();
  const visible = useSelector(
    (state: RootState) => state.recipes.showDeleteCategoryModal === category.id
  );
  const loading = useSelector(
    (state: RootState) => state.recipes.deleteCategoryLoading
  );
  const error = useSelector(
    (state: RootState) => state.recipes.deleteCategoryError
  );

  const handleOk = () => {
    dispatch(deleteRecipesCategory(category.id as string));
  };

  const handleCancel = () => {
    dispatch(hideDeleteCategoryModal());
  };

  return (
    <Modal
      visible={visible}
      title="Delete Recipe Category"
      okText="Delete"
      onOk={handleOk}
      okButtonProps={{ danger: true, loading: loading ? true : undefined }}
      onCancel={handleCancel}
    >
      {error && (
        <>
          <Alert message="Error" description={error} type="error" showIcon />
          <Divider />
        </>
      )}
      <h3>Are you sure you want to delete {category.name}?</h3>
    </Modal>
  );
};
