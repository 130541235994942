import React from "react";
import { useDispatch, useSelector } from "react-redux";
import { deleteCircularGroup } from "./api";
import { RootState } from "../../App";
import { hideDeleteCircularGroupModal } from "./slice";

import { Modal, Alert, Divider } from "antd";
import { CircularGroup } from "./models";

export default function DeleteCircularGroupModal(props: {
  row: CircularGroup;
}) {
  const { row } = props;
  const dispatch = useDispatch();

  const visible = useSelector(
    (state: RootState) => state.circularGroups.showDeleteModal === row.id
  );

  const loading = useSelector(
    (state: RootState) => state.circularGroups.deleteLoading
  );

  const error = useSelector(
    (state: RootState) => state.circularGroups.deleteError
  );

  const handleDelete = () => {
    dispatch(deleteCircularGroup(row));
  };

  const handleCancel = () => {
    dispatch(hideDeleteCircularGroupModal());
  };

  return (
    <Modal
      maskClosable={false}
      title="Delete Circular Group"
      visible={visible}
      onCancel={handleCancel}
      okText="Delete"
      onOk={handleDelete}
      okButtonProps={{ danger: true, loading: loading || undefined }}
    >
      {error && (
        <>
          <Alert message="Error" description={error} type="error" showIcon />
          <Divider />
        </>
      )}
      <h3>Are you sure you want to delete {row.name}?</h3>
    </Modal>
  );
}
