import React from "react";
import { Button, Dropdown, Menu } from "antd";
import { UserOutlined } from "@ant-design/icons";

export const UserSettings = (props: {
  handleAccountSettingsClick: () => void;
  handleChangePasswordClick: () => void;
  handleSettingsClick: () => void;
  logOut: () => void;
  userEmail: string;
}) => {
  return (
    <Dropdown
      trigger={["click"]}
      overlay={
        <Menu>
          <Menu.Item onClick={props.handleAccountSettingsClick}>
            <Button type="link" style={{ padding: 0 }}>
              My Profile
            </Button>
          </Menu.Item>
          <Menu.Item onClick={props.handleChangePasswordClick}>
            <Button type="link" style={{ padding: 0 }}>
              Change Password
            </Button>
          </Menu.Item>
          <Menu.Item onClick={props.handleSettingsClick}>
            <Button type="link" style={{ padding: 0 }}>
             Settings
            </Button>
          </Menu.Item>

          <Menu.Item onClick={props.logOut}>
            <Button type="link" style={{ padding: 0 }}>
              Log Out
            </Button>
          </Menu.Item>
        </Menu>
      }
    >
      <div
        style={{ display: "flex", alignItems: "center", marginRight: "2em" }}
      >
        <Button type="link">{props.userEmail}</Button>
        <Button shape="circle" icon={<UserOutlined />} />
      </div>
    </Dropdown>
  );
};
