import React, { useEffect } from "react";
import { Modal, Form, Divider, Alert, Button, Table, Tag } from "antd";

import { useDispatch, useSelector } from "react-redux";
import { RootState } from "../../App";

import { hideSubserviceOfServiceModal } from "./slice";
import { PlusOutlined } from "@ant-design/icons";
import { clearSubservices, showAddSubserviceModal } from "../subservices/slice";
import AddSubserviceModal from "../subservices/AddSubserviceModal";


export default function ShowSubserviceModal(props: any) {
  // const [form] = Form.useForm();
  const {row} = props;
  const dispatch = useDispatch();

  const visible = useSelector(
    (state: RootState) => state.services.showSubserviceOfServiceModal === row.id
  );
  const loading = useSelector(
    (state: RootState) => state.services.showSubserviceOfServiceModalLoading
  );

  const tableLoader = useSelector(
    (state: RootState) => state.subservices.loading
  );
  const error = useSelector(
    (state: RootState) => state.services.showSubserviceOfServiceModalError
  );

  const subservices = useSelector((state: RootState) => state.subservices.subServiceByServiceId);
  
  // handle cancel
  const handleCancel = () => {
    // form.resetFields();
    dispatch(hideSubserviceOfServiceModal());
    dispatch(clearSubservices())
  };


  const layout = {
    labelCol: { span: 8 },
    wrapperCol: { span: 16 },
  };
  // handle add button
  const handleAddButton = () => {
    // dispatch(hideSubserviceOfServiceModal());
    dispatch(showAddSubserviceModal())
  };

  
  const columns = [
    {
      title: '#',
      dataIndex: 'index',
      key: 'index',
      sorter :(a: any, b: any) => {
        if (a.id < b.id) { return -1; }
        if (a.id > b.id) { return 1; }
        return 0;
      }
    },
    {
      title: 'Subservice Name',
      dataIndex: 'name',
      key: 'name',
      sorter :(a: any, b: any) => {        
        if (a.name > b.name) { return -1; }
        if (a.name < b.name) { return 1; }
        return 0;
      }
    },
    {
      title: 'Service Name',
      dataIndex: 'serviceName',
      key: 'serviceName',
      sorter :(a: any, b: any) => {        
        if (a.name > b.name) { return -1; }
        if (a.name < b.name) { return 1; }
        return 0;
      }
    },
    {
      title: 'Website',
      dataIndex: 'website',
      key: 'website',
      sorter :(a: any, b: any) => {        
        if (a.name > b.name) { return -1; }
        if (a.name < b.name) { return 1; }
        return 0;
      }
    },
    {
      title: 'Unique ID',
      dataIndex: 'uniqueID',
      key: 'uniqueID',
      sorter :(a: any, b: any) => {        
        if (a.name > b.name) { return -1; }
        if (a.name < b.name) { return 1; }
        return 0;
      }
    }
  ]

  return (
    <Modal
      maskClosable={false}
      title="Subservices"
      visible={visible}
      okText="OK"
      onOk={handleCancel}
      cancelText="CANCEL"
      onCancel={handleCancel}
      okButtonProps={loading ? { loading } : undefined}
      destroyOnClose={true}
      width={1000}
    >
      {error && (
        <>
          <Alert message="Error" description={error} type="error" showIcon />
          <Divider />
        </>
      )}
      <div>
        <div style={{ display: 'flex', flexDirection: 'row-reverse', marginBottom : 20 }}>
          <Button
            type="primary"
            icon={<PlusOutlined />}
            onClick={handleAddButton}
          >
            Add Subservice
          </Button>
          <AddSubserviceModal />
        </div>
        
        <Table  
        pagination={{ hideOnSinglePage: true }}  
        columns={columns} 
        loading={tableLoader}
        dataSource={subservices.map((item,index)=> ({
          key: index,
          index : index+1,
          name: item.name,
          uniqueID : item.uniqueID,
          serviceName : item.serviceName,
          website : item.website,
          notes: item.notes
        }))}/>
      </div>
    </Modal>
  );
}
