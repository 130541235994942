import { CircularGroup, CircularGroupWithoutId } from "./models";
import { Retailer } from "../retailers/models";
import { apiClient } from "../../ApiClient";
import { createAsyncThunk } from "@reduxjs/toolkit";
import { createAuthConfig } from "../auth/api";
import { RootState } from "../../App";

export const getCircularGroups = createAsyncThunk(
  "api/CircularGroups/Get",
  async (
    _params: { retailerId: string | undefined },
    thunkAPI
  ): Promise<CircularGroup[] | ReturnType<typeof thunkAPI.rejectWithValue>> => {
    try {
      const res = await apiClient.get<CircularGroup[]>(
        `api/CircularGroups?retailerId=${
          _params.retailerId ? _params.retailerId : ""
        }`,
        createAuthConfig()
      );
      return res.data;
    } catch (e) {
      if (e.response && e.response.data && e.response.data.status === 400) {
        return thunkAPI.rejectWithValue(
          [].concat.apply([], Object.values(e.response.data.errors)).join(" ")
        );
      }
      return thunkAPI.rejectWithValue("Error getting circular groups");
    }
  }
);

export const addCircularGroup = createAsyncThunk(
  "api/CircularGroups/Add",
  async (
    params: CircularGroupWithoutId,
    thunkAPI
  ): Promise<CircularGroup | ReturnType<typeof thunkAPI.rejectWithValue>> => {
    try {
      const state = thunkAPI.getState() as RootState;
      const retailer = params.retailerId
        ? state.retailers.all.find(
            (retailer: Retailer) => params.retailerId === retailer.id
          )
        : state.currentRetailer.currentRetailer;
      const res = await apiClient.post<CircularGroup>(
        "api/CircularGroups",
        {
          name: params.name,
          retailer: retailer,
        },
        createAuthConfig()
      );
      return res.data;
    } catch (e) {
      if (e.response && e.response.data && e.response.data.status === 400) {
        return thunkAPI.rejectWithValue(
          [].concat.apply([], Object.values(e.response.data.errors)).join(" ")
        );
      }
      return thunkAPI.rejectWithValue("Error adding circular group");
    }
  }
);

export const editCircularGroup = createAsyncThunk(
  "api/CircularGroups/Edit",
  async (
    params: CircularGroup,
    thunkAPI
  ): Promise<CircularGroup | ReturnType<typeof thunkAPI.rejectWithValue>> => {
    try {
      const state = thunkAPI.getState() as RootState;
      const retailer = params.retailerId
        ? state.retailers.all.find(
            (retailer: Retailer) => params.retailerId === retailer.id
          )
        : state.currentRetailer.currentRetailer;
      const res = await apiClient.put<CircularGroup>(
        `api/CircularGroups/${params.id}`,
        {
          name: params.name,
          retailer: retailer,
        },
        createAuthConfig()
      );
      return res.data;
    } catch (e) {
      if (e.response && e.response.data && e.response.data.status === 400) {
        return thunkAPI.rejectWithValue(
          [].concat.apply([], Object.values(e.response.data.errors)).join(" ")
        );
      }
      return thunkAPI.rejectWithValue("Error editing circular group");
    }
  }
);

export const deleteCircularGroup = createAsyncThunk(
  "api/CircularGroups/Delete",
  async (
    params: CircularGroup,
    thunkAPI
  ): Promise<CircularGroup | ReturnType<typeof thunkAPI.rejectWithValue>> => {
    try {
      const res = await apiClient.delete<CircularGroup>(
        `api/CircularGroups/${params.id}`,
        createAuthConfig()
      );
      return res.data;
    } catch (e) {
      if (e.response && e.response.data && e.response.data.status === 400) {
        return thunkAPI.rejectWithValue(
          [].concat.apply([], Object.values(e.response.data.errors)).join(" ")
        );
      }
      return thunkAPI.rejectWithValue("Error deleting circular group");
    }
  }
);
