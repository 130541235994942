import React, { useEffect, useState } from "react";
import { Modal, Form, Input, Select, notification } from "antd";

import { useDispatch, useSelector } from "react-redux";
import { editRetailer, getRetailers } from "./api";
import { RootState } from "../../App";

import { hideEditRetailerModal, resetMessages } from "./slice";
import { slug } from "../common/utils/slug";

import { getSubservicesByServiceId } from "../subservices/api";
import { Subservice } from "../subservices/models";
import { BASE_URL } from "../../ApiClient";
import _ from 'lodash';
import { createAuthConfig } from "../auth/api";

export default function EditModal(props: {
  row: {
    name: string;
    slug: string;
    id: string;
    from: string;
    replyTo: string;
    siteName: string;
    siteUrl: string;
    logoUrl: string;
    service: string;
    subservice: string;
  };
}) {
  const { row } = props;
  const dispatch = useDispatch();

  const [form] = Form.useForm();

  const visible = useSelector(
    (state: RootState) => state.retailers.showEditModal === row.id
  );
  const editId = useSelector(
    (state: RootState) => state.retailers.showEditModal
  );

  const loading = useSelector(
    (state: RootState) => state.retailers.editRetailerLoading
  );

  const error = useSelector((state: RootState) => state.retailers.editRetailerError);

  const services = useSelector(
    (state: RootState) => state.services.all
  );
  const subservices = useSelector((state: RootState) => state.subservices.subServiceByServiceId);

  const [subServiceOption, setSubServiceOption] = useState<Subservice[]>([]);
  const submitForm = (values: any) => {
    dispatch(editRetailer({ ...row, ...values }));
  };
  const [serviceId, setServiceId] = useState<string>('');
  const [serviceSubservice, setServiceSubservice] = useState<{ serviceId: string, subservices: number[] }[]>([]);
  const [submitDisable, setSubmitDisable] = useState<boolean>(false);
  const [api, contextHolder] = notification.useNotification();
  const retailer = useSelector((state: RootState) => state.retailers);


  useEffect(() => {
    let SubServices: Subservice[] = [];
    let serviceSubArray: { serviceId: string, subservices: number[] }[] = [];
    // setSubServiceOption(SubServices);
    // setServiceSubservice(serviceSubArray);
    row.service?.split(",").forEach(async (id) => {
      let isExist = serviceSubservice.find(item => item.serviceId == id);
      if (!isExist) {
        (async () => {
          const result = await fetch(`${BASE_URL}api/getsubservicedetail/${id}`,createAuthConfig());
          const data = await result.json()
          let subserviceIds = data.subservice.map((item: any) => Number(item.id));
          SubServices = [...SubServices, ...data.subservice];
          serviceSubArray = [...serviceSubArray, { serviceId: id, subservices: subserviceIds }]
          setSubServiceOption(SubServices);
          setServiceSubservice(serviceSubArray);
        })()
        setServiceId(id)
      }
    })
  }, []);

  useEffect(() => {
    if (subservices.length) {
      setSubServiceOption([...subServiceOption, ...subservices]);
      let subserviceIds = subservices.map(item => Number(item.id))
      setServiceSubservice(old => [...old, { serviceId: serviceId, subservices: subserviceIds }]);
    }
  }, [subservices]);



  useEffect(() => {
    if (retailer.editSuccess && visible) {
      api.success({
        message: 'Success Message',
        description: `${row.name} retailer updated successfully.`
      })
      dispatch(resetMessages())
      dispatch(getRetailers({ retailerId: "" }))
      dispatch(hideEditRetailerModal());
    }
    if (retailer.editError && visible) {
      api.error({
        message: 'Error Message',
        description: `Unable to update ${row.name} retailer.`
      })
      dispatch(resetMessages());
      dispatch(getRetailers({ retailerId: "" }))
      dispatch(hideEditRetailerModal());
    }
  }, [retailer.editError, retailer.editSuccess])

  const handleModalOk = () => {
    form
      .validateFields()
      .then((values) => {
        values.service = values.service ? values.service?.join(",") : null
        values.subservice = values.subservice ? values.subservice?.join(",") : null
        dispatch(resetMessages());
        submitForm(values);
      }).catch((e) => { });
  };


  const handleCancel = () => {
    form.resetFields();
    let SubServices: Subservice[] = [];
    let serviceSubArray: { serviceId: string, subservices: number[] }[] = [];
    // setSubServiceOption(SubServices);
    // setServiceSubservice(serviceSubArray);
    row.service?.split(",").forEach(async (id) => {
      let isExist = serviceSubservice.find(item => item.serviceId == id);
      if (!isExist) {
        (async () => {
          const result = await fetch(`${BASE_URL}api/getsubservicedetail/${id}`);
          const data = await result.json()
          let subserviceIds = data.subservice.map((item: any) => Number(item.id));
          SubServices = [...SubServices, ...data.subservice];
          serviceSubArray = [...serviceSubArray, { serviceId: id, subservices: subserviceIds }]

          setSubServiceOption(SubServices);
          setServiceSubservice(serviceSubArray);
        })()
        setServiceId(id)
      }
    })
    dispatch(hideEditRetailerModal());
    setServiceId('')
  };

  const handleFormChange = (changedValues: any, allValues: any) => {
    if (changedValues.name && slug(changedValues.name) !== allValues.slug) {
      form.setFieldsValue({
        name: allValues.name,
        slug: slug(allValues.name),
      });

    }

    if (allValues.name != undefined && allValues.slug != undefined) {
      setSubmitDisable(false);
    } else {
      setSubmitDisable(true);
    }
  };

  const layout = {
    labelCol: { span: 8 },
    wrapperCol: { span: 16 },
  };


  const handleChange = async (value: string) => {
    if (value) {
      setServiceId(value)
      dispatch(getSubservicesByServiceId({ serviceId: Number(value) }))
    }
  };

  const handleChangeSubService = (value: string[]) => {
  }

  const handleServiceDeselect = (value: string) => {
    let service = serviceSubservice.find(item => item.serviceId == value);
    let remainService = serviceSubservice.filter(item => item.serviceId != value);
    setServiceSubservice(remainService);

    let subservices = service?.subservices;
    let updateSubService = subServiceOption?.filter(item => !subservices?.includes(Number(item.id)));
    if (updateSubService != undefined) {
      setSubServiceOption(updateSubService)
      let formValues = form.getFieldsValue();
      const selectedSubservices = formValues.subservice;

      let newSelected = selectedSubservices?.filter((id: string) => !subservices?.includes(Number(id)));

      form.setFieldsValue({ subservice: newSelected })
    }
    else {
      form.resetFields(["subservice"]);
    }
  }


  return (
    <>
      {contextHolder}
      <Modal
        maskClosable={false}
        title="Edit Retailer"
        visible={visible}
        okText="Update"
        onOk={handleModalOk}
        onCancel={handleCancel}
        okButtonProps={{
          loading: loading ? true : false,
          disabled: submitDisable ? true : false,
        }}
        destroyOnClose={true}
      >
        <Form
          {...layout}
          form={form}
          name={`editRetailer#${row.id}`}
          initialValues={{
            name: row.name,
            slug: row.slug,
            from: row.from,
            replyTo: row.replyTo,
            siteName: row.siteName,
            siteUrl: row.siteUrl,
            logoUrl: row.logoUrl,
            service: row.service != "" ? row.service?.split(",") : [],
            subservice: row.subservice != "" ? row.subservice?.split(",") : []
          }}
          onValuesChange={handleFormChange}
        >
          <Form.Item
            label="Retailer name"
            name="name"
            rules={[{ required: true, message: "Retailer name is required!" }]}
          >
            <Input />
          </Form.Item>
          <Form.Item
            label="Slug"
            name="slug"
            rules={[{ required: true, message: "Slug is required!" }]}
          >
            <Input />
          </Form.Item>
          <Form.Item label="From" name="from">
            <Input />
          </Form.Item>
          <Form.Item label="Reply To" name="replyTo">
            <Input />
          </Form.Item>
          <Form.Item label="Site Name" name="siteName">
            <Input />
          </Form.Item>
          <Form.Item label="Site Url" name="siteUrl">
            <Input />
          </Form.Item>
          <Form.Item label="Logo url" name="logoUrl">
            <Input />
          </Form.Item>
          <Form.Item label="Address 1" name="address1">
            <Input />
          </Form.Item>
          <Form.Item label="Address 2" name="address2">
            <Input />
          </Form.Item>
          <Form.Item label="City" name="city">
            <Input />
          </Form.Item>
          <Form.Item label="State" name="state">
            <Input />
          </Form.Item>
          <Form.Item label="Zip" name="zip">
            <Input />
          </Form.Item>
          <Form.Item label="Primary Contact" name="primaryContact">
            <Input />
          </Form.Item>
          <Form.Item label="Primary Email" name="contactEmail">
            <Input />
          </Form.Item>
          <Form.Item label="Primary Phone" name="contactPhone">
            <Input />
          </Form.Item>
          {/* <Form.Item label="Primary Contact" name="primaryContact">
          <Input />
        </Form.Item> */}
          <Form.Item label="Secondary Contact" name="secondaryContact">
            <Input />
          </Form.Item>
          <Form.Item label="Secondary Email" name="secondaryEmail">
            <Input />
          </Form.Item>
          <Form.Item label="Secondary Phone" name="secondaryPhone">
            <Input />
          </Form.Item>
          <Form.Item label="Notes" name="secondaryNotes">
            <Input />
          </Form.Item>
          <Form.Item label="Services" name="service">
            <Select mode="multiple"
              // allowClear
              style={{ width: '100%' }}
              placeholder="Please select"
              onSelect={handleChange}
              onDeselect={handleServiceDeselect}
            >
              {
                services && services.map((item, index) => {
                  return (
                    <Select.Option key={index} value={item.id.toString()}>{item.name}</Select.Option>
                  )
                })
              }
            </Select>
          </Form.Item>
          <Form.Item
            label="Subservices"
            name="subservice"
          >
            <Select mode="multiple"
              // allowClear
              style={{ width: '100%' }}
              placeholder="Please select"
              onChange={handleChangeSubService}
            >
              {
                subServiceOption &&
                _.uniqBy(subServiceOption, "id")
                  .map((item, index) => {
                    return (
                      <Select.Option key={index} value={item.id.toString()}>{item.name}</Select.Option>
                    )
                  })
              }
            </Select>
          </Form.Item>
        </Form>
      </Modal>
    </>
  );
}
