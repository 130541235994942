import React, { useCallback, useEffect, useState } from "react";
import { Alert, Button, Form, Input, Divider } from "antd";
import styled from "styled-components";
import { Redirect, useLocation } from "react-router";
import { useDispatch, useSelector } from "react-redux";
import { StringMap } from "../../types";
import { loginUser } from "./api";
import { RootState } from "../../App";
import { DualRingLoader } from "../common/components/Loader";
import { ForgotEmailModal } from "./ForgotEmailModal";
import { apiClient } from "../../ApiClient";

const layout = {
  labelCol: { span: 8 },
  wrapperCol: { span: 16 },
};

const tailLayout = {
  wrapperCol: { offset: 8, span: 16 },
};

const Layout = styled.div`
  display: flex;
  flex-direction: row;
  height: 100%;
  min-height: 100vh;
`;

const ImageDiv = styled.div`
  flex: 2;
  background-color: darkred;
  display: flex;
  align-items: center;
  justify-content: center;
`;

const LoginDiv = styled.div`
  flex: 1;
  padding: 10px;
  justify-self: center;
  align-self: center;
`;

const LogoImg = styled.img`
  width: auto;
  padding: 10px;
  background-color: white;
  max-height: 210px;
  border-radius: 50%;
`;

const ResetPasswordForm = (props: {
  onResetComplete: () => void;
  email: string;
  token: string;
}) => {
  const [form] = Form.useForm();
  const [error, setError] = useState("");
  const [success, setSuccess] = useState(false);
  const [loading, setLoading] = useState(false);
  const onFinish = useCallback(
    async (fieldsValue: StringMap) => {
      setSuccess(false);
      setLoading(true);
      try {
        await apiClient.post("api/Account/ResetPassword", {
          token: props.token,
          email: props.email,
          password: fieldsValue["password"],
          confirmPassword: fieldsValue["confirmPassword"],
        });
        setLoading(false);
        setError("");
        setSuccess(true);
      } catch (e) {
        setLoading(false);
        if (e.response && e.response.data && e.response.data.status === 400) {
          setError(
            [].concat.apply([], Object.values(e.response.data.errors)).join(" ")
          );
          return;
        }
        setError("Error resetting password");
      }
    },
    [props.email, props.token]
  );
  return (
    <>
      {error && (
        <>
          <Alert message="Error" description={error} type="error" showIcon />
          <Divider />
        </>
      )}
      {success && (
        <Alert
          message="Your password has been sucessfully reset."
          type="success"
          style={{ marginBottom: 20 }}
        />
      )}
      <Form {...layout} name="basic" onFinish={onFinish} form={form}>
        {!success && (
          <>
            <Form.Item label="Email" name="email">
              <Input disabled placeholder={props.email} />
            </Form.Item>
            <Form.Item
              label="New password"
              name="password"
              rules={[{ required: true, message: "New Password is required!" }]}
            >
              <Input.Password />
            </Form.Item>

            <Form.Item
              label="Confirm Password"
              name="confirmPassword"
              rules={[
                { required: true, message: "Confirm Password is required!" },
              ]}
            >
              <Input.Password />
            </Form.Item>
          </>
        )}
        <Form.Item {...tailLayout}>
          {!success ? (
            <Button type="primary" htmlType="submit">
              Reset Password
            </Button>
          ) : (
            <></>
          )}

          <Button
            onClick={() => {
              props.onResetComplete();
            }}
            style={{
              marginLeft: "1em",
              alignSelf: "center",
            }}
          >
            {"Back to Login"}
          </Button>
        </Form.Item>
      </Form>
      {loading && <DualRingLoader />}
    </>
  );
};

const LoginForm = () => {
  const dispatch = useDispatch();
  const [form] = Form.useForm();
  const [forgotModalVisible, setForgotModalVisible] = useState(false);
  const onFinish = useCallback(
    (fieldsValue: StringMap) => {
      dispatch(
        loginUser({
          email: fieldsValue["email"],
          password: fieldsValue["password"],
        })
      );
    },
    [dispatch]
  );
  return (
    <>
      <ForgotEmailModal
        visible={forgotModalVisible}
        onSetVisible={(b) => setForgotModalVisible(b)}
      />
      <Form {...layout} name="basic" onFinish={onFinish} form={form}>
        <Form.Item
          label="Email"
          name="email"
          rules={[{ required: true, message: "Email is required!" }]}
        >
          <Input />
        </Form.Item>

        <Form.Item
          label="Password"
          name="password"
          rules={[{ required: true, message: "Password is required!" }]}
        >
          <Input.Password />
        </Form.Item>
        <Form.Item {...tailLayout}>
          <Button type="primary" htmlType="submit">
            Login
          </Button>
          <Button
            onClick={() => {
              setForgotModalVisible(true);
            }}
            style={{
              marginLeft: "1em",
              alignSelf: "center",
            }}
          >
            {"Forgot password?"}
          </Button>
        </Form.Item>
      </Form>
    </>
  );
};

export const LoginScreenRoute = (props: { authenticated: any }) => {
  const userRole = useSelector((state: RootState) => state.auth.user?.role);

  return props.authenticated ? (
    <Redirect
      to={
        userRole === "Administrator"
          ? "/dashboard/retailers"
          : "/dashboard/stores"
      }
    />
  ) : (
    <Redirect to={"/login"} />
  );
  // return null;
};

export const LoginScreen = () => {
  const authenticated = useSelector((state: RootState) => state.auth.user);
  const error = useSelector((state: RootState) => state.auth.error);
  const loading = useSelector((state: RootState) => state.auth.loading);
  const location = useLocation();

  const [resetPasswordToken, setResetPassworToken] = useState<
    string | undefined
  >();

  const [resetPasswordEmail, setResetPasswordEmail] = useState("");

  useEffect(() => {
    if (location.hash.length > 0) {
      const hash = location.hash;
      const fakeURL = new URL(`https://test.com/test?${hash.substr(1)}`);
      const fakeSearchParams = new URLSearchParams(fakeURL.search);
      const params = {} as { [key: string]: string };

      // @ts-ignore
      for (const [key, value] of fakeSearchParams) {
        params[key] = value || key;
      }

      setResetPassworToken(params.t);
      setResetPasswordEmail(params.e);
    }
  }, [location]);

  const LoginContent = () => (
    <>
      {error && (
        <>
          <Alert message="Error" description={error} type="error" showIcon />
          <Divider />
        </>
      )}
      <LoginForm />
      {loading && <DualRingLoader />}
    </>
  );
  return (
    <>
      <LoginScreenRoute authenticated={authenticated} />
      <Layout>
        <ImageDiv>
          <LogoImg src={require("../../resources/logo.png")} />
        </ImageDiv>
        <LoginDiv>
          {resetPasswordToken ? (
            <ResetPasswordForm
              onResetComplete={() => {
                setResetPassworToken(undefined);
                setResetPasswordEmail("");
              }}
              token={resetPasswordToken}
              email={resetPasswordEmail}
            />
          ) : (
            <LoginContent />
          )}
        </LoginDiv>
        v1.0
      </Layout>
    </>
  );
};
