import { apiClient } from "../../ApiClient";
import { createAsyncThunk } from "@reduxjs/toolkit";
import { createAuthConfig } from "../auth/api";
import { UserModel } from "./models";
import { RootState } from "../../App";
import { Retailer } from "../retailers/models";

export type UserPayload = {
  admins: UserModel[];
  customers: UserModel[];
  retailers: UserModel[];
};

export const getUsers = createAsyncThunk(
  "/api/GetUsers",
  async (_params: { retailerId: string | undefined }, thunkAPI) => {
    try {
      const retailersResponse = await apiClient.get<UserModel[]>(
        `api/Users?retailerId=${_params.retailerId ? _params.retailerId : ""}`,
        createAuthConfig()
      );
     
       
    

      const payload: UserPayload = {
        admins: [],
        customers: [],
        retailers: [],
      };
      for (let u of retailersResponse.data) {
        switch (u.role) {
          case "Administrator":
            payload.admins.push(u);
            break;
          case "Retailer":
            payload.retailers.push(u);
            break;
          case "Customer":
            payload.customers.push(u);
            break;
        }
      }
      return payload;
    } catch (e) {
      if (e.response && e.response.data && e.response.data.status === 400) {
        return thunkAPI.rejectWithValue(
          [].concat.apply([], Object.values(e.response.data.errors)).join(" ")
        );
      }
      return thunkAPI.rejectWithValue("Error getting users");
    }
  }
);

export const postUser = createAsyncThunk(
  "/api/PostUsers",
  async (params: Partial<UserModel>, thunkAPI) => {
    try {
      const body = { ...params };
      const state = thunkAPI.getState() as RootState;
      body.retailer = body.retailer ?? (state.auth.user?.retailer as Retailer);
      if (body.role !== "Customer") {
        body.emailConfirmed = true;
      }
      body.password = body.password ?? "";
      body.confirmPassword = body.confirmPassword ?? "";
      const putResponse = await apiClient.post<UserModel>(
        "api/Users",
        body,
        createAuthConfig()
      );
      thunkAPI.dispatch(
        getUsers({
          retailerId: (thunkAPI.getState() as RootState).currentRetailer
            .currentRetailer?.id,
        })
      );
      return putResponse.data;
    } catch (e) {
      if (e.response && e.response.data && e.response.data.status === 400) {
        return thunkAPI.rejectWithValue(
          [].concat.apply([], Object.values(e.response.data.errors)).join(" ")
        );
      }
      return thunkAPI.rejectWithValue("Error creating user");
    }
  }
);

export const putUser = createAsyncThunk(
  "/api/PutUsers",
  async (params: Partial<UserModel>, thunkAPI) => {
    try {
      const body = { ...params };
      const state = thunkAPI.getState() as RootState;
      body.retailer = body.retailer ?? (state.auth.user?.retailer as Retailer);
      if (body.role !== "Retailer") {
        body.emailConfirmed = true;
      }
      body.password = body.password ?? "";
      body.confirmPassword = body.confirmPassword ?? "";
      const putResponse = await apiClient.put<UserModel>(
        `api/Users/${params.id}`,
        body,
        createAuthConfig()
      );
      thunkAPI.dispatch(
        getUsers({
          retailerId: (thunkAPI.getState() as RootState).currentRetailer
            .currentRetailer?.id,
        })
      );
      return putResponse.data;
    } catch (e) {
      if (e.response && e.response.data && e.response.data.status === 400) {
        return thunkAPI.rejectWithValue(
          [].concat.apply([], Object.values(e.response.data.errors)).join(" ")
        );
      }
      return thunkAPI.rejectWithValue("Error updating user");
    }
  }
);

export const deleteUser = createAsyncThunk(
  "/api/Users",
  async (params: string, thunkAPI) => {
    try {
      const putResponse = await apiClient.delete<UserModel>(
        `api/Users/${params}`,
        createAuthConfig()
      );
      thunkAPI.dispatch(
        getUsers({
          retailerId: (thunkAPI.getState() as RootState).currentRetailer
            .currentRetailer?.id,
        })
      );
      return putResponse.data;
    } catch (e) {
      if (e.response && e.response.data && e.response.data.status === 400) {
        return thunkAPI.rejectWithValue(
          [].concat.apply([], Object.values(e.response.data.errors)).join(" ")
        );
      }
      return thunkAPI.rejectWithValue("Error deleting user");
    }
  }
);

export const resendConfirmationEmail = createAsyncThunk(
  "/api/Account/ResendConfirmationEmail",
  async (params: { email: string }, thunkAPI) => {
    const putResponse = await apiClient.post<any>(
      `api/Account/ResendConfirmationEmail`,
      { email: params.email },
      createAuthConfig()
    );
    return putResponse.data;
  }
);

export const sendResetPasswordEmail = createAsyncThunk(
  "api/Account/ForgotPassword",
  async (params: { email: string }, thunkAPI) => {
    const putResponse = await apiClient.post<any>(
      `api/Account/ForgotPassword`,
      { email: params.email },
      createAuthConfig()
    );
    return putResponse.data;
  }
);

// export const fileUploader = createAsyncThunk(
//   '/api/UserCustomer',
//   async (file:any, thunkAPI) => {
//     const putResponse = await apiClient.post<any>(
//       `/api/UserCustomer`,file,
//       createAuthConfig()
//     );
//     return putResponse.data;
//   }
// )
export const fileUploader=async(file:any)=>{
  debugger
  const putResponse = await apiClient.post<any>(
          `/api/UserCustomer`,file,
          createAuthConfig()
        );
        return putResponse.data;
}