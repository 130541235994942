import React from "react";
import { Modal, Button } from "antd";

import { Magnifier } from "react-image-magnifiers";
import { CircularPage } from "../models";
import { BASE_URL } from "../../../ApiClient";
import "./style.scss";

export const PreviewModal = (props: {
  visible: boolean;
  onCancel: () => void;
  page: CircularPage;
}) => {
  return (
    <Modal
      maskClosable={false}
      title={props.page?.pageImageFileName}
      visible={props.visible}
      onCancel={props.onCancel}
      footer={
        <Button key="cancel" onClick={props.onCancel}>
          Close
        </Button>
      }
      width="640px"
    >
      <Magnifier
        style={{
          maxHeight: "70vh",
          overflow: "hidden",
        }}
        imageSrc={`${BASE_URL}api/CircularPages/${props.page.id}/BigPageImage`}
        // largeImageSrc={`${BASE_URL}api/CircularPages/${props.page.id}/BigPageImage`}
        dragToMove={false}
        className="magnifier-container"
      />
    </Modal>
  );
};
