import { Alert, Statistic } from "antd";
import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import styled from "styled-components";
import { RootState } from "../../App";
import { getQuota } from "./api";
import { QuotaInterface } from "./models";

const Container = styled.div`
  flex: 1;
  display: flex;
  flex-direction: column;
  margin-bottom: 32px;
`;

const SubContainer = styled.div`
  display: flex;
  flex-direction: row;
  width: 300px;
  justify-content: space-between;
`;

export const Quota = () => {
  const dispatch = useDispatch();
  const [fristRender, setFirstRender] = useState(true);

  const data = useSelector(
    (Store: RootState) => Store.emails.quota
  ) as QuotaInterface;

  const status = useSelector((Store: RootState) => Store.emails.statusGetQuota);

  useEffect(() => {
    setFirstRender(false);
    dispatch(getQuota());
  }, [dispatch]);

  return fristRender ? null : status.progress === "success" ? (
    <div
      style={{
        display: "flex",
        flexDirection: "column",
        marginTop: "32px",
        marginBottom: "32px",
        width: "100%",
        // alignItems: "flex-start",
      }}
    >
      <Container>
        <h3 style={{ alignSelf: "flex-start" }}>Daily Quota</h3>
        <SubContainer>
          <Statistic
            style={{ width: "64px" }}
            title="Total"
            value={data.perDay}
          />
          <Statistic
            style={{ width: "64px" }}
            title="Retailer"
            value={data.perRetailerDay}
          />
          <Statistic
            style={{ width: "64px" }}
            title="IP"
            value={data.perIPDay}
          />
        </SubContainer>
      </Container>

      <Container
        style={{
          flex: 1,
          display: "flex",
          flexDirection: "column",
        }}
      >
        <h3 style={{ alignSelf: "flex-start" }}>Hourly Quota</h3>
        <SubContainer>
          <Statistic
            style={{ width: "64px" }}
            title="Total"
            value={data.perHour}
          />
          <Statistic
            style={{ width: "64px" }}
            title="Retailer"
            value={data.perRetailerHour}
          />
          <Statistic
            style={{ width: "64px" }}
            title="IP"
            value={data.perIPHour}
          />
        </SubContainer>
      </Container>
    </div>
  ) : status.progress === "error" ? (
    <Alert message="Error" description={status.message} type="error" showIcon />
  ) : null;
};
