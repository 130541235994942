import React, { useEffect } from "react";
import { Modal, Alert, Divider, notification } from "antd";
import { useDispatch, useSelector } from "react-redux";
import { deleteConnection, getConnections } from "./api";
import { RootState } from "../../App";
import { clearConnectionStatus, hideDeleteConnectionsModal } from "./slice";

export default function DeleteModal(props: {
  row: { name: string;  id: string };
}) {
  const { row } = props;
  const dispatch = useDispatch();
  const visible = useSelector((state: RootState) => state.connections.showDeleteModal === row.id);
  const loading = useSelector((state: RootState) => state.connections.deleteConnectionLoading);
  const error = useSelector((state: RootState) => state.connections.deleteConnectionError);
  
  const {deleteSuccess,deleteError} = useSelector((state : RootState) => state.connections);
 
  useEffect(()=>{
    if(deleteSuccess){
      notification.success({
        message: 'Success Message',
        description: `${row.name} connection deleted successfully.`
      })
      dispatch(clearConnectionStatus())
    }
    if(deleteError){
      notification.error({
        message: 'Error Message',
        description: `Unable to delete ${row.name} connection.`
      })
      dispatch(clearConnectionStatus())
    }
  },[deleteError,deleteSuccess])

  // handle Delete
  const handleDelete = () => {
    dispatch(deleteConnection(row));
  };
  // handle Cancel
  const handleCancel = () => {
    dispatch(hideDeleteConnectionsModal());
  }; 

  return (
    <Modal
      maskClosable={false}
      title="Delete Connection"
      visible={visible}
      onCancel={handleCancel}
      okText="Delete"
      onOk={handleDelete}
      okButtonProps={{ danger: true, loading: loading ? loading : undefined }}
      destroyOnClose={true}
    >
      {error && (
        <>
          <Alert message="Error" description={error} type="error" showIcon />
          <Divider />
        </>
      )}
      <h3>Are you sure you want to delete {row.name}?</h3>{" "}
    </Modal>
  );
}
