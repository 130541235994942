import React, { ChangeEvent, useEffect, useState } from "react";
import {
  Modal,
  Form,
  Input,
  Divider,
  Alert,
  Select,
  Button,
  Radio,
  DatePicker,
  notification,
} from "antd";
import moment, { Moment } from "moment";
import AddRetailerModal from "../retailers/AddRetailerModal";
import { useDispatch, useSelector } from "react-redux";
import { runNowConnection, scheduleNowConnection } from "./api";
import { RootState } from "../../App";

import { clearConnectionStatus, hideAddConnectionModal } from "./slice";
import { RadioChangeEvent } from "antd/lib/radio";
import { DatePickerProps } from "antd/lib/date-picker";
import { TimePicker } from "antd";
import radioUnchecked from "../../resources/radio-unchecked.png";
import radioChecked from "../../resources/radio-checked.png";
import { showAddRetailerModal } from "../retailers/slice";
import { clearProperties, showAddPropertyModal } from "../properties/slice";
import { resetRetailerService, showAddServiceModal } from "../services/slice";
import { clearSubservices, showAddSubserviceModal } from "../subservices/slice";
import AddPropertyModal from "../properties/AddPropertyModal";
import AddSubserviceModal from "../subservices/AddSubserviceModal";
import AddServiceModal from "../services/AddServiceModal";
// import { getServices, getServiceByRetailerId } from "../services/api";
// import { getSubservices } from "../subservices/api";
import { getProperties, getPropertiesBySubServiceId } from "../properties/api";
// import { getSubservicesByServiceId } from "../subservices/api"
import { BASE_URL } from "../../ApiClient";
import { Service } from "../services/models";
import { Subservice } from "../subservices/models";
import { Properties } from "../properties/models";
import { createAuthConfig } from "../auth/api";
const { Option } = Select;

export default function AddConnectionModal(props: any) {
  [];
  const [form] = Form.useForm();
  const [selectedTime, setSelectedTime] = useState<Moment | null>(
    moment(new Date())
  );
  const [appendOrReplace, setAppendOrReplace] = React.useState("append");
  const [pageTitle, setPageTitle] = useState("Page")
  const [retailer, setRetailer] = React.useState<String | null>(null);
  const [spreadsheetURL, setSpreadsheetURL] = useState("");
  const [fromDate, setFromDate] = useState<Moment | null>(moment(new Date().setDate(new Date().getDate() - 10)));
  const [toDate, setToDate] = useState<Moment | null>(
    moment(new Date())
  );
  const [beginningCount, setBeginningCount] = useState(1);
  const [beginningType1, setBeginningType] = useState("days");
  const [beginning1Date, setBeginning1Date] = useState<Moment | null>(moment(new Date()));
  const [endingCount, setEndingCount] = useState(1);
  const [endingType, setEndingType] = useState("days");
  const [endingDate, setEndingDate] = useState<Moment | null>(
    moment(new Date().setDate(new Date().getDate()))
  );
  const [rangeUncheckedImg, setRangeUncheckedImg] = useState(1);
  const [intervalUncheckedImg, setIntervalUncheckedImg] = useState(1);
  const [everyWeek, setEveryWeek] = useState("monday");
  const [everyWeekAtTime, setEveryWeekAtTime] = useState<Moment | null>(
    moment(new Date())
  );
  const [everyDayCount, setEveryDayCount] = useState(1);
  const [everyDayDayType, setEveryDayDayType] = useState("day");
  const [everyDayMonthOrYearType, setEveryMonthOrYearType] = useState("month");
  const [everyTimeType, setEveryTimeType] = useState("hours");
  const [everyTimeCount, setEveryTimeCount] = useState(1);
  const [dateRangeValue, setDateRangeValue] = React.useState(1);
  const [lastCount, setLastCount] = React.useState("1");
  const [lastCountType, setLastCountType] = React.useState("days");
  const [selectedDateRange, setSelectedDateRange] = React.useState("1");
  const [selectedInterval, setSelectedInterval] = useState(1);
  const [validStatus, setValidStatus] = useState({ name: false, retailerID: false, sheetUrl: false, pageName: false });
  const [connectionName, setConnectionName] = useState({
    retailerPart: "",
    servicePart: "",
    subServicePart: "",
    propertyPart: "",
  })
  const retailers = useSelector((state: RootState) => state.retailers.all);
  const properties = useSelector((state: RootState) => state.properties.propertyBySubService);

  const { addSuccess, addError, scheduleError, scheduleSucces } = useSelector((state: RootState) => state.connections);
  const [abbreviation, setAbbreviation] = useState("st");

  const dispatch = useDispatch();
  const [subserviceOptions, setSubserviceOptions] = useState<Subservice[]>([]);
  const services = useSelector((state: RootState) => state.services.all);
  const [retailerServices, setRetailerServices] = useState<Service[]>([]);
  const [subserviceArray, setSubserviceArray] = useState<string[]>([]);
  const [propertyOptions, setPropertyOptions] = useState<Properties[]>([]);
  const [facebookPages, setFacebookPages] = useState<string[]>([])
  const { runNowConnectionLoading: runNowLoading,
    scheduleNowConnectionLoading: scheduleNowLoading,
    showAddModal
  } = useSelector((state: RootState) => state.connections)

  useEffect(() => {
    if (showAddModal) {
      (async () => {
        const result = await fetch(`${BASE_URL}api/GetFacebbokPageList`, createAuthConfig());
        const connection_data  = await result.json();
        const pagesName = connection_data.data.map((item: any) => item.name);
        const sortedPages = pagesName.sort((a: string, b: string) => {
          let fa = a.toLowerCase(),
            fb = b.toLowerCase();
          if (fa < fb) {
            return -1;
          }
          if (fa > fb) {
            return 1;
          }
          return 0;
        });

        setFacebookPages(sortedPages)
      })()
    }
  }, [showAddModal]);

  useEffect(() => {
    if (retailerServices.length > 0)
      setPropertyOptions(properties)
  }, [properties]);

  useEffect(() => {
    if (addSuccess) {
      notification.success({
        message: 'Success Message',
        description: "New connection created successfully."
      })
      dispatch(clearConnectionStatus())
      setEveryTimeType("hours")
      setEveryMonthOrYearType("month")

      setRetailerServices([]);
      setSubserviceOptions([]);
      setPropertyOptions([]);
      setSubserviceArray([]);
    }
    if (addError) {
      notification.error({
        message: 'Error Message',
        description: "Unable to create new connection."
      })
      dispatch(clearConnectionStatus())

      setRetailerServices([]);
      setSubserviceOptions([]);
      setPropertyOptions([]);
      setSubserviceArray([]);
    }
  }, [addSuccess, addError]);

  useEffect(() => {
    if (scheduleSucces) {
      notification.success({
        message: 'Success Message',
        description: "New connection scheduled successfully."
      })
      dispatch(clearConnectionStatus())
      setEveryTimeType("hours")
      setEveryMonthOrYearType("month")
    }
    if (scheduleError) {
      notification.error({
        message: 'Error Message',
        description: "Unable to scheduled new connection."
      })
      dispatch(clearConnectionStatus())
    }
  }, [scheduleSucces, scheduleError]);

  //handle change beginning date
  const onChangeBeginningOn: DatePickerProps["onChange"] = (
    date,
    dateString
  ) => {
    setBeginning1Date(date);
    form.setFieldsValue({
      selectedDateRange: "3",
      beginningDate: dateString,
    });
  };
  //handle change ending date
  const onChangeEndingOn: DatePickerProps["onChange"] = (date, dateString) => {
    setEndingDate(date);
    form.setFieldsValue({
      selectedDateRange: "4",
      endingDate: dateString,
    });
    setEndingDate(date);
  };



  const error = useSelector(
    (state: RootState) => state.connections.addConnectionError
  );

  //handle Submit form
  const submitForm = (values: any, isRunNow: boolean) => {
    isRunNow
      ? dispatch(runNowConnection(values))
      : dispatch(scheduleNowConnection(values));
  };
  useEffect(() => {
    form.resetFields();
  }, [showAddModal]);


  // handle modal ok
  const handleModalOk = (isRunNow: any) => () => {
    //validates values then sends them to submitForm()
    if (isRunNow === null) {
      return false
    }

    form
      .validateFields()
      .then((values) => {



        //Check here for run now and schedule now
        const copy = { ...values };
        // date range
        // 1
        copy.lastCount = 0;
        copy.lastCountType = "string";
        // 2
        copy.fromDate = "string";
        copy.toDate = "string";
        // 3
        copy.beginningCount = 0;
        copy.beginningDate = "string";
        copy.beginningType = "string";
        // 4
        copy.endingType = "string";
        copy.endingDate = "string";
        copy.endingCount = 0;
        // add scheduler
        // 1
        copy.everyTimeCount = 0;
        copy.everyTimeType = "string";
        // 2
        copy.everyWeekAtTime = "string";
        copy.everyWeek = "string";
        // 3
        copy.everyDayCount = 0;
        copy.everyDayDayType = "string";
        copy.everyDayMonthOrYearType = "string";

        if (values.serviceID == undefined) {
          copy.serviceID = null;
        }
        if (values.subserviceID == undefined) {
          copy.subserviceID = null;
        }
        if (values.propertyID == undefined) {
          copy.propertyID = null;
        }

        switch (rangeUncheckedImg) {
          case 1:
            copy.lastCount = Number(values.lastCount);
            copy.lastCountType = values.lastCountType;
            break;
          case 2:
            let fromDate = moment(values.fromDate).format("MM/DD/YYYY");
            let toDate = moment(values.toDate).format("MM/DD/YYYY");
            copy.fromDate = fromDate;
            copy.toDate = toDate;
            break;
          case 3:
            copy.beginningCount = Number(values.beginningCount);
            copy.beginningDate = moment(values.beginningDate).format("MM/DD/YYYY");
            copy.beginningType = values.beginningType;
            break;
          case 4:
            copy.endingType = values.endingType;
            copy.endingDate = moment(values.endingDate).format("MM/DD/YYYY");
            copy.endingCount = Number(values.endingCount);
            break;

          default:
            break;
        }

        if (!isRunNow) {
          switch (intervalUncheckedImg) {
            case 1:
              copy.everyTimeCount = Number(values.everyTimeCount);
              copy.everyTimeType = everyTimeType;
              break;
            case 2:
              copy.everyWeekAtTime = moment(values.everyWeekAtTime).format("HH:mm");
              copy.everyWeek = values.everyWeek;
              break;
            case 3:
              copy.everyDayCount = Number(values.everyDayCount);
              copy.everyDayDayType = values.everyDayDayType;
              copy.everyDayMonthOrYearType = everyDayMonthOrYearType;
              break;

            default:
              break;
          }
        }

        submitForm(copy, isRunNow);

        //For google spreadsheet
        // sendDataToGoogleSheets(jsonData)
      })
      .catch((e) => { });
  };
  // handle Cancel
  const handleCancel = () => {
    form.resetFields();
    setValidStatus({ name: false, retailerID: false, sheetUrl: false, pageName: false })
    dispatch(hideAddConnectionModal());
    setEveryTimeType("hours")
    setEveryMonthOrYearType("month")

    setRetailerServices([]);
    setSubserviceOptions([]);
    setPropertyOptions([]);
    setSubserviceArray([]);
  };
  // layout object
  const layout = {
    labelCol: { span: 8 },
    wrapperCol: { span: 16 },
  };


  // handle Change Retailer
  const handleChangeRetailer = async (value: string) => {

    form.resetFields(["serviceID", "subserviceID", "propertyID"]);
    await setServices(value)

    dispatch(resetRetailerService());
    dispatch(clearSubservices());
    dispatch(clearProperties());
    let data = retailers.filter((data) => data.id === value);
    let id = data[0].name;
    form.setFieldsValue({ retailerID: id });

    const noSpecialCharacters = id
      .replace(/[^a-zA-Z0-9 ]/g, "")
      .replace(/\s/g, "");

    setConnectionName({
      ...connectionName,
      retailerPart: noSpecialCharacters,
      servicePart: "",
      subServicePart: "",
      propertyPart: "",
    })
    setValidStatus({ ...validStatus, retailerID: true, name: true });

  };

  // handle Change Service
  const handleChangeService = async (value: string) => {
    form.resetFields(["subserviceID", "propertyID"]);
    dispatch(clearSubservices());
    dispatch(clearProperties())

    let services = retailerServices.filter((data) => data.id == value);
    let id = services[0].uniqueID;

    form.setFieldsValue({ serviceID: value });

    const noSpecialCharacters = id
      .replace(/[^a-zA-Z0-9 ]/g, "")
      .replace(/\s/g, "");

    setConnectionName({
      ...connectionName,
      servicePart: noSpecialCharacters,
      subServicePart: "",
      propertyPart: "",
    })

    await setSubserviceData(Number(value))
    form.resetFields(["subserviceID"])
  };



  // handle Change Subservice
  const handleChangeSubservice = (value: string) => {
    let subserviceId;
    form.resetFields(["propertyID"]);
    dispatch(clearProperties())

    subserviceId = subserviceOptions.filter((data) => data.id == value);
    let subId = subserviceId[0].uniqueID;

    dispatch(getPropertiesBySubServiceId({ subserviceID: Number(value) }));
    form.setFieldsValue({ subserviceID: value });
    const noSpecialCharacters = subId
      ?.replace(/[^a-zA-Z0-9 ]/g, "")
      ?.replace(/\s/g, "");

    setConnectionName({
      ...connectionName,
      subServicePart: noSpecialCharacters,
      propertyPart: "",
    })

  };



  const setServices = async (retailerId: string, clear: boolean = true) => {
    if (clear) {
      setRetailerServices([]);
      setSubserviceOptions([])
      form.setFieldsValue({ serviceID: "" })
      form.setFieldsValue({ subserviceID: "" })
    }
    const result = await fetch(`${BASE_URL}api/Retailers/${retailerId}`, createAuthConfig());
    let data = await result.json();
    const serviceArray = data.service?.trim().split(",");
    const RetsubserviceArray = data.subservice?.trim().split(",");

    if (RetsubserviceArray) {
      setSubserviceArray(RetsubserviceArray)
    }
    else {
      setSubserviceArray([])
    }

    if (serviceArray) {
      let serviceData = services.filter(item => serviceArray.includes(item.id.toString()))
      if (serviceData.length) {
        setRetailerServices(serviceData)
      }
    }
  }

  const setSubserviceData = async (serviceId: number, clear = true) => {
    if (clear) {
      form.setFieldsValue({ subserviceID: "" });
      setSubserviceOptions([])
    }

    const result = await fetch(`${BASE_URL}api/getsubservicedetail/${serviceId}`, createAuthConfig());
    const data = await result.json();


    let subServices = data.subservice.filter((item: any) => subserviceArray.includes(item.id.toString()));
    setSubserviceOptions(subServices)
    if (!subServices.length) {
      const result = await fetch(`${BASE_URL}api/GetPropertiesDetailserviceID/${serviceId}`, createAuthConfig());
      const data = await result.json();
      
      setPropertyOptions(data.property)

    }
  }

  const onChanget = (value: Moment) => {
    setSelectedTime(value);
    setEveryWeekAtTime(value);
    form.setFieldsValue({
      selectedInterval: "2",
      everyTimeCount: everyTimeCount.toString(),
      everyTimeType: everyTimeType,
      everyWeek: everyWeek,
      everyWeekAtTime: value?.toString(),
      everyDayCount: everyDayCount.toString(),
      everyDayDayType: everyDayDayType,
      everyDayMonthOrYearType: everyDayMonthOrYearType,
    });
  };

  // handle Change LastCount
  const handleChangeLastCount = (
    e: { target: { value: string } },
    type: number
  ) => {
    let x = e.target.value;
    setRangeUncheckedImg(1);
    if (dateRangeValue != 1) {
      setDateRangeValue(1);
    }
    // form.setFieldsValue({
    //   lastCount: lastCount.toString(),
    // });
    setLastCount(x);
    setSelectedDateRange(type.toString());
  };

  // const handleChangeLastCount = (
  //   e: { target: { value: string } },
  //      type: number
  //    ) => {
  //   let x = parseInt(e.target.value); // Parse the input value to an integer
  //   setRangeUncheckedImg(1);
  //   if (dateRangeValue !== 1) {
  //     setDateRangeValue(1);
  //   }
  //   // You don't need to set form field value manually if using state
  //   setLastCount(x.toString()); // Set lastCount as number
  //   setSelectedDateRange(type.toString());
  // };


  // handle Change LastCountType
  const handleChangeLastCountType = (e: string) => {
    form.setFieldsValue({
      lastCountType: e,
      selectedDateRange: "1",
    });
    setLastCountType(e);
    setSelectedDateRange("1");
  };
  // handle Change FromDate
  const handleChangeFromDate: DatePickerProps["onChange"] = (
    date,
    dateString
  ) => {
    form.setFieldsValue({
      fromDate: dateString,
    });
    setFromDate(date);
    setSelectedDateRange("2");
  };

  const handleChangeToDate: DatePickerProps["onChange"] = (
    date,
    dateString
  ) => {
    form.setFieldsValue({
      toDate: dateString,
    });
    setToDate(date);
    setSelectedDateRange("2");
  };
  // handle Change BeginningCount
  const handleChangeBeginningCount = (
    e: { target: { value: string } },
    type: number
  ) => {
    let x = e.target.value;
    form.setFieldsValue({
      selectedDateRange: "3",
      beginningCount: x.toString(),
    });
    setBeginningCount(Number(x));
    setSelectedDateRange(type.toString());
  };
  // handle Change EndingCount
  const handleChangeEndingCount = (
    e: { target: { value: string } },
    type: number
  ) => {
    let x = e.target.value;
    form.setFieldsValue({
      selectedDateRange: "4",
      endingCount: x.toString(),
    });
    setEndingCount(Number(x));
    setSelectedDateRange(type.toString());
  };
  // handle Change EveryTimeCount
  const handleChangeEveryTimeCount = (
    e: { target: { value: string } },
    type: number
  ) => {
    let x = e.target.value;
    form.setFieldsValue({
      selectedInterval: 1,
      everyTimeCount: x.toString(),
      everyTimeType: everyTimeType,
      everyWeek: everyWeek,
      everyWeekAtTime: everyWeekAtTime,
      everyDayCount: everyDayCount,
      everyDayDayType: everyDayDayType,
      everyDayMonthOrYearType: everyDayMonthOrYearType,
    });
    setSelectedInterval(1);
    setEveryTimeCount(Number(x));
    setSelectedDateRange(type.toString());
  };
  // handle Change Every Day Count
  const handleChangeEveryDayCount = (
    e: { target: { value: string } },
    type: number
  ) => {
    let x = e.target.value;
    form.setFieldsValue({
      selectedInterval: "3",
      everyTimeCount: everyTimeCount.toString(),
      everyTimeType: everyTimeType,
      everyWeek: everyWeek,
      everyWeekAtTime: everyWeekAtTime,
      everyDayCount: x,
      everyDayDayType: everyDayDayType,
      everyDayMonthOrYearType: everyDayMonthOrYearType,
    });
    setSelectedInterval(3);
    setEveryDayCount(Number(x));
  };

  //onChange for the radio button 
  const onChangePagetitle = (e: RadioChangeEvent) => {
    console.log(e, "e")
    setPageTitle(e.target.value);
    form.setFieldsValue({ pageTitle: e.target.value })
  }

  // onChange Append Type
  const onChangeAppendType = (e: RadioChangeEvent) => {
    setAppendOrReplace(e.target.value);
    form.setFieldsValue({ appendOrReplace: e.target.value });
  };

  // handle Change SpreadsheetURL
  const handleChangeSpreadsheetURL = (event: { target: { value: string } }) => {
    setSpreadsheetURL(event.target.value);
    form.setFieldsValue({
      spreadsheetURL: event.target.value,
    });

  };
  // handle Change Property
  const handleChangeProperty = (value: string) => {
    let propertyDescription;
    propertyDescription = propertyOptions.filter(
      (data) => data.id == value
    );
    let desc = propertyDescription[0].uniqueID;

    // setProperty(value);
    form.setFieldsValue({ propertyID: value });
    const noSpecialCharacters = desc
      .replace(/[^a-zA-Z0-9 ]/g, "")
      .replace(/\s/g, "");


    setConnectionName({
      ...connectionName,
      propertyPart: noSpecialCharacters,
    })

  };

  const format = "HH:mm";
  // handle Add Retailer
  const handleAddRetailer = () => {
    dispatch(showAddRetailerModal());
  };
  // handle Add Service
  const handleAddService = () => {
    dispatch(showAddServiceModal());
  };
  // handle Add Subservice
  const handleAddSubservice = () => {
    dispatch(showAddSubserviceModal());
  };
  // handle Add Property
  const handleAddProperty = () => {
    dispatch(showAddPropertyModal());
  };



  const beginningTypeOptions = [
    { value: "days", label: "Days", key: "Days" },
    { value: "weeks", label: "Weeks", key: "Weeks" },
    { value: "months", label: "Months", key: "Weeks" },
    { value: "years", label: "Years", key: "Weeks" },
  ];

  const handleBeginningTypeField = (e: string) => {
    setBeginningType(e);
    form.setFieldsValue({
      selectedDateRange: "3",
      beginningCount: beginningCount.toString(),
      beginningType: e,
      beginningDate: beginning1Date?.toString(),
    });
  };

  useEffect(() => {
    let connectionText = "";
    if (connectionName.retailerPart !== "") connectionText += connectionName.retailerPart
    if (connectionName.servicePart !== "") connectionText += `-${connectionName.servicePart}`
    if (connectionName.subServicePart !== "") connectionText += `-${connectionName.subServicePart}`
    if (connectionName.propertyPart !== "") connectionText += `-${connectionName.propertyPart}`
    form.setFieldsValue({ name: connectionText })
  }, [connectionName]);

  return (
    <Modal
      maskClosable={false}
      title="Add Connection"
      visible={showAddModal}
      okText={"SCHEDULE NOW"}
      onOk={handleModalOk(null)}
      cancelText="Cancel"
      onCancel={handleCancel}
      okButtonProps={{ style: { display: "none" } }}
      cancelButtonProps={{ style: { display: "none" } }}
      width={1000}
      destroyOnClose={true}
    >
      {error && (
        <>
          <Alert message="Error" description={error} type="error" showIcon />
          <Divider />
        </>
      )}
      <Form
        {...layout}
        form={form}
        name="addConnection"
        className="form"
        initialValues={{
          description: "",
          retailerID: retailer,
          spreadsheetURL: spreadsheetURL,
          selectedDateRange: selectedDateRange,
          lastCount: lastCount,
          lastCountType: lastCountType,
          beginningCount: beginningCount,
          beginningType: beginningType1,
          endingCount: endingCount,
          endingType: endingType,
          selectedInterval: selectedInterval,
          everyTimeCount: everyTimeCount,
          everyTimeType: everyTimeType,
          everyWeek: everyWeek,
          everyWeekAtTime: everyWeekAtTime,
          everyDayCount: everyDayCount,
          everyDayDayType: everyDayDayType,
          everyDayMonthOrYearType: everyDayMonthOrYearType,
          appendOrReplace: appendOrReplace,
          pageTitle: pageTitle,

        }}
      >
        <Form.Item
          label="Connection name"
          name="name"
          rules={[{ required: true, message: "Connection name is required!" }]}
        >
          <Input disabled />
        </Form.Item>

        <Form.Item
          label="Description"
          name="description"
        >
          <Input.TextArea />
        </Form.Item>

        <Form.Item
          label="Facebook page name"
          name="pageName"
          rules={[{ required: true, message: "Page name is required!" }]}
        >
          <Select
            style={{ width: 300 }}
            placeholder="Select a facebook page"
            onChange={(value) => setValidStatus({ ...validStatus, pageName: true })}
          >
            {facebookPages.map((pageName, index) => (
              <Select.Option key={index} value={pageName}>
                {pageName}
              </Select.Option>
            ))}
          </Select>
        </Form.Item>

        <Form.Item
          label="Retailer"
          name="retailerID"
          rules={[{ required: true, message: "Retailer is required!" }]}
        >
          <Select
            style={{ width: 300 }}
            placeholder="Select a Retailer"
            onChange={handleChangeRetailer}
          >
            {retailers.map((option) => (
              <Select.Option key={option.id} value={option.id.toString()}>
                {option.name}
              </Select.Option>
            ))}
          </Select>
          <Button
            type="primary"
            size={"small"}
            htmlType="button"
            style={{ marginLeft: "1em" }}
            onClick={handleAddRetailer}
          >
            Add Retailer
          </Button>
          <AddRetailerModal />

        </Form.Item>
        <Form.Item label="Property " name="propertyID">
          <Select style={{ width: 300 }} disabled={!propertyOptions.length} onChange={handleChangeProperty}>
            {propertyOptions?.map((option) => (
              <Select.Option key={option.id} value={option.id.toString()}>
                {option.name}
              </Select.Option>
            ))}
          </Select>
          <Button
            type="primary"
            size={"small"}
            htmlType="button"
            style={{ marginLeft: "1em" }}
            onClick={handleAddProperty}
          >
            Add Property
          </Button>
          <AddPropertyModal />
        </Form.Item>
        <Form.Item
          label="Service "
          name="serviceID"
        >
          <Select style={{ width: 300 }} disabled={!retailerServices.length} onChange={handleChangeService}>
            {retailerServices?.length > 0
              ? retailerServices.map((option) => (
                <Select.Option key={option.id} value={option.id.toString()}>
                  {option.name}
                </Select.Option>
              ))
              : null}
          </Select>
          <Button
            type="primary"
            size={"small"}
            htmlType="button"
            style={{ marginLeft: "1em" }}
            onClick={handleAddService}
          >
            Add Service
          </Button>
          <AddServiceModal />
        </Form.Item>
        <Form.Item label="Subservice" name="subserviceID">
          <Select style={{ width: 300 }} disabled={!subserviceOptions.length} onChange={handleChangeSubservice}>
            {subserviceOptions.map((option) => (
              <Select.Option key={option.id} value={option.id}>
                {option.name}
              </Select.Option>
            ))}
          </Select>
          <Button
            type="primary"
            htmlType="button"
            size={"small"}
            style={{ marginLeft: "1em" }}
            onClick={handleAddSubservice}
          >
            Add Subservice
          </Button>
          <AddSubserviceModal />
        </Form.Item>

        {/* <Form.Item label="Property " name="propertyID">
          <Select style={{ width: 300 }} disabled={!propertyOptions.length} onChange={handleChangeProperty}>
            {propertyOptions?.map((option) => (
              <Select.Option key={option.id} value={option.id.toString()}>
                {option.name}
              </Select.Option>
            ))}
          </Select>
          <Button
            type="primary"
            size={"small"}
            htmlType="button"
            style={{ marginLeft: "1em" }}
            onClick={handleAddProperty}
          >
            Add Property
          </Button>
          <AddPropertyModal />
        </Form.Item> */}
        {/* {(subserviceID &&  propertyOptions.length)&& (
        )} */}

        <div>
          <label>Date Range</label>
          <Form.Item
            name="selectedDateRange"
            rules={[{ required: true, message: "Date Range is required!" }]}
            style={{ width: "52vw" }}
          >
            <div style={{ display: "flex", flexDirection: "column" }}>
              <div
                style={{ display: "flex", flexDirection: "row" }}
                onClick={() => {
                  setRangeUncheckedImg(1);
                  // setRunNowDisable(true);
                  form.setFieldsValue({
                    selectedDateRange: "1",
                    lastCount: lastCount.toString(),
                    // lastCountType: lastCountType,
                  });
                }}
              >
                <img
                  src={rangeUncheckedImg === 1 ? radioChecked : radioUnchecked}
                  style={{ height: "20px", width: "20px", marginRight: "1rem" }}
                />
                Last
                <Form.Item name="lastCount">
                  <Input
                    onChange={(e) => handleChangeLastCount(e, 1)}
                    type="number"
                    min={1}
                    value={lastCount}
                    style={{ width: 60, marginLeft: "2em", marginRight: "2em" }}
                  />
                </Form.Item>
                <Form.Item name="lastCountType">
                  <></>
                </Form.Item>
                <div>
                  <Select
                    style={{ width: 120 }}
                    defaultValue={lastCountType}
                    onChange={handleChangeLastCountType}
                    options={[
                      { value: "days", label: "Days" },
                      { value: "weeks", label: "Weeks" },
                      { value: "months", label: "Months" },
                      { value: "years", label: "Years" },
                    ]}
                  />
                </div>
              </div>
              <div
                style={{
                  display: "flex",
                  flexDirection: "row",
                  justifyContent: "space-between",
                  marginTop: " 5px",
                }}
                onClick={() => {
                  setRangeUncheckedImg(2);
                  // setRunNowDisable(false);
                  form.setFieldsValue({
                    selectedDateRange: "2",
                    // fromDate: fromDate,
                    // toDate: toDate,
                  });
                }}
              >
                <img
                  src={rangeUncheckedImg === 2 ? radioChecked : radioUnchecked}
                  style={{ height: "20px", width: "20px", marginRight: "1rem" }}
                />
                From:
                <Form.Item name="fromDate">
                  <></>
                </Form.Item>
                <div>
                  <DatePicker
                    value={fromDate}
                    allowClear={false}
                    format={"MM/DD/YYYY"}
                    placeholder="MM/DD/YYYY"
                    onChange={handleChangeFromDate}
                    style={{ marginLeft: "2em", marginRight: "2em" }}
                    disabledDate={(current) => {
                      let customDate = moment().format("YYYY-MM-DD");
                      return current && current > moment(customDate, "YYYY-MM-DD");
                    }}
                  />
                </div>
                <label>to: </label>
                <Form.Item name="toDate">
                  <></>
                </Form.Item>
                <div>
                  <DatePicker
                    value={toDate}
                    allowClear={false}
                    format={"MM/DD/YYYY"}
                    onChange={handleChangeToDate}
                    placeholder="MM/DD/YYYY"
                    style={{ marginLeft: "2em", marginRight: "2em" }}
                    disabledDate={(current) => {
                      let customDate = moment().format("YYYY-MM-DD");
                      return current && current > moment(customDate, "YYYY-MM-DD");
                    }}
                  />
                </div>
              </div>
              <div
                style={{
                  display: "flex",
                  flexDirection: "row",
                  marginTop: " 5px",
                }}
                onClick={() => {
                  setRangeUncheckedImg(3);
                  // setRunNowDisable(false);
                  form.setFieldsValue({
                    selectedDateRange: "3",
                    // selectedInterval: selectedInterval.toString(),
                    // beginningCount: beginningCount.toString(),
                    // beginningType: beginningType1,
                    // beginningDate: beginning1Date,
                  });
                }}
              >
                <img
                  src={rangeUncheckedImg === 3 ? radioChecked : radioUnchecked}
                  style={{ height: "20px", width: "20px", marginRight: "1rem" }}
                />
                <Form.Item name="beginningCount">
                  <Input
                    type="number"
                    min={1}
                    value={beginningCount}
                    style={{ width: 60, marginRight: "1em" }}
                    onChange={(e) => {
                      handleChangeBeginningCount(e, 3);
                    }}
                  />
                </Form.Item>
                <Form.Item name={"beginningType"}>
                  <></>
                </Form.Item>
                <div>
                  <Select
                    // value={beginningType1}
                    defaultValue={beginningType1}
                    style={{ width: 120, marginRight: "1em" }}
                    onChange={handleBeginningTypeField}
                  >
                    {beginningTypeOptions.map((database, index) => (
                      <Option key={index} value={database.value}>
                        {database.label}
                      </Option>
                    ))}
                  </Select>
                </div>

                Beginning on:
                <Form.Item name="beginningDate">
                  <></>
                </Form.Item>
                <div>
                  <DatePicker
                    allowClear={false}
                    value={beginning1Date}
                    format={"MM/DD/YYYY"}
                    placeholder="MM/DD/YYYY"
                    onChange={onChangeBeginningOn}
                    style={{ marginLeft: "1em", marginRight: "1em" }}
                    disabledDate={(current) => {
                      let customDate = moment().format("YYYY-MM-DD");
                      return current && current > moment(customDate, "YYYY-MM-DD");
                    }}
                  />
                </div>
              </div>
              <div
                style={{
                  display: "flex",
                  flexDirection: "row",
                  marginTop: " 5px",
                }}
                onClick={() => {
                  setRangeUncheckedImg(4);
                  // setRunNowDisable(false);
                  form.setFieldsValue({
                    selectedDateRange: "4",
                    // endingCount: endingCount,
                    // endingType: endingType,
                    // endingDate: endingDate,
                  });
                }}
              >
                <img
                  src={rangeUncheckedImg == 4 ? radioChecked : radioUnchecked}
                  style={{ height: "20px", width: "20px", marginRight: "1rem" }}
                />
                <Form.Item name="endingCount">
                  <Input
                    type="number"
                    value={endingCount}
                    min={1}
                    style={{ width: 60, marginRight: "1em" }}
                    onChange={(e) => handleChangeEndingCount(e, 4)}
                  />
                </Form.Item>
                <Form.Item name="endingType">
                  <></>
                </Form.Item>
                <div>
                  <Select
                    style={{ width: 120, marginRight: "1em" }}
                    value={endingType}
                    onChange={(e) => {
                      setEndingType(e);
                      form.setFieldsValue({
                        selectedDateRange: "4",
                        // endingCount: endingCount,
                        endingType: e,
                        // endingDate: endingDate?.toString(),
                      });
                      setEndingType(e);
                    }}
                    options={[
                      { value: "days", label: "Days" },
                      { value: "weeks", label: "Weeks" },
                      { value: "months", label: "Months" },
                      { value: "years", label: "Years" },
                    ]}
                  />
                </div>
                Ending on:
                <Form.Item name="endingDate">
                  <></>
                </Form.Item>
                <div>
                  <DatePicker
                    allowClear={false}
                    value={endingDate}
                    format={"MM/DD/YYYY"}
                    placeholder="MM/DD/YYYY"
                    onChange={onChangeEndingOn}
                    style={{ marginLeft: "1em", marginRight: "1em" }}
                    disabledDate={(current) => {
                      let customDate = moment().format("YYYY-MM-DD");
                      return current && current > moment(customDate, "YYYY-MM-DD");
                    }}
                  />
                </div>
              </div>
            </div>
          </Form.Item>
        </div>
        <div>
          <label>Connection Interval</label>
          <Form.Item
            style={{ width: "max-content" }}
            name="selectedInterval"
            rules={[
              { required: true, message: "Connection Interval is required!" },
            ]}
          >
            <div style={{ display: "flex", flexDirection: "column" }}>
              <div
                style={{
                  display: "flex",
                  flexDirection: "row",
                  marginTop: " 5px",
                }}
                onClick={() => {
                  setIntervalUncheckedImg(1);
                  setSelectedInterval(1);
                  form.setFieldsValue({
                    selectedInterval: "1",
                    everyTimeCount: everyTimeCount.toString(),
                    everyTimeType: everyTimeType,
                    everyWeek: everyWeek,
                    everyWeekAtTime: everyWeekAtTime,
                    everyDayCount: everyDayCount.toString(),
                    everyDayDayType: everyDayDayType,
                    everyDayMonthOrYearType: everyDayMonthOrYearType,
                  });
                }}
              >
                <img
                  src={
                    intervalUncheckedImg === 1 ? radioChecked : radioUnchecked
                  }
                  style={{ height: "20px", width: "20px", marginRight: "1rem" }}
                />
                Every
                <Form.Item name="everyTimeCount">
                  <Input
                    value={everyTimeCount}
                    onChange={(e) => handleChangeEveryTimeCount(e, 1)}
                    style={{ width: 60, marginLeft: "1em", marginRight: "1em" }}
                    type="number"
                    min={1}
                  />
                </Form.Item>
                <Form.Item name="everyTimeType">
                  <></>
                </Form.Item>
                <div>
                  <Select
                    value={everyTimeType}
                    style={{ width: 120 }}
                    onChange={(e) => {
                      setEveryTimeType(e);
                      setSelectedInterval(1);
                      form.setFieldsValue({
                        selectedInterval: "1",
                        everyTimeCount: everyTimeCount.toString(),
                        everyTimeType: e,
                        everyWeek: everyWeek,
                        everyWeekAtTime: everyWeekAtTime,
                        everyDayCount: everyDayCount.toString(),
                        everyDayDayType: everyDayDayType,
                        everyDayMonthOrYearType: everyDayMonthOrYearType,
                      });
                    }}
                    options={[
                      { value: "seconds", label: "Seconds" },
                      { value: "minutes", label: "Minutes" },
                      { value: "hours", label: "Hours" },
                      { value: "days", label: "Days" },
                      { value: "weeks", label: "Weeks" },
                      { value: "months", label: "Months" },
                      { value: "years", label: "Years" },
                    ]}
                  />
                </div>
              </div>
              <div
                style={{
                  display: "flex",
                  flexDirection: "row",
                  marginTop: " 5px",
                }}
                onClick={() => {
                  setIntervalUncheckedImg(2);
                  setSelectedInterval(2);
                  form.setFieldsValue({
                    selectedInterval: "2",
                    everyTimeCount: everyTimeCount.toString(),
                    everyTimeType: everyTimeType,
                    everyWeek: everyWeek,
                    everyWeekAtTime: everyWeekAtTime,
                    everyDayCount: everyDayCount.toString(),
                    everyDayDayType: everyDayDayType,
                    everyDayMonthOrYearType: everyDayMonthOrYearType,
                  });
                }}
              >
                <img
                  src={
                    intervalUncheckedImg === 2 ? radioChecked : radioUnchecked
                  }
                  style={{ height: "20px", width: "20px", marginRight: "1rem" }}
                />
                Every:
                <Form.Item name="everyWeek">
                  <></>
                </Form.Item>
                <div>
                  <Select
                    defaultValue="monday"
                    value={everyWeek}
                    style={{
                      width: 120,
                      marginLeft: "1em",
                      marginRight: "1em",
                    }}
                    onChange={(e) => {
                      setEveryWeek(e);
                      setSelectedInterval(2);
                      form.setFieldsValue({
                        selectedInterval: "2",
                        everyTimeCount: everyTimeCount.toString(),
                        everyTimeType: everyTimeType,
                        everyWeek: e,
                        everyWeekAtTime: everyWeekAtTime?.toString(),
                        everyDayCount: everyDayCount.toString(),
                        everyDayDayType: everyDayDayType,
                        everyDayMonthOrYearType: everyDayMonthOrYearType,
                      });
                    }}
                    options={[
                      { value: "sunday", label: "Sunday" },
                      { value: "monday", label: "Monday" },
                      { value: "tuesday", label: "Tuesday" },
                      { value: "wednesday", label: "Wednesday" },
                      { value: "thursday", label: "Thursday" },
                      { value: "friday", label: "Friday" },
                      { value: "saturday", label: "Saturday" },
                    ]}
                  />
                </div>
                <label style={{ marginRight: "1em" }}>at: </label>
                <Form.Item name="everyWeekAtTime">
                  <></>
                </Form.Item>
                <div>
                  <TimePicker
                    style={{ marginRight: "1em", width: 80 }}
                    value={selectedTime}
                    format={format}
                    onChange={(value) => onChanget(value as Moment)}
                  />
                </div>
              </div>
              <div
                style={{
                  display: "flex",
                  flexDirection: "row",
                  marginTop: " 5px",
                }}
                onClick={() => {
                  setIntervalUncheckedImg(3);
                  setSelectedInterval(3);
                  form.setFieldsValue({
                    selectedInterval: "3",
                    everyTimeCount: everyTimeCount.toString(),
                    everyTimeType: everyTimeType,
                    everyWeek: everyWeek,
                    everyWeekAtTime: everyWeekAtTime,
                    everyDayCount: everyDayCount.toString(),
                    everyDayDayType: everyDayDayType,
                    everyDayMonthOrYearType: everyDayMonthOrYearType,
                  });
                }}
              >
                <img
                  src={
                    intervalUncheckedImg === 3 ? radioChecked : radioUnchecked
                  }
                  style={{
                    height: "20px",
                    width: "20px",
                    marginRight: ".5rem",
                  }}
                />
                Every
                <Form.Item name="everyDayCount">
                  <Input
                    type="number"
                    value={everyDayCount}
                    onChange={(e) => handleChangeEveryDayCount(e, 3)}
                    style={{
                      marginLeft: ".5em",
                      marginRight: ".5em",
                      width: 60,
                    }}
                    min={1}
                    max={31}
                    onInput={(e) => {
                      let value = Number(e.currentTarget.value);
                      if (value == 1) {
                        setAbbreviation("st")
                      }
                      else if (value == 2) {
                        setAbbreviation("nd")
                      }
                      else if (value == 3) {
                        setAbbreviation("rd")
                      }
                      else {
                        setAbbreviation("th")
                      }

                    }}
                  />
                </Form.Item>
                {/* st/nd/rd/th */}
                <label style={{ marginRight: ".5em", paddingTop: 5 }}>{abbreviation}</label>
                <Form.Item name="everyDayDayType">
                  <></>
                </Form.Item>
                <div>
                  <Select
                    value={everyDayDayType}
                    style={{ width: 120, marginRight: ".5em" }}
                    onChange={(e) => {
                      setEveryDayDayType(e);
                      setSelectedInterval(3);
                      form.setFieldsValue({
                        selectedInterval: "3",
                        everyTimeCount: everyTimeCount.toString(),
                        everyTimeType: everyTimeType,
                        everyWeek: everyWeek,
                        everyWeekAtTime: everyWeekAtTime,
                        everyDayCount: everyDayCount.toString(),
                        everyDayDayType: e,
                        everyDayMonthOrYearType: everyDayMonthOrYearType,
                      });
                    }}
                    options={[
                      { value: "day", label: "day" },
                      { value: "week", label: "week" },
                      { value: "month", label: "month" },
                      { value: "quarter", label: "quarter" },
                      { value: "half", label: "half" },
                    ]}
                  />
                </div>
                of
                <Form.Item name="everyDayMonthOrYearType">
                  <></>
                </Form.Item>
                <div>
                  <Select
                    defaultValue="month"
                    style={{
                      width: 120,
                      marginRight: ".5em",
                      marginLeft: ".5em",
                    }}
                    onChange={(e) => {
                      setEveryMonthOrYearType(e);
                      setSelectedInterval(3);
                      form.setFieldsValue({
                        selectedInterval: "3",
                        everyTimeCount: everyTimeCount.toString(),
                        everyTimeType: everyTimeType,
                        everyWeek: everyWeek,
                        everyWeekAtTime: everyWeekAtTime,
                        everyDayCount: everyDayCount.toString(),
                        everyDayDayType: everyDayDayType,
                        everyDayMonthOrYearType: e,
                      });
                    }}
                    options={[
                      { value: "month", label: "month" },
                      { value: "year", label: "year" },
                    ]}
                  />
                </div>
              </div>
            </div>
          </Form.Item>
        </div>
        {/* adding radio buttons */}
        <div style={{ display: "flex", justifyContent: "space-around" }}>
          <Form.Item name="pageTitle">
            <Radio.Group
              onChange={onChangePagetitle}
              value={pageTitle}
              style={{ display: "flex", flexDirection: "row" }}
            >
              <Radio value={"Video Posts"} style={{ marginTop: "1em" }}>
                Video Posts
              </Radio>
              <Radio value={"Posts"} style={{ marginTop: "1em" }}>
                Posts
              </Radio>
              <Radio checked value={"Page"} style={{ marginTop: "1em" }}>
                Page
              </Radio>
            </Radio.Group>
          </Form.Item>
        </div>
        <Form.Item
          label="Push results to sheet:"
          name="spreadsheetURL"
          rules={[{
            validator(_, value) {
              if (value == "") {
                setValidStatus({ ...validStatus, sheetUrl: false });
                return Promise.reject("Spreadsheet url field is required.")
              }
              var urlRegx = new RegExp('https://docs.google.com', 'i');
              if (urlRegx.test(value)) {
                setValidStatus({ ...validStatus, sheetUrl: true });
                return Promise.resolve()
              }
              else {
                setValidStatus({ ...validStatus, sheetUrl: false });
                return Promise.reject("Please enter a valid spreadsheet url.")
              }
            },
          }]}
        >
          <Input value={spreadsheetURL} onChange={handleChangeSpreadsheetURL} />
        </Form.Item>
        <div>
          <label>Replace or Append Data</label>
          <Form.Item name="appendOrReplace">
            <Radio.Group
              onChange={onChangeAppendType}
              value={appendOrReplace}
              style={{ display: "flex", flexDirection: "column" }}
            >
              <Radio checked value={"append"} style={{ marginTop: "1em" }}>
                Append Data
              </Radio>
              <Radio value={"replace"} style={{ marginTop: "1em" }}>
                Replace Data
              </Radio>
            </Radio.Group>
          </Form.Item>
        </div>
      </Form>
      <div style={{ display: "flex", justifyContent: "space-around" }}>
        {/* <Popconfirm
          placement="topRight"
          title={" "}
          onConfirm={handleModalOk(false)}
          okText="Yes"
          cancelText="No"
        >
          <Button loading={scheduleNowLoading} disabled={runNowLoading || submittable}>Schedule Now</Button>
        </Popconfirm> */}

        <Button
          loading={scheduleNowLoading}
          // {
          // ...(runNowLoading && { disabled: true })
          // }
          // {
          // ...(!(validStatus.name && validStatus.retailerID && validStatus.sheetUrl) && { disabled: true })
          // }
          // {
          //   ...(scheduleNowLoading && { disabled: true })
          //   }
          //   {
          //   ...(!(validStatus.name && validStatus.retailerID && validStatus.sheetUrl && validStatus.pageName) && { disabled: true })
          //   }
          {
          ...(scheduleNowLoading && { disabled: true })
          }
          {
          ...(!(validStatus.retailerID && validStatus.sheetUrl && validStatus.pageName) && { disabled: true })
          }
          onClick={() => {
            Modal.confirm({
              title: "Are you sure?",
              content: "You want to Schedule Now?",
              onOk: handleModalOk(false)
            })
          }} type="primary" danger>
          Schedule Now
        </Button>

        <Button
          loading={runNowLoading}
          {
          ...(scheduleNowLoading && { disabled: true })
          }
          {
          ...(!(validStatus.retailerID && 
          validStatus.sheetUrl && validStatus.pageName) && { disabled: true })
          }
          onClick={() => {
            Modal.confirm({
              title: "Are you sure?",
              content: "You want to Run Now?",
              onOk: handleModalOk(true)
            })
          }} type="primary">
          Run Now
        </Button>
        <Button onClick={handleCancel}>Cancel</Button>
      </div>
    </Modal>
  );
}
