import React from "react";
import { useDispatch } from "react-redux";
import { showAddSubserviceModal } from "./slice";
import { Button } from "antd";
import { PlusOutlined } from "@ant-design/icons";
import styled from "styled-components";
import AddSubserviceModal from "./AddSubserviceModal";
import ServicesTable from "./SubservicesTable";

const Container = styled.div`
  width: 100%;
  padding: 32px;
`;

const HeaderContainer = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
`;

export default function Services() {
  const dispatch = useDispatch();

  //handle Add Button
  const handleAddButton = () => {
    dispatch(showAddSubserviceModal());
  };

  return (
    <>
      <Container>
        <HeaderContainer>
          <h1>Subservices</h1>
          <Button
            type="primary"
            icon={<PlusOutlined />}
            onClick={handleAddButton}
          >
            Add Subservice
          </Button>
        </HeaderContainer>
        <ServicesTable />
      </Container>
      <AddSubserviceModal />
    </>
  );
}
