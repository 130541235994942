import React from "react";
import { useDispatch, useSelector } from "react-redux";

import { Form, Modal, Divider, Alert } from "antd";
import { CircularsForm } from "../CircularForm";
import { RootState } from "../../../App";
import { hideEditCircularModal } from "../slice";
import { setEditCircularLoading } from "../slice";
import { editCircular } from "../api";
import { Circular } from "../models";
import moment from "moment";

export const EditCircularModal = (props: { circular: Circular }) => {
  const [form] = Form.useForm();

  const retailers = useSelector((state: RootState) => state.retailers.all);

  const dispatch = useDispatch();

  const visible = useSelector(
    (state: RootState) => state.circulars.showEditModal
  );

  const loading = useSelector(
    (state: RootState) => state.circulars.editLoading
  );

  const error = useSelector((state: RootState) => state.circulars.editError);

  const handleSubmit = async (values: any) => {
    dispatch(setEditCircularLoading(true));

    // we're doing this because redux doesn't let us dispatch unserializable stuff (i.e. File, FormData)

    const targetRetailer = retailers.find(
      (retailer) => retailer.id === values.retailer
    );

    const prepared: any = {
      id: props.circular.id,
      Title: values.title,
      Name: values.name,
      Slug: values.slug,
      activeDate: moment.utc(values?.dateRange[0]).toJSON(),
      terminationDate: moment.utc(values.dateRange[1]).toJSON(),
      retailer: targetRetailer,
      circularPages: props.circular.circularPages,
    };

    dispatch(editCircular(prepared));
  };

  const onCancel = () => {
    form.resetFields();
    dispatch(hideEditCircularModal());
  };

  const handleModalOk = async () => {
    try {
      const values = await form.validateFields();
      handleSubmit(values);
    } catch (e) {}
  };

  return (
    <Modal
      maskClosable={false}
      title="Edit Circular Details"
      visible={visible === props.circular.id}
      onCancel={onCancel}
      onOk={handleModalOk}
      okText="Update"
      okButtonProps={loading ? { loading: true } : undefined}
      destroyOnClose
    >
      {error && (
        <>
          <Alert message="Error" description={error} type="error" showIcon />
          <Divider />
        </>
      )}
      <CircularsForm
        form={form}
        onSubmit={handleModalOk}
        initialValues={{
          ...props.circular,
          dateRange: [
            moment(props.circular.activeDate),
            moment(props.circular.terminationDate),
          ],
          retailer: props?.circular?.retailer?.id,
        }}
      />
    </Modal>
  );
};
