import React from "react";
import { useSelector } from "react-redux";
import { RootState } from "../App";
import { Navbar } from "./navbar/Navbar";
import { Sidebar } from "./Sidebar";
import { Routes } from "./Routes";

//@ts-ignore
export const AdminDashboard = () => {
  const user = useSelector((state: RootState) => state.auth.user);

  return user ? (
    <div style={{ height: "100vh", display: "flex", flexDirection: "column" }}>
      <div style={{ flex: 0 }}>
        <Navbar />
      </div>
      <div style={{ display: "flex", flex: 1 }}>
        <Sidebar />
        <Routes />
      </div>
    </div>
  ) : null;
};
