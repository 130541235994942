import React from "react";
import { useSelector, useDispatch } from "react-redux";
import { RootState } from "../../App";
import { showAddCircularGroupModal, hideAddCircularGroupModal } from "./slice";
import { Button } from "antd";
import { PlusOutlined } from "@ant-design/icons";
import styled from "styled-components";
import CircularGroupsTable from "./CircularGroupsTable";
import AddCircularGroupModal from "./AddCircularGroupModal";

const Container = styled.div`
  width: 100%;
  padding: 32px;
`;

const HeaderContainer = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
`;

export default function CircularGroups() {
  const dispatch = useDispatch();
  const showAddModal = useSelector(
    (state: RootState) => state.circularGroups.showAddModal
  );

  const handleAddButton = () => {
    dispatch(showAddCircularGroupModal());
  };

  const handleAddCancel = () => {
    dispatch(hideAddCircularGroupModal());
  };

  return (
    <>
      <Container>
        <HeaderContainer>
          <h1>Circular Groups</h1>
          <Button
            type="primary"
            icon={<PlusOutlined />}
            onClick={handleAddButton}
          >
            Add Circular Group
          </Button>
        </HeaderContainer>
        <CircularGroupsTable />
      </Container>
      <AddCircularGroupModal
        visible={showAddModal}
        onCancel={handleAddCancel}
      />
    </>
  );
}
