import React, { useEffect, useState } from "react";
import { useSelector, useDispatch } from "react-redux";
import { getSubservices } from "./api";
import { Table, Alert, Select, Button } from "antd";
import { RootState } from "../../App";
import Search from "antd/lib/input/Search";
import { getServices } from "../services/api";
import { Subservice } from "./models";
import { EyeOutlined } from "@ant-design/icons";
import EditICon from '../../resources/ICON-edit-pencil.png';
import DeleteICon from '../../resources/ICON-trash-can-bin-delete-remove.png';

import ViewSubserviceModal from "./ViewSubserviceModal";
import EditSubserviceModal from "./EditSubserviceModal";
import DeleteSubserviceModal from "./DeleteSubserviceModal";
import { processDeleteSubService, showDeleteSubserviceModal, showEditSubserviceModal, showViewSubserviceModal } from "./slice";
import { BASE_URL } from "../../ApiClient";
const { Column } = Table;

export default function SubservicesTable() {
  const dispatch = useDispatch();

  const subservices = useSelector((state: RootState) => state.subservices.all);
  const error = useSelector((state: RootState) => state.subservices.error);
  const retailerId = useSelector(
    (state: RootState) => state.subservices.currentSubservice?.id
  );
  const [replica, setReplica] = React.useState(subservices);
  const [searchType, setSearchType] = React.useState('name');
  const [editId, setEditId] = React.useState<number>();
  const [deleteId, setDeleteId] = React.useState<number>();
  const orderStorageName = "subservice_order"
  let localOrder = localStorage.getItem(orderStorageName);
  const [order, setOrder] = useState<string | null>(localOrder);
  const [jsonOrder, setJsonOrder] = useState(JSON.parse(order !== null ? order : JSON.stringify({ order: "ascend", column: "name" })));
  const [columnAttr, setcolumnAttr] = React.useState<object>({
    sortOrder: jsonOrder?.order == "ascend" ? "ascend" : "descend",
    sortDirections: ["ascend", "descend", "ascend"]
  });
  useEffect(() => {
    if (order == null) {
      localStorage.setItem(orderStorageName, JSON.stringify({ order: "ascend", column: "name" }));
      setOrder(JSON.stringify({ order: "ascend", column: "name" }))
      setJsonOrder({ order: "ascend", column: "name" });
    }
    if (order !== null) {
      setJsonOrder(JSON.parse(order));
    }
  }, [order]);

  const onHeaderCell = (columnName: string) => {
    return {
      onClick: () => {
        if (jsonOrder.order == "descend") {
          localStorage.setItem(orderStorageName, JSON.stringify({ order: "ascend", column: columnName }));
          setOrder(JSON.stringify({ order: "ascend", column: columnName }))
          setcolumnAttr({ ...columnAttr, sortOrder: "ascend" })
        }
        else {
          localStorage.setItem(orderStorageName, JSON.stringify({ order: "descend", column: columnName }));
          setOrder(JSON.stringify({ order: "descend", column: columnName }));
          setcolumnAttr({ ...columnAttr, sortOrder: "descend" })
        }
      },
    };
  }

  // handle change search function
  const handleChangeSearch = (e: { target: { value: string; }; }) => {
    if (e.target.value.length > 0) {
      let filteredData: Subservice[] = [];
      switch (searchType) {
        case "name": filteredData = subservices.filter(data => data.name.toLowerCase().includes(e.target.value.toLowerCase()))
          break;
        case "uniqueID": filteredData = subservices.filter(data => data.uniqueID.toLowerCase().includes(e.target.value.toLowerCase()))
          break;
        case "serviceName": filteredData = subservices.filter(data => data.serviceName.toLowerCase().includes(e.target.value.toLowerCase()))
          break;
        case "website": filteredData = subservices.filter(data => data.website.toLowerCase().includes(e.target.value.toLowerCase()))
          break;
      }
      setReplica(filteredData)
    } else {
      setReplica(subservices)
    }
  };

  useEffect(() => {
    dispatch(getSubservices({ retailerId: retailerId }));
    dispatch(getServices({ retailerId: retailerId }));
  }, [dispatch, retailerId]);

  // handle change search type
  const handleChangeSearchType = (value: string) => {
    setSearchType(value)
  }

  useEffect(() => {
    setReplica(subservices)
  }, [subservices])

  //Handle view button click
  const handleViewButtonClick = (row: any) => {
    dispatch(showViewSubserviceModal(row.id));
  };

  // handle Edit button click
  const handleEditButtonClick = (row: any) => {
    setEditId(row.id);
    dispatch(showEditSubserviceModal(row.id));
  };
  //handle delete button Click
  const handleDeleteButtonClick = async(row: any) => {
    const result = await fetch(`${BASE_URL}api/CheckRetailerSubService/${row.id}`,{method : "POST"});
    const data = await result.json();
    setDeleteId(row.id);
    dispatch(processDeleteSubService(data));
    dispatch(showDeleteSubserviceModal(row.id));
  };
  return error ? (
    <Alert message="Error" description={error} type="error" showIcon />
  ) : (
    <>
      <div style={{ display: 'flex', flexDirection: 'row' }}>
        <div>
          <Select
            defaultValue="name"
            style={{ width: 250 }}
            options={[
              { value: 'name', label: 'Subservice Name' },
              { value: 'serviceName', label: 'Service Name' },
              { value: 'uniqueID', label: 'Unique ID' }
            ]}
            onChange={handleChangeSearchType}
          />
        </div>
        <Search placeholder="Search here" style={{ width: 300, marginBottom: '1em', marginLeft: '1em' }} allowClear onChange={handleChangeSearch} />
        <p style={{ width: 300, marginBottom: '1em', marginLeft: '1em' }}>Total Count: &nbsp; {replica.length} </p>
      </div>
      <Table
        // loading={subservices.length == 0 ? true : false}
        pagination={{ hideOnSinglePage: true }}
        dataSource={[...replica]
          ?.reverse()
          ?.map((el, index) => ({ ...el, index: index + 1, key: el?.id }))}
      >
        <Column
          title="SL No"
          dataIndex="index"
          key="index"
          {...(jsonOrder.column == "index" && columnAttr)}
          onHeaderCell={(column) => onHeaderCell("index")}
          sorter={(a: any, b: any) => {
            if (a.index < b.index) { return -1; }
            if (a.index > b.index) { return 1; }
            return 0;
          }} />
        <Column title="Subservice Name"
          {...(jsonOrder.column == "name" && columnAttr)}
          onHeaderCell={(column) => onHeaderCell("name")}
          dataIndex="name" key="name" sorter={(a: any, b: any) => {
            if (a.name < b.name) { return -1; }
            if (a.name > b.name) { return 1; }
            return 0;
          }}

        />

        <Column title="Service Name"
          dataIndex="serviceName"
          key="serviceName"
          {...(jsonOrder.column == "serviceName" && columnAttr)}
          onHeaderCell={(column) => onHeaderCell("serviceName")}
          sorter={(a: any, b: any) => {
            if (a.serviceName < b.serviceName) { return -1; }
            if (a.serviceName > b.serviceName) { return 1; }
            return 0;
          }}
        />

        <Column
          title="Unique ID"
          dataIndex="uniqueID"
          key="uniqueID"
          {...(jsonOrder.column == "uniqueID" && columnAttr)}
          onHeaderCell={(column) => onHeaderCell("uniqueID")}
          sorter={(a: any, b: any) => {
            if (a.uniqueID < b.uniqueID) { return -1; }
            if (a.uniqueID > b.uniqueID) { return 1; }
            return 0;
          }} />

        <Column
          title="View"
          key="view"
          align="center"
          render={(row: any) => {
            return (
              <div>
                <Button title="View"
                  type="link" onClick={() => handleViewButtonClick(row)}>
                  <EyeOutlined style={{ color: '#565050', height: '24px', width: '24px', margin: '0 0.8em' }} />
                </Button>
                <ViewSubserviceModal row={row} />
              </div>
            )
          }}
        />

        <Column
          title="Edit"
          key="edit"
          align="center"
          render={(row: any) => {
            return (
              <div>
                <Button title="Edit" type="link" onClick={() => handleEditButtonClick(row)}>
                  <img src={EditICon} alt="Edit Icon" style={{ height: '24px', width: '24px', margin: '0 0.8em' }} />
                </Button>
              {
                (editId && editId == row.id) && 
                <EditSubserviceModal row={row} />
              }
              </div>
            )
          }}
        />

        <Column
          title="Delete"
          key="delete"
          align="center"
          render={(row: any) => {
            return (
              <div>
                <Button
                  title="Delete"
                  type="link" danger onClick={() => handleDeleteButtonClick(row)}>
                  <img src={DeleteICon} alt="Edit Icon" style={{ height: '24px', width: '24px', margin: '0 0.8em' }} />
                </Button>
                {
                  (deleteId && deleteId == row.id) &&
                  <DeleteSubserviceModal row={row} />
                }
              </div>
            )
          }}
        />
      </Table>
    </>
  );
}
