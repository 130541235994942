import React from "react";
import { useDispatch } from "react-redux";
import { showAddConnectionModal } from "./slice";
import { Button } from "antd";
import { PlusOutlined } from "@ant-design/icons";
import styled from "styled-components";
import AddConnectionModal from "./AddConnectionModal";
import ConnectionsTable from "./ConnectionsTable";

const Container = styled.div`
  width: 100%;
  padding: 32px;
`;

const HeaderContainer = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
`;

export default function Connections() {
  const dispatch = useDispatch();
  // Handle Add Button
  const handleAddButton = () => {
    dispatch(showAddConnectionModal());
  };

  return (
    <>
      <Container>
        <HeaderContainer>
          <h1>Connections</h1>
          <Button
            type="primary"
            icon={<PlusOutlined />}
            onClick={handleAddButton}
          >
            Add Connection
          </Button>
        </HeaderContainer>
        <ConnectionsTable />
      </Container>
      <AddConnectionModal />
    </>
  );
}
