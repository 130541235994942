import React, { useEffect } from "react";
import { useSelector, useDispatch } from "react-redux";
import { getRecipesCategories } from "./api";
import { RootState } from "../../App";
import { Table, Alert, Tag } from "antd";
import { ActionsRow } from "./ActionsRow";
import { RecipesCategory, Recipe } from "./models";

const { Column } = Table;

interface LengthProps {
  category: RecipesCategory;
}
const CategoryLength: React.FC<LengthProps> = ({ category }) => {
  const length = useSelector(
    (state: RootState) =>
      state?.recipes?.recipesByCategory[category.id as string]?.length
  );

  return length !== undefined ? <span>{length} recipes</span> : null;
};

interface CategoryMainProps {
  category: RecipesCategory;
}

const CategoryMain: React.FC<CategoryMainProps> = ({ category }) => {
  const main: any = useSelector((state: RootState) =>
    state?.recipes?.recipesByCategory[category.id as string]?.find(
      (recipe: Recipe) => recipe.mainRecipe
    )
  );




  return main ? (
    <div style={{ maxWidth: 120 }}>
      <p
        style={{
          maxWidth: 120,
          whiteSpace: "nowrap",
          overflow: "hidden",
          textOverflow: "ellipsis",
        }}
      >
        {console.log(main, "---main", main?.title)}
        {main.title || main?.display?.displayName}
      </p>
    </div>
  ) : (
    <Tag color="warning">No main recipe</Tag>
  );
};

export const RecipesCategoriesTable = () => {
  const dispatch = useDispatch();
  const categories = useSelector(
    (state: RootState) => state.recipes.categories
  );

  const error = useSelector((state: RootState) => state.recipes.getError);

  useEffect(() => {
    dispatch(getRecipesCategories());
  }, [dispatch]);

  return error ? (
    <Alert message="Error" description={error} type="error" showIcon />
  ) : (
    <Table
      pagination={{ hideOnSinglePage: true }}
      dataSource={categories.map((categ, index) => ({
        ...categ,
        index: index + 1,
        key: categ.id,
      }))}
    >
      <Column title="#" dataIndex="index" key="index" />
      <Column title="Name" dataIndex="name" key="name" />
      <Column
        title="Main Recipe"
        key="length"
        render={(row: RecipesCategory) => <CategoryMain category={row} />}
      />
      <Column
        title="Recipes"
        key="length"
        render={(row: any) => <CategoryLength category={row} />}
      />

      <Column
        title=""
        key="actions"
        render={(row: any) => {
          return <ActionsRow key={row.id} row={row} />;
        }}
      />
    </Table>
  );
};
