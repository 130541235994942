import React from "react";
import styled from "styled-components";
import { useDispatch, useSelector } from "react-redux";
import { Button, Spin } from "antd";
import { PlusOutlined } from "@ant-design/icons";
import { RecipesCategoriesTable } from "./RecipesCategoriesTable";
import { showAddCategoryModal } from "./slice";
import { AddRecipesCategoryModal } from "./AddRecipesCategoryModal";
import { RecipeOfTheWeek } from "./RecipeOfTheWeek";
import { RootState } from "../../App";

const Container = styled.div`
  width: 100%;
  padding: 32px;
`;

const HeaderContainer = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
`;

export const Recipes = () => {
  const dispatch = useDispatch();
  const handleAddButton = () => {
    dispatch(showAddCategoryModal());
  };

  const loading = useSelector(
    (state: RootState) =>
      state.recipes.editCategoryLoading ||
      state.recipes.getRecipesByCategoryLoading
  );

  return (
    <>
      <Container>
        <RecipeOfTheWeek />
        <HeaderContainer>
          <h1>Recipe Categories</h1>
          <Button
            type="primary"
            icon={<PlusOutlined />}
            onClick={handleAddButton}
          >
            Add Recipe Category
          </Button>
        </HeaderContainer>
        <Spin spinning={loading}>
          <RecipesCategoriesTable />
        </Spin>
        <AddRecipesCategoryModal />
      </Container>
    </>
  );
};
