import React, { useState, useEffect } from "react";
import { useSelector, useDispatch } from "react-redux";

import { Modal, Input, Button, Checkbox, Spin, Alert, Divider } from "antd";
import { RootState } from "../../App";
import { RecipesCategory, Recipe, APIRecipe } from "./models";
import { hideEditCategoryModal } from "./slice";
import {
  getRecipesByCategory,
  editRecipe,
  editRecipesCategory,
  deleteRecipe,
  setRecipeOfTheWeek,
} from "./api";
import { RecipePreviewModal } from "./RecipePreviewModal";
import { ToastContainer, toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';

const { Search } = Input;

interface EditRecipesCategoryModalProps {
  category: RecipesCategory;
}

export const EditRecipesCategoryModal: React.FC<EditRecipesCategoryModalProps> = ({
  category,
}) => {
  const dispatch = useDispatch();

  const recipes = useSelector(
    (state: RootState) => state.recipes.recipesByCategory[category.id as string]
  );

  const visible = useSelector(
    (state: RootState) => state.recipes.showEditCategoryModal === category.id
  );

  const [nameInputVal, setNameInputVal] = useState(category.name);
  const [previewVisible, setPreviewVisible] = useState<string | null>(null);

  const loading = useSelector(
    (state: RootState) =>
      state.recipes.addCategoryLoading ||
      state.recipes.getLoading ||
      state.recipes.getRecipesByCategoryLoading ||
      state.recipes.deleteRecipeLoading ||
      state.recipes.editCategoryLoading ||
      state.recipes.editRecipeLoading
  );

  const errors = useSelector((state: RootState) => [
    state.recipes.editRecipeError,
    state.recipes.editCategoryError,
    state.recipes.deleteRecipeError,
    state.recipes.getRecipesByCategoryError,
  ]);

  useEffect(() => {
    if (visible) {
      dispatch(getRecipesByCategory(category.id as string));
    } else {
      // dispatch(clearCategoryState(category.id as string));
      setNameInputVal(category.name);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [visible]);

  const handleCancel = () => {
    dispatch(hideEditCategoryModal());
    // setNameInputVal(category.name);
  };

  const handleSaveName = () => {
    dispatch(editRecipesCategory({ ...category, name: nameInputVal }));
  };

  const handleMainRecipeCheckbox = (recipe: Recipe) => {
    dispatch(editRecipe({ ...recipe, mainRecipe: true } as APIRecipe));
  };

  const handleRecipeOfTheWeekCheckbox = (recipe: Recipe) => {
    // dispatch(editRecipe({ ...recipe, recipeOfTheWeek: true } as APIRecipe));
    dispatch(setRecipeOfTheWeek(recipe.id));
    setNameInputVal(category.name);
  };

  const handleFeaturedCheckboxChange = () => {
    dispatch(
      editRecipesCategory({ ...category, featured: !category.featured })
    );
    setNameInputVal(category.name);
  };

  const handleDelete = (recipe: any) => {
    console.log("recipe id", recipe);
    const id = recipe["tracking-id"]
    dispatch(deleteRecipe(recipe?.id as string));
    // dispatch(deleteRecipe(recipe.id as string));
  };

  const diffToast = () => {
    toast("Recipe deleted successfully")
  }

  const handleNameInputChange = (
    event: React.ChangeEvent<HTMLInputElement>
  ) => {
    setNameInputVal(event.target.value);
  };

  const handlePreviewClick = (recipeId: string) => {
    setPreviewVisible(recipeId);
  };

  return (
    <Modal
      title={"Edit Recipe Category"}
      visible={visible}
      okText="Save"
      onCancel={handleCancel}
      footer={<Button onClick={handleCancel}>Close</Button>}
      destroyOnClose
      maskClosable={false}
      width={580}
      bodyStyle={{ height: "70vh", overflowX: "scroll" }}
    >
      {errors.map(
        (error: string | null) =>
          error && (
            <>
              <Alert
                message="Error"
                description={error}
                type="error"
                showIcon
              />
              <Divider />
            </>
          )
      )}
      <Spin spinning={loading}>
        <>
          {recipes && recipes.find((recipe) => recipe.mainRecipe) ? null : (
            <Alert
              message="Warning"
              description="No main recipe selected. Please choose one"
              type="warning"
              showIcon
              // closable
              style={{ marginBottom: 16 }}
            />
          )}
          <div style={{ display: "flex", flexDirection: "column" }}>
            <div style={{ display: "flex", marginBottom: 16 }}>
              <Search
                value={nameInputVal}
                onChange={handleNameInputChange}
                onSearch={handleSaveName}
                enterButton={"Update Name"}
              />
            </div>
            <div style={{ display: "flex", marginBottom: 16 }}>
              <Checkbox
                checked={category.featured}
                onChange={handleFeaturedCheckboxChange}
              />
              <h4 style={{ marginLeft: 8 }}>Featured Category</h4>
            </div>
          </div>
          {recipes
            ? recipes.map((recipe: any) => (
              // ? recipes.map((recipe: Recipe) => (
              <div
                style={{ display: "flex", marginTop: 8, marginBottom: 8 }}
                key={recipe.id}
              >
                <img
                  src={recipe.image || recipe.content?.details?.images[0]?.hostedLargeUrl}
                  alt={recipe.content?.details?.displayName}
                  style={{ width: 80 }}
                />
                <div
                  style={{
                    display: "flex",
                    flexDirection: "column",
                    justifyContent: "center",
                    flex: 1,
                    marginLeft: 8,
                  }}
                >
                  <h4 style={{ marginBottom: 0, marginLeft: 16 }}>
                    {recipe.title || recipe.content?.details?.name}
                  </h4>
                  <div
                    style={{
                      display: "flex",
                      alignItems: "center",
                      // justifyContent: "flex-end",
                      marginLeft: 16,
                    }}
                  >
                    <Checkbox
                      checked={recipe.mainRecipe}
                      onChange={() => handleMainRecipeCheckbox(recipe)}
                    />
                    <h4 style={{ marginLeft: 8, marginBottom: 0 }}>
                      Main Recipe
                    </h4>
                    <Checkbox
                      checked={recipe.recipeOfTheWeek}
                      onChange={() => handleRecipeOfTheWeekCheckbox(recipe)}
                      style={{ marginLeft: 12 }}
                    />
                    <h4 style={{ marginLeft: 8, marginBottom: 0 }}>
                      Recipe of the Week
                    </h4>
                    <Button
                      type="link"
                      onClick={() => handlePreviewClick(recipe.id)}
                    >
                      Preview
                    </Button>
                    <RecipePreviewModal
                      visible={recipe.id === previewVisible}
                      onCancel={() => setPreviewVisible(null)}
                      recipe={recipe}
                    />

                    <Button
                      type="link"
                      danger
                      onClick={() => {
                        handleDelete(recipe);
                        diffToast();
                      }}
                    >
                      Delete
                    </Button>
                  </div>
                </div>
              </div>
            ))
            : null}
        </>{" "}
      </Spin>
      <ToastContainer />
    </Modal>
  );
};
