import axios, { AxiosError } from "axios";
import { store } from "./App";

export const BASE_URL = document.location.host.includes("localhost")
  ? "https://admin.grocerytech.solutions/"
  : "https://admin.grocerytech.solutions/";
  // ? "https://localhost:44300/"
  // : "https://localhost:44300/";

//For Epic Cyber 
export const EPIC_BASE_URL = document.location.host.includes("localhost")
  ? "https://admin.grocerytech.solutions/"
  : "https://admin.grocerytech.solutions/";
  // ?"https://localhost:44300/"
  // :"https://localhost:44300/";

export const epicApiClient = axios.create({
  baseURL: EPIC_BASE_URL,
  responseType: "json",
  headers: {
    "Content-Type": "application/json",
  },
});

export const apiClient = axios.create({
  baseURL: BASE_URL,
  responseType: "json",
  headers: {
    "Content-Type": "application/json",
  },
});
//For Epic Cyber 
epicApiClient.interceptors.response.use(undefined, (error: AxiosError) => {
  const statusCode = error.response?.status;
  if (statusCode === 401) {
    store.dispatch({ type: "auth/logOut" });
  }
  return Promise.reject(error);
});

apiClient.interceptors.response.use(undefined, (error: AxiosError) => {
  const statusCode = error.response?.status;
  if (statusCode === 401) {
    store.dispatch({ type: "auth/logOut" });
  }
  return Promise.reject(error);
});