import { createAsyncThunk } from "@reduxjs/toolkit";
import { apiClient } from "../../ApiClient";
import { RootState } from "../../App";
import { createAuthConfig } from "../auth/api";
import { Retailer } from "../retailers/models";
import { EmailStatistic } from "./models";

export const getQuota = createAsyncThunk(
  "emails/getQuota",
  async (
    _,
    thunkAPI
  ): Promise<any | ReturnType<typeof thunkAPI.rejectWithValue>> => {
    try {
      const response = await apiClient.get(
        "/api/SentEmails/Quota",
        createAuthConfig()
      );

      return response.data;
    } catch (e) {
      if (e.response && e.response.data && e.response.data.status === 400) {
        return thunkAPI.rejectWithValue(
          [].concat.apply([], Object.values(e.response.data.errors)).join(" ")
        );
      }
      return thunkAPI.rejectWithValue("Error getting circulars");
    }
  }
);

export const getEmailsReport = createAsyncThunk(
  "emails/getRetailerEmailsReport",
  async (
    _,
    thunkAPI
  ): Promise<
    Array<EmailStatistic> | ReturnType<typeof thunkAPI.rejectWithValue>
  > => {
    const retailer = getRetailerFromStore(thunkAPI.getState()) as Retailer;

    const dateInterval = (thunkAPI.getState() as RootState).emails.dateInterval;
    try {
      if (retailer) {
        const response = await apiClient.post(
          "/api/SentEmails/GetCountByRetailer",
          { ...dateInterval, retailer },
          createAuthConfig()
        );
        return [{ retailer, count: response.data.count }];
      } else {
        const response = await apiClient.post(
          "/api/SentEmails/CountTotalRetailers",
          dateInterval,
          createAuthConfig()
        );
        return response.data;
      }
    } catch (e) {
      if (e.response && e.response.data && e.response.data.status === 400) {
        return thunkAPI.rejectWithValue(
          [].concat.apply([], Object.values(e.response.data.errors)).join(" ")
        );
      }
      return thunkAPI.rejectWithValue("Error getting circulars");
    }
  }
);

const getRetailerFromStore = (Store: any) => {
  return (Store as RootState).currentRetailer.currentRetailer;
};
