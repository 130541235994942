import React from "react";
import { useDispatch } from "react-redux";
import { showEditStoreModal, showDeleteStoreModal } from "./slice";
import { Button } from "antd";
import EditStoreModal from "./EditStoreModal";
import DeleteStoreModal from "./DeleteStoreModal";

export default function StoresActionsRow(props: any) {
  const dispatch = useDispatch();
  const { row } = props;

  const handleEditClick = () => {
    dispatch(showEditStoreModal(row.id));
  };

  const handleDeleteClick = () => {
    dispatch(showDeleteStoreModal(row.id));
  };

  return (
    <>
      <div style={{ display: "flex", justifyContent: "flex-end" }}>
        <Button type="link" onClick={handleEditClick}>
          Edit
        </Button>
        <EditStoreModal row={row} />
        <Button type="link" danger onClick={handleDeleteClick}>
          Delete
        </Button>
        <DeleteStoreModal row={row} />
      </div>
    </>
  );
}
