import React from "react";
import { useDispatch } from "react-redux";
import { showAddPropertyModal } from "./slice";
import { Button } from "antd";
import { PlusOutlined } from "@ant-design/icons";
import styled from "styled-components";
import AddPropertyModal from "./AddPropertyModal";
import PropertiesTable from "./PropertiesTable";

const Container = styled.div`
  width: 100%;
  padding: 32px;
`;

const HeaderContainer = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
`;

export default function Services() {
  const dispatch = useDispatch();

  // Handle Add Button
  const handleAddButton = () => {
    dispatch(showAddPropertyModal());
  };

  return (
    <>
      <Container>
        <HeaderContainer>
          <h1>Properties</h1>
          <Button
            type="primary"
            icon={<PlusOutlined />}
            onClick={handleAddButton}
          >
            Add Property
          </Button>
        </HeaderContainer>
        <PropertiesTable />
      </Container>
      <AddPropertyModal />
    </>
  );
}
