import React, { useState, useEffect, ChangeEvent, ReactElement } from "react";
import { useSelector } from "react-redux";
import { Input, Select, Row, Col, Modal, Checkbox, Alert, Divider } from "antd";
import { geocodeByAddress, getLatLng } from "react-places-autocomplete";
import AddressInput from "../../components/AddressInput";
import { states } from "../common/utils/states";
import { formatAddressAndSlug } from "./utils";
import { RootState } from "../../App";
import { Form } from "antd";
import { Store } from "./models";

interface StoreFormErrorsType {
  city: string | undefined;
  name: string | undefined;
  slug: string | undefined;
  state: string | undefined;
  address1: string | undefined;
  circularGroupId?: string | undefined;
  retailerId: string | undefined;
  hasExistingShoppingList?: boolean | undefined;
  existingShoppingListUrl?: string | undefined;
}

export interface StoreFormValues {
  name?: string | undefined;
  slug?: string;
  storeNumber?: number | string;
  retailerId?: string;
  circularGroupId?: string | undefined;
  address1?: string;
  address2?: string;
  city?: string;
  state?: string;
  zipCode?: string;
  phone?: string;
  // anchorTag?: string;
  email?: string;
  storeInfo?: string;
  hours?: string;
  description?: string;
  latitude?: number;
  longitude?: number;
  stringNumber?: number;
  rewardsDeviceId?: string;
  circularGroup?: any;
  id?: string;
  hasExistingShoppingList?: boolean;
  existingShoppingListUrl?: string;
}

const { Option } = Select;
const { TextArea } = Input;

const InputWrapper = (props: {
  label: string;
  error?: string | undefined;
  children: ReactElement;
  marginLeft?: string;
}) => {
  const { label, children, error } = props;
  return (
    <Row
      style={{
        marginBottom: 24,
        marginLeft: props.marginLeft ? props.marginLeft : undefined,
      }}
    >
      <Col span={8}>
        <div
          style={{
            display: "flex",
            justifyContent: "flex-end",
          }}
        >
          <label style={{ marginRight: 16, textAlign: "right" }}>{label}</label>
        </div>
      </Col>
      <Col span={14}>
        <Form.Item
          style={{ marginBottom: 0 }}
          validateStatus={!!error ? "error" : undefined}
          help={error}
        >
          {children}
        </Form.Item>
      </Col>
    </Row>
  );
};

const ControlledTextInput = (props: {
  label: string;
  value: string | number;
  id: string;
  textArea?: boolean;
  error?: string | undefined;
  onChange: (event: ChangeEvent) => void;
}) => {
  const { label, value, id, onChange, textArea, error } = props;
  return (
    <InputWrapper label={label} error={error}>
      {textArea ? (
        <TextArea id={id} value={value} onChange={onChange} />
      ) : (
        <Input id={id} value={value} onChange={onChange} />
      )}
    </InputWrapper>
  );
};

export default function StoreFormModal(props: {
  initialValues?: StoreFormValues | Store;
  title: string;
  visible: boolean;
  onOk: (values: any) => void;
  onCancel: () => void;
  okText: string;
  cancelText: string;
  error: string | null;
  loading: boolean;
}) {
  const {
    initialValues,
    // onSubmit,
    title,
    visible,
    onOk,
    onCancel,
    okText,
    cancelText,
    error,
    loading,
  } = props;

  const [formValues, setFormValues] = useState<
    StoreFormValues | Store | undefined
  >({ ...initialValues, circularGroupId: initialValues?.circularGroup.id });

  const [errors, setErrors] = useState<StoreFormErrorsType | null>(null);

  const circularGroups = useSelector(
    (state: RootState) => state.circularGroups.all
  );

  const retailers = useSelector((state: RootState) => state.retailers.all);

  const currentRetailerId = useSelector(
    (state: RootState) => state.currentRetailer.currentRetailer?.id
  );

  const [retailer, setRetailer] = useState<string | null | undefined>(
    initialValues?.circularGroup.retailer.id ?? currentRetailerId
  );

  const [retailerChanged, setRetailerChanged] = useState(false);

  const [addressInputValue, setAddressInputValue] = useState<
    string | undefined | null
  >("");

  useEffect(() => {
    setAddressInputValue(initialValues?.name);
  }, [initialValues]);

  useEffect(() => {
    if (retailerChanged) {
      setFormValues({ ...formValues, circularGroupId: undefined });
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [retailer]);

  useEffect(() => {
    if (currentRetailerId) {
      setRetailer(currentRetailerId);
    }
  }, [currentRetailerId]);

  useEffect(() => {
    if (!visible) {
      if (!initialValues) {
        //if it's an ADD form
        setFormValues(initialValues);
        setRetailer(currentRetailerId ?? null);
        setAddressInputValue("");
        setErrors(null);
      } else {
        //if it's an EDIT form
        setFormValues({
          ...initialValues,
          circularGroupId: initialValues.circularGroup.id,
        });
        setRetailer(initialValues.circularGroup.retailer.id);
        setAddressInputValue(initialValues.name);
        setErrors(null);
      }
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [visible]);

  const handleInputChange = (e: any) => {
    const newVar =
      e.target.id === "hasExistingShoppingList"
        ? e.target.checked
        : e.target.value;
    setFormValues({
      ...formValues,
      [e.target.id]: newVar,
    });
  };

  const handleSelectChange = (key: string, val: string) => {
    setFormValues({ ...formValues, [key]: val });
  };

  const handleRetailerChanged = (val: string) => {
    setRetailerChanged(true);
    setRetailer(val);
  };

  /* address change handling */
  const onAddressChange = (val: string) => {
    setAddressInputValue(val);
  };

  const onAddressSelect = async (val: string) => {
    try {
      const res = await geocodeByAddress(val);
      const coordinates = await getLatLng(res[0]);
      setFormValues({
        ...formValues,
        ...formatAddressAndSlug(res[0], coordinates),
        googlePlacesId: res[0].place_id,
      });
    } catch (e) { }
  };

  const onSuggestionClick = (suggestion: any) => {
    setAddressInputValue(suggestion.formattedSuggestion.mainText);
  };

  const validate = async () => {
    const errs: StoreFormErrorsType = {
      name: !!addressInputValue?.trim() ? undefined : "Name is required",
      city: !!formValues?.city?.trim() ? undefined : "City is required",
      slug: !!formValues?.slug?.trim() ? undefined : "Slug is required",
      state: !!formValues?.state?.trim() ? undefined : "State is required",
      address1: !!formValues?.address1?.trim()
        ? undefined
        : "Addres1 is required",
      circularGroupId: !!formValues?.circularGroupId
        ? undefined
        : "Circular Group is required",
      retailerId: !!retailer ? undefined : "Retailer is required",
    };

    Object.keys(errs).forEach((key: string) => {
      //@ts-ignore
      if (errs[key] !== undefined) {
        throw errs;
      }
    });

    return;
  };

  const handleOk = async () => {
    try {
      await validate();
      onOk({ ...formValues, name: addressInputValue });
    } catch (e) {
      setErrors(e);
    }
  };

  return (
    <Modal
      maskClosable={false}
      title={title}
      visible={visible}
      style={{ minWidth: 800 }}
      okText={okText}
      cancelText={cancelText}
      onOk={handleOk}
      onCancel={onCancel}
      okButtonProps={loading ? { loading } : undefined}
      destroyOnClose
    >
      {error && (
        <>
          <Alert message="Error" description={error} type="error" showIcon />
          <Divider />
        </>
      )}
      <Row>
        <Col span={12}>
          <InputWrapper label="Name" error={errors?.name}>
            <AddressInput
              onChange={onAddressChange}
              onSelect={onAddressSelect}
              onSuggestionClick={onSuggestionClick}
              value={addressInputValue ?? ""}
            />
          </InputWrapper>
          <ControlledTextInput
            label="Slug"
            id="slug"
            value={formValues?.slug ?? ""}
            onChange={handleInputChange}
            error={errors?.slug}
          />
          <ControlledTextInput
            label="Store Number"
            id="storeNumber"
            value={formValues?.storeNumber ?? ""}
            onChange={handleInputChange}
          />

          {!currentRetailerId && (
            <InputWrapper label="Retailer" error={errors?.retailerId}>
              <Select
                // defaultValue={initialValues?.circularGroup.retailerId}
                placeholder="Select a retailer"
                allowClear
                id="retailer"
                value={retailer ?? ""}
                onChange={handleRetailerChanged}
                style={{ width: "100%" }}
              >
                {retailers.map((retailer, index) => (
                  <Option key={index} value={retailer.id}>{retailer.name}</Option>
                ))}
              </Select>
            </InputWrapper>
          )}

          <InputWrapper label="Circular Group" error={errors?.circularGroupId}>
            <Select
              placeholder="Select a Circular Group"
              allowClear
              id="retailer"
              value={formValues?.circularGroupId ?? ""}
              disabled={!retailer}
              onChange={(val) => handleSelectChange("circularGroupId", val)}
              style={{ width: "100%" }}
            >
              {circularGroups
                .filter((cg) => cg.retailer.id === retailer)
                .map((cg, index) => (
                  <Option key={index} value={cg.id}>{cg.name}</Option>
                ))}
            </Select>
          </InputWrapper>

          <ControlledTextInput
            label="Address 1"
            id="address1"
            value={formValues?.address1 ?? ""}
            onChange={handleInputChange}
            error={errors?.address1}
          />
          <ControlledTextInput
            label="Address 2"
            id="address2"
            value={formValues?.address2 ?? ""}
            onChange={handleInputChange}
          />
          <ControlledTextInput
            label="City"
            id="city"
            value={formValues?.city ?? ""}
            onChange={handleInputChange}
            error={errors?.city}
          />

          <InputWrapper label="State" error={errors?.state}>
            <Select
              placeholder="State"
              allowClear
              id="retailer"
              value={formValues?.state ?? ""}
              onChange={(val) => handleSelectChange("state", val)}
              style={{ width: "100%" }}
            >
              {states.map((state, index) => (
                <Option key={index} value={state.abbreviation}>{state.name}</Option>
              ))}
            </Select>
          </InputWrapper>

          <ControlledTextInput
            label="ZIP Code"
            id="zipCode"
            value={formValues?.zipCode ?? ""}
            onChange={handleInputChange}
          />
          <ControlledTextInput
            label="Phone"
            id="phone"
            value={formValues?.phone ?? ""}
            onChange={handleInputChange}
          />
        </Col>
        <Col span={12}>
          <ControlledTextInput
            label="Store Email"
            id="email"
            value={formValues?.email ?? ""}
            onChange={handleInputChange}
          />
          <ControlledTextInput
            textArea
            label="Store Info"
            id="storeInfo"
            value={formValues?.storeInfo ?? ""}
            onChange={handleInputChange}
          />
          <ControlledTextInput
            textArea
            label="Hours"
            id="hours"
            value={formValues?.hours ?? ""}
            onChange={handleInputChange}
          />
          <ControlledTextInput
            textArea
            label="Description"
            id="description"
            value={formValues?.description ?? ""}
            onChange={handleInputChange}
          />
          <ControlledTextInput
            label="Latitude"
            id="latitude"
            value={formValues?.latitude ?? ""}
            onChange={handleInputChange}
          />
          <ControlledTextInput
            label="Longitude"
            id="longitude"
            value={formValues?.longitude ?? ""}
            onChange={handleInputChange}
          />
          <InputWrapper label="Has Shopping List">
            <Checkbox
              // style={{ width: "100%" }}
              // label="Has Existing Shopping List"
              id="hasExistingShoppingList"
              checked={formValues?.hasExistingShoppingList ?? false}
              onChange={(e) => {
                handleInputChange(e);
              }}
            />
          </InputWrapper>
          {(formValues?.hasExistingShoppingList ?? false) && (
            <InputWrapper label={"Shopping List Url"}>
              <Input
                id="existingShoppingListUrl"
                value={formValues?.existingShoppingListUrl ?? ""}
                onChange={handleInputChange}
              />
            </InputWrapper>
          )}
        </Col>
      </Row>
    </Modal>
  );
}
