import { Connection, ConnectionWithoutId } from "./models";
import { apiClient, epicApiClient } from "../../ApiClient";
import { createAsyncThunk } from "@reduxjs/toolkit";
import { createAuthConfig } from "../auth/api";

interface MyData {
  "connection_data": []
}
// Get Connections
export const getConnections = createAsyncThunk(
  "/api/Connections/Get",
  async (
    _params: { retailerId?: string },
    thunkAPI
  ): Promise<Connection[] | ReturnType<typeof thunkAPI.rejectWithValue>> => {
    try {
      const retailersResponse = await epicApiClient.get<MyData>(
        `api/allconnections`,
        createAuthConfig()
      );
      const x: Connection[] = retailersResponse.data["connection_data"];
      return x as Connection[];
    } catch (e) {
      if (e.response && e.response.data && e.response.data.status === 400) {
        return thunkAPI.rejectWithValue(
          [].concat.apply([], Object.values(e.response.data.errors)).join(" ")
        );
      }
      return thunkAPI.rejectWithValue("Error getting connections");
    }
  }
);

// Add Connection
export const addConnection = createAsyncThunk(
  "/api/Connections/Add",
  async (
    params: ConnectionWithoutId,
    thunkAPI
  ): Promise<Connection | ReturnType<typeof thunkAPI.rejectWithValue>> => {
    try {
      const retailerResponse = await apiClient.post<{ message: string, connection_data: Connection }>(
        "api/addconnection",
        createAuthConfig()
      );
      return retailerResponse.data.connection_data;
    } catch (e) {
      if (e.response && e.response.data && e.response.data.status === 400) {
        return thunkAPI.rejectWithValue(
          [].concat.apply([], Object.values(e.response.data.errors)).join(" ")
        );
      }
      return thunkAPI.rejectWithValue(`Error adding connection ${e?.response?.data?.error} ${e?.response?.data?.message}`);
    }
  }
);

// Edit Connection
export const editConnection = createAsyncThunk(
  "/api/Connection/Edit",
  async (
    params: Connection,
    thunkAPI
  ): Promise<Connection | ReturnType<typeof thunkAPI.rejectWithValue>> => {
    try {
      const retailerResponse = await epicApiClient.post<{ message: string, Connection: Connection }>(
        `api/editconnection`,
        params,
        // createAuthConfig()
      );
      return retailerResponse.data.Connection;
    } catch (e) {
      if (e.response && e.response.data && e.response.data.status === 400) {
        return thunkAPI.rejectWithValue(
          [].concat.apply([], Object.values(e.response.data.errors)).join(" ")
        );
      }
      return thunkAPI.rejectWithValue(`Error editing connection  ${e?.response?.data?.error} ${e?.response?.data?.message}`);
    }
  }
);

//Delete Connection
export const deleteConnection = createAsyncThunk(
  "/api/Connections/Delete",
  async (
    params: Partial<Connection>,
    thunkAPI
  ): Promise<string | undefined | ReturnType<typeof thunkAPI.rejectWithValue>> => {
    try {
      const response = await epicApiClient.post<{ message: string, ["Status code"]: number }>(
        `api/deleteconnection/${params.id}`,
        createAuthConfig()
      );
      return params.id;
    } catch (e) {
      if (e.response && e.response.data && e.response.data.status === 400) {
        return thunkAPI.rejectWithValue(
          [].concat.apply([], Object.values(e.response.data.errors)).join(" ")
        );
      }
      return thunkAPI.rejectWithValue(`Error deleting connection ${e?.response?.data?.error} ${e?.response?.data?.message}`);
    }
  }
);

//schedule now 
export const scheduleNowConnection = createAsyncThunk(
  "/api/Connections/scheduleNow",
  async (
    params: Connection,
    thunkAPI
  ): Promise<Connection | ReturnType<typeof thunkAPI.rejectWithValue>> => {
    try {
      const response = await epicApiClient.post<{ message: string, connection_data: Connection }>(
        `api/scheduleconnection`,
        params,
        createAuthConfig()
      );
      return response.data.connection_data;
    } catch (e) {
      if (e.response && e.response.data && e.response.data.status === 400) {
        return thunkAPI.rejectWithValue(
          [].concat.apply([], Object.values(e.response.data.errors)).join(" ")
        );
      }
      return thunkAPI.rejectWithValue(`Error scheduling connection${e?.response?.data?.error} ${e?.response?.data?.message}`);
    }
  }
);

//run now 
export const runNowConnection = createAsyncThunk(
  "/api/Connections/runNow",
  async (
    params: Connection,
    thunkAPI
  ): Promise<Connection | ReturnType<typeof thunkAPI.rejectWithValue>> => {
    try {
      const response = await epicApiClient.post<{ message: string, connection_data: Connection }>(
        `api/addconnection`,
        params,
        createAuthConfig()
      );
      console.log(response);
      
      return response.data.connection_data;
    } catch (e) {
      if (e.response && e.response.data && e.response.data.status === 400) {
        return thunkAPI.rejectWithValue(
          [].concat.apply([], Object.values(e.response.data.errors)).join(" ")
        );
      }
      return thunkAPI.rejectWithValue(`Error Run Now  Connection ${e?.response?.data?.error} ${e?.response?.data?.message}`);
    }
  }
);


// Edit Schedule Now Connection
export const editScheduleNowConnection = createAsyncThunk(
  "/api/Connection/EditScheduleNow",
  async (
    params: Connection,
    thunkAPI
  ): Promise<Connection | ReturnType<typeof thunkAPI.rejectWithValue>> => {
    try {
      const retailerResponse = await epicApiClient.post<{ message: string, Connection: Connection }>(
        `api/editscheduleconnection`,
        params,
        // createAuthConfig()
      );
      return retailerResponse.data.Connection;
    } catch (e) {
      if (e.response && e.response.data && e.response.data.status === 400) {
        return thunkAPI.rejectWithValue(
          [].concat.apply([], Object.values(e.response.data.errors)).join(" ")
        );
      }
      return thunkAPI.rejectWithValue(`Error editing connection  ${e?.response?.data?.error} ${e?.response?.data?.message}`);
    }
  }
);