import React from "react";
import { Alert, Divider, Modal } from "antd";
import { useSelector } from "react-redux";
import { RootState } from "../../../App";

type ConfirmDeleteModalProps = {
  title: string;
  visible: boolean;
  onCancel: () => void;
  onConfirm: () => void;
  label: string;
  isLoading?: boolean;
  error?: string;
};
export const ConfirmDeleteModal = (props: ConfirmDeleteModalProps) => {
  const { title, visible, onCancel, onConfirm, label } = props;
  const loading = useSelector((state: RootState) => state.users.loading);
  return (
    <Modal
      zIndex={90001}
      maskClosable={false}
      title={title}
      confirmLoading={loading}
      visible={visible}
      onCancel={onCancel}
      okText="Delete"
      onOk={onConfirm}
      okButtonProps={{ danger: true, loading: props.isLoading }}
    >
      {props.error && (
        <>
          <Alert
            message="Error"
            description={props.error}
            type="error"
            showIcon
          />
          <Divider />
        </>
      )}
      <h3>{label}</h3>
    </Modal>
  );
};
