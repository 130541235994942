import React, { useEffect } from "react";
import { useSelector, useDispatch } from "react-redux";
import { RootState } from "../../App";
import { Modal, Divider, Alert } from "antd";
import { hideDeleteCircularModal } from "./slice";
import { deleteCircular } from "./api";
import { useParams } from "react-router";

export default function DeleteCircularModal(props: any) {
  const { row } = props;

  const dispatch = useDispatch();

  const visible = useSelector(
    (state: RootState) => state.circulars.showDeleteModal === row.id
  );

  const loading = useSelector(
    (state: RootState) => state.circulars.deleteLoading
  );

  const handleCancel = () => {
    dispatch(hideDeleteCircularModal());

  };
  const handleDelete = () => {
    dispatch(deleteCircular(row));
  };

  const error = useSelector((state: RootState) => state.circulars.deleteError);
  return (
    <Modal
      maskClosable={false}
      title="Delete Circular"
      visible={visible}
      onCancel={handleCancel}
      okText="Delete"
      onOk={handleDelete}
      okButtonProps={{ danger: true, loading: loading || undefined }}
    >
      {error && (
        <>
          <Alert message="Error" description={error} type="error" showIcon />
          <Divider />
        </>
      )}
      <h3>Are you sure you want to delete {row.name}?</h3>
    </Modal>
  );
}
