import { Alert, Divider, Input, Modal } from "antd";
import { apiClient } from "../../ApiClient";
import { AxiosError } from "axios";
import { stringifyErrors } from "../common/utils/utils";
import React, { useState } from "react";

export const ForgotEmailModal = (props: {
  visible: boolean;
  onSetVisible: (v: boolean) => void;
}) => {
  const [forgotEmailValue, setForgotEmailValue] = useState("");
  const [forgotLoading, setForgotLoading] = useState(false);
  const [forgotError, setForgotError] = useState<string | undefined>(undefined);

  return (
    <Modal
      visible={props.visible}
      okText={"Send Recovery Email"}
      title={"Forgot Password"}
      onCancel={() => {
        props.onSetVisible(false);
        setForgotError(undefined);
      }}
      confirmLoading={forgotLoading}
      onOk={async () => {
        setForgotError(undefined);  
        setForgotLoading(true);
        try {
          await apiClient.post(
            "api/Account/ForgotPassword",
            {
              email: forgotEmailValue,
            },
            {
              headers: {
                Referer: window.location.host + "/resetPassword",
              },
            }
          );
          props.onSetVisible(false);
        } catch (e) {
          setForgotLoading(false);
          const error = e as AxiosError;
          setForgotError(
            stringifyErrors(error.response?.data.errors ?? error.response?.data)
          );
        }
        setForgotLoading(false);
      }}
    >
      {forgotError && (
        <>
          <Alert
            message="Error"
            description={forgotError}
            type="error"
            showIcon
          />
          <Divider />
        </>
      )}
      <Input
        placeholder={"Your email address"}
        type={"email"}
        onChange={(event) => {
          setForgotEmailValue(event.target.value);
        }}
      />
    </Modal>
  );
};
