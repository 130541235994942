export const stringifyErrors = (error: any) => {
  let e = "";
  for (let k in error) {
    // noinspection JSUnfilteredForInLoop
    e += `${k}: ${error[k].join("\n")} \n`;
  }
  return e;
};

// export const extractErrors = (err: any): string[] => {
//   try {
//     if (err.response.data.errors) {
//       return Object.keys(err.response.data.errors).map(
//         (key) => err.response.data.errors[key][0]
//       );
//     }

//     if (err.response.data) {
//       return Object.keys(err.response.data).map(
//         (key) => err.response.data[key]
//       );
//     }
//   } catch (_e) {
//     return ["There was an error. Please try again later"];
//   }
//   return ["There was an error. Please try again later"];
// };
