import { apiClient } from "../../ApiClient";
import { createAsyncThunk } from "@reduxjs/toolkit";
import { createAuthConfig } from "../auth/api";
import { RecipesCategory, Recipe, APIRecipe } from "./models";

export const getRecipesCategories = createAsyncThunk(
  "/api/Recipes/GetCagegories",
  async (
    _params,
    thunkAPI
  ): Promise<
    RecipesCategory[] | ReturnType<typeof thunkAPI.rejectWithValue>
  > => {
    try {
      const res = await apiClient.get<RecipesCategory[]>(
        `api/RecipeCategories`,
        createAuthConfig()
      );
      res.data.forEach((category) =>
        thunkAPI.dispatch(getRecipesByCategory(category?.id as string))
      );
      return res.data;
    } catch (e) {
      if (e.response && e.response.data && e.response.data.status === 400) {
        return thunkAPI.rejectWithValue(
          [].concat.apply([], Object.values(e.response.data.errors)).join(" ")
        );
      }
      return thunkAPI.rejectWithValue("Error getting recipe categories");
    }
  }
);

export const addRecipesCategory = createAsyncThunk(
  "/api/Recipes/AddCategory",
  async (
    params: RecipesCategory,
    thunkAPI
  ): Promise<RecipesCategory | ReturnType<typeof thunkAPI.rejectWithValue>> => {
    try {
      const res = await apiClient.post<RecipesCategory>(
        "api/RecipeCategories",
        params,
        createAuthConfig()
      );
      thunkAPI.dispatch(getRecipesByCategory(res.data.id as string));
      thunkAPI.dispatch(getRecipesCategories());
      return res.data;
    } catch (e) {
      if (e.response && e.response.data && e.response.data.status === 400) {
        return thunkAPI.rejectWithValue(
          [].concat.apply([], Object.values(e.response.data.errors)).join(" ")
        );
      }
      return thunkAPI.rejectWithValue("Error adding recipe category");
    }
  }
);

export const editRecipesCategory = createAsyncThunk(
  "/api/Recipes/EditCategory",
  async (
    params: RecipesCategory,
    thunkAPI
  ): Promise<RecipesCategory | ReturnType<typeof thunkAPI.rejectWithValue>> => {
    try {
      const res = await apiClient.post<RecipesCategory>(
        `api/RecipeCategories/EditRecipeCategory/${params.id}`,
        params,
        createAuthConfig()
      );
      thunkAPI.dispatch(getRecipesCategories());
      thunkAPI.dispatch(getRecipesByCategory(res.data.id as string));
      return res.data;
    } catch (e) {
      if (e.response && e.response.data && e.response.data.status === 400) {
        return thunkAPI.rejectWithValue(
          [].concat.apply([], Object.values(e.response.data.errors)).join(" ")
        );
      }
      return thunkAPI.rejectWithValue("Error editing recipe category");
    }
  }
);

export const deleteRecipesCategory = createAsyncThunk(
  "/api/Recipes/DeleteCategory",
  async (
    rcId: string,
    thunkAPI
  ): Promise<RecipesCategory | ReturnType<typeof thunkAPI.rejectWithValue>> => {
    try {
      const res = await apiClient.post<any>(
        `/api/RecipeCategories/${rcId}`,
        createAuthConfig()
      );
      thunkAPI.dispatch(getRecipesCategories());
      thunkAPI.dispatch(getRecipeOfTheWeek());
      return res.data;
    } catch (e) {
      if (e.response && e.response.data && e.response.data.status === 400) {
        return thunkAPI.rejectWithValue(
          [].concat.apply([], Object.values(e.response.data.errors)).join(" ")
        );
      }
      return thunkAPI.rejectWithValue("Error deleting recipe cateogry");
    }
  }
);

export const getAllRecipes = createAsyncThunk(
  "api/Recipes/GetRecipes",
  async (
    params: string,
    thunkAPI
  ): Promise<Recipe[] | ReturnType<typeof thunkAPI.rejectWithValue>> => {
    try {
      const res = await apiClient.get<Recipe[]>(
        "/api/Recipes/",
        createAuthConfig()
      );
      return res.data;
    } catch (e) {
      if (e.response && e.response.data && e.response.data.status === 400) {
        return thunkAPI.rejectWithValue(
          [].concat.apply([], Object.values(e.response.data.errors)).join(" ")
        );
      }
      return thunkAPI.rejectWithValue("Error getting recipes");
    }
  }
);

export const getRecipesByCategory = createAsyncThunk(
  "api/Recipes/GetRecipesByCategory",
  async (
    rcId: string,
    thunkAPI
  ): Promise<APIRecipe[] | ReturnType<typeof thunkAPI.rejectWithValue>> => {
    try {
      const res = await apiClient.get<APIRecipe[]>(
        `/api/Recipes/ForRecipeCategory/${rcId}`,
        createAuthConfig()
      );
      return res.data;
    } catch (e) {
      if (e.response && e.response.data && e.response.data.status === 400) {
        return thunkAPI.rejectWithValue(
          [].concat.apply([], Object.values(e.response.data.errors)).join(" ")
        );
      }
      return thunkAPI.rejectWithValue("Error getting recipes");
    }
  }
);

export const addRecipes = createAsyncThunk(
  "api/Recipes/AddRecipes",
  async (
    params: APIRecipe[],
    thunkAPI
  ): Promise<APIRecipe[] | ReturnType<typeof thunkAPI.rejectWithValue>> => {
    try {
      const res = await Promise.all(
        params.map(async (recipe: APIRecipe) => {
          try {
            const res = await apiClient.post<APIRecipe>(
              "api/Recipes",
              recipe,
              createAuthConfig()
            );
            return res.data;
          } catch (e) {
            throw e;
          }
        })
      );
      thunkAPI.dispatch(
        getRecipesByCategory(params[0].recipeCategory.id as string)
      );
      return res;
    } catch (e) {
      if (e.response && e.response.data && e.response.data.status === 400) {
        return thunkAPI.rejectWithValue(
          [].concat.apply([], Object.values(e.response.data.errors)).join(" ")
        );
      }
      return thunkAPI.rejectWithValue("Error adding recipes");
    }
  }
);

export const addRecipe = createAsyncThunk(
  "api/Recipes/AddRecipe",
  async (
    params: APIRecipe,
    thunkAPI
  ): Promise<APIRecipe | ReturnType<typeof thunkAPI.rejectWithValue>> => {
    try {
      const res = await apiClient.post<APIRecipe>(
        "api/Recipes",
        params,
        createAuthConfig()
      );
      thunkAPI.dispatch(
        getRecipesByCategory(res.data.recipeCategory.id as string)
      );
      return res.data;
    } catch (e) {
      if (e.response && e.response.data && e.response.data.status === 400) {
        return thunkAPI.rejectWithValue(
          [].concat.apply([], Object.values(e.response.data.errors)).join(" ")
        );
      }
      return thunkAPI.rejectWithValue("Error adding recipe");
    }
  }
);

export const editRecipe = createAsyncThunk(
  "api/Recipes/EditRecipe",
  async (
    recipe: APIRecipe,
    thunkAPI
  ): Promise<APIRecipe | ReturnType<typeof thunkAPI.rejectWithValue>> => {
    try {
      const res = await apiClient.post<APIRecipe>(
        `api/Recipes/EditRecipe/${recipe.id}`,
        recipe,
        createAuthConfig()
      );
      thunkAPI.dispatch(
        getRecipesByCategory(res.data.recipeCategory.id as string)
      );
      return res.data;
    } catch (e) {
      if (e.response && e.response.data && e.response.data.status === 400) {
        return thunkAPI.rejectWithValue(
          [].concat.apply([], Object.values(e.response.data.errors)).join(" ")
        );
      }
      return thunkAPI.rejectWithValue("Error editing recipe");
    }
  }
);

export const deleteRecipe = createAsyncThunk(
  "api/Recipes/DeleteRecipe",
  async (
    recipeId: string,
    thunkAPI
  ): Promise<APIRecipe | ReturnType<typeof thunkAPI.rejectWithValue>> => {
    try {
      const res = await apiClient.post<any>(
        `api/Recipes/${recipeId}`,
        createAuthConfig()
      );
      thunkAPI.dispatch(
        getRecipesByCategory(res.data.recipeCategory.id as string)
      );
      thunkAPI.dispatch(getRecipeOfTheWeek());
      return res.data;
    } catch (e) {
      if (e.response && e.response.data && e.response.data.status === 400) {
        return thunkAPI.rejectWithValue(
          [].concat.apply([], Object.values(e.response.data.errors)).join(" ")
        );
      }
      return thunkAPI.rejectWithValue("Error deleting recipe xxxxxxxxx");
    }
  }
);

export const getRecipeOfTheWeek = createAsyncThunk(
  "api/Recipes/GetRecipeOfTheWeek",
  async (
    _params,
    thunkAPI
  ): Promise<APIRecipe | ReturnType<typeof thunkAPI.rejectWithValue>> => {
    try {
      const res = await apiClient.get<APIRecipe>(
        "api/Recipes/RecipeOfTheWeek",
        createAuthConfig()
      );
      return res.data;
    } catch (e) {
      if (e.response && e.response.data && e.response.data.status === 400) {
        return thunkAPI.rejectWithValue(
          [].concat.apply([], Object.values(e.response.data.errors)).join(" ")
        );
      }
      return thunkAPI.rejectWithValue("Error getting recipe of the week");
    }
  }
);

export const setRecipeOfTheWeek = createAsyncThunk(
  "api/Recipes/SetRecipeOfTheWeek",
  async (
    id: string,
    thunkAPI
  ): Promise<APIRecipe | ReturnType<typeof thunkAPI.rejectWithValue>> => {
    try {
      const res = await apiClient.get<APIRecipe>(
        `api/Recipes/RecipeOfTheWeek/${id}`,
        createAuthConfig()
      );
      // thunkAPI.dispatch(getRecipeOfTheWeek());
      thunkAPI.dispatch(
        getRecipesByCategory(res.data.recipeCategory.id as string)
      );
      return res.data;
    } catch (e) {
      if (e.response && e.response.data && e.response.data.status === 400) {
        return thunkAPI.rejectWithValue(
          [].concat.apply([], Object.values(e.response.data.errors)).join(" ")
        );
      }
      return thunkAPI.rejectWithValue("Error setting recipe of the week");
    }
  }
);
