import React, { useEffect, useState } from "react";
import { useSelector, useDispatch } from "react-redux";
import { getServices } from "./api";
import { Table, Alert, Select, Button } from "antd";
import { RootState } from "../../App";
import Search from "antd/lib/input/Search";
import ShowSubservicesModal from './ShowSubservicesModal';
import SubserviceIcon from '../../resources/UI-Web-btn-subservice-bar-chart-graph-YELLOW.png';
import { processDelete, showDeleteServicesModal, showEditServicesModal, showSubserviceOfServiceModal, showViewServicesModal } from "./slice";
import { getSubservicesByServiceId } from "../subservices/api";
import { EyeOutlined } from "@ant-design/icons";
import ViewServicesModal from "./ViewServicesModal";
import EditICon from '../../resources/ICON-edit-pencil.png';
import EditServiceModal from "./EditServiceModal";
import DeleteICon from '../../resources/ICON-trash-can-bin-delete-remove.png';
import DeleteServiceModal from "./DeleteServiceModal";
import moment from "moment";
import { BASE_URL } from "../../ApiClient";
const { Column } = Table;

export default function ServicesTable() {
  const dispatch = useDispatch();

  const services = useSelector((state: RootState) => state.services.all);
  const error = useSelector((state: RootState) => state.services.error);
  const retailerId = useSelector(
    (state: RootState) => state.retailers.currentRetailer?.id
  );

  const [replica, setReplica] = React.useState(services);
  const [searchType, setSearchType] = React.useState('name');

  const [deleteId, setDeleteId] = React.useState<number>();
  const [editId, setEditId] = React.useState<number>();

  const orderStorageName = "service_order"
  let localOrder = localStorage.getItem(orderStorageName);
  const [order, setOrder] = useState<string | null>(localOrder);
  const [jsonOrder, setJsonOrder] = useState(JSON.parse(order !== null ? order : JSON.stringify({ order: "ascend", column: "name" })));
  const [columnAttr, setcolumnAttr] = React.useState<object>({
    sortOrder: jsonOrder?.order == "ascend" ? "ascend" : "descend",
    sortDirections: ["ascend", "descend", "ascend"]
  });
  useEffect(() => {
    if (order == null) {
      localStorage.setItem(orderStorageName, JSON.stringify({ order: "ascend", column: "name" }));
      setOrder(JSON.stringify({ order: "ascend", column: "name" }))
      setJsonOrder({ order: "ascend", column: "name" });
    }
    if (order !== null) {
      setJsonOrder(JSON.parse(order));
    }
  }, [order]);

  const onHeaderCell = (columnName: string) => {
    return {
      onClick: () => {
        if (jsonOrder.order == "descend") {
          localStorage.setItem(orderStorageName, JSON.stringify({ order: "ascend", column: columnName }));
          setOrder(JSON.stringify({ order: "ascend", column: columnName }))
          setcolumnAttr({ ...columnAttr, sortOrder: "ascend" })
        }
        else {
          localStorage.setItem(orderStorageName, JSON.stringify({ order: "descend", column: columnName }));
          setOrder(JSON.stringify({ order: "descend", column: columnName }));
          setcolumnAttr({ ...columnAttr, sortOrder: "descend" })
        }
      },
    };
  }

  // ascend
  // handle change search 
  const handleChangeSearch = (e: { target: { value: string; }; }) => {
    if (e.target.value.length > 0) {
      let filteredData = searchType == 'name' ? services.filter(data => data.name.toLowerCase().includes(e.target.value.toLowerCase())) :
        searchType == 'uniqueID' ?
          services.filter(data => data.uniqueID.toLowerCase().includes(e.target.value.toLowerCase())) : services.filter(data => data.notes.toLowerCase().includes(e.target.value.toLowerCase()))
      setReplica(filteredData)
    } else {
      setReplica(services)
    }
  };


  useEffect(() => {
    dispatch(getServices({ retailerId: retailerId }));
  }, [dispatch, retailerId]);

  // handle Change search type function
  const handleChangeSearchType = (value: string) => {
    setSearchType(value)
  }

  useEffect(() => {
    setReplica(services)
  }, [services])

  // handle show subservice button click
  const handleShowSubserviceButtonClick = (row: any) => {
    dispatch(showSubserviceOfServiceModal(row.id))
    sessionStorage.setItem("serviceId", row.id);
    dispatch(getSubservicesByServiceId({ serviceId: Number(row.id) }))
  }
  // handle view button click
  const handleViewButtonClick = (row: any) => {
    dispatch(showViewServicesModal(row.id));
  };

  // handle edit button click
  const handleEditButtonClick = (row: any) => {
    setEditId(row.id)
    // setEditData(row)
    dispatch(showEditServicesModal(row.id));
  };

  // handle delete button click
  const handleDeleteButtonClick = async (row: any) => {
    const result = await fetch(`${BASE_URL}api/CheckRetailerServices/${row.id}`, { method: "POST" });
    const data = await result.json();
    setDeleteId(row.id)
    dispatch(processDelete(data))
    dispatch(showDeleteServicesModal(row.id));
  };
  return error ? (
    <Alert message="Error" description={error} type="error" showIcon />
  ) : (
    <>
      <div style={{ display: 'flex', flexDirection: 'row' }}>
        <div>
          <Select
            defaultValue="name"
            style={{ width: 250 }}
            options={[
              { value: 'name', label: 'Service Name' },
              { value: 'uniqueID', label: 'Unique ID' }

            ]}
            onChange={handleChangeSearchType}
          />
        </div>
        <Search placeholder="Search here" style={{ width: 300, marginBottom: '1em', marginLeft: '1em' }} allowClear onChange={handleChangeSearch} />
        <p style={{ width: 300, marginBottom: '1em', marginLeft: '1em' }}>Total Count: &nbsp; {replica.length} </p>
      </div>
      <Table
        // loading={services.length == 0 ? true : false}
        pagination={{ hideOnSinglePage: true }}
        dataSource={[...replica]
          .reverse()
          .map((el, index) => ({ ...el, index: index + 1, key: el.id }))}
      >
        <Column
          title="SL No"
          dataIndex="index"
          key="index"
          {...(jsonOrder.column == "index" && columnAttr)}
          onHeaderCell={(column) => onHeaderCell("index")}
          sorter={(a: any, b: any) => {
            if (a.index < b.index) { return -1; }
            if (a.index > b.index) { return 1; }
            return 0;
          }} />

        <Column title="Service Name"
          {...(jsonOrder.column == "name" && columnAttr)}
          dataIndex="name" key="name"
          sorter={(a: any, b: any) => {
            if (a.name < b.name) { return -1; }
            if (a.name > b.name) { return 1; }
            return 0;
          }}
          onHeaderCell={(column) => onHeaderCell("name")}
        />
        <Column title="Unique ID" dataIndex="uniqueID" key="uniqueID"
          sorter={(a: any, b: any) => {
            if (a.name < b.name) { return -1; }
            if (a.name > b.name) { return 1; }
            return 0;
          }}
          {...(jsonOrder.column == "uniqueID" && columnAttr)}
          onHeaderCell={(column) => onHeaderCell("uniqueID")}
        />
        <Column title="Created On" key='created_at'
          render={(row: any) => {
            return moment(row.created_at).format("MM/DD/YYYY")
          }}
          sorter={(a: any, b: any) => {
            if (a.created_at < b.created_at) { return -1; }
            if (a.created_at > b.created_at) { return 1; }
            return 0;
          }}
          {...(jsonOrder.column == "created_at" && columnAttr)}
          onHeaderCell={(column) => onHeaderCell("created_at")}
        />

        <Column title="Updated On" key='updated_at'
          render={(row: any) => {
            return row.updated_at !== null ? moment(row.updated_at).format("MM/DD/YYYY") : "-";
          }}
          sorter={(a: any, b: any) => {
            if (a.updated_at < b.updated_at) { return -1; }
            if (a.updated_at > b.updated_at) { return 1; }
            return 0;
          }}
          {...(jsonOrder.column == "updated_at" && columnAttr)}
          onHeaderCell={(column) => onHeaderCell("updated_at")}
        />

        <Column title="Subservices" align="center" key="subservices" render={(row: any) => {
          return (<div>
            <Button title="Subservice" type="link" onClick={() => handleShowSubserviceButtonClick(row)}>
              <img src={SubserviceIcon} alt="subservice icon" style={{ height: '24px', width: '24px', margin: '0 0.8em' }} />
            </Button>
            <ShowSubservicesModal style={{ width: 100, height: 100 }} row={row} />
          </div>)
        }} />
        <Column title="View" align="center" key="view" render={(row: any) => {
          return (<div>
            <Button title="View"
              type="link" onClick={() => handleViewButtonClick(row)}>
              <EyeOutlined style={{ color: '#565050', height: '24px', width: '24px', margin: '0 0.8em' }} />
            </Button>
            <ViewServicesModal row={row} />
          </div>)
        }} />

        <Column title="Edit" align="center" key="edit" render={(row: any) => {
          return (<div>
            <Button title="Edit" type="link" onClick={() => handleEditButtonClick(row)}>
              <img src={EditICon} alt="Edit Icon" style={{ height: '24px', width: '24px', margin: '0 0.8em' }} />
            </Button>
            {
              (editId && editId == row.id) &&
              <EditServiceModal row={row} />
            }
          </div>)
        }} />

        <Column title="Delete" align="center" key="delete" render={(row: any) => {
          return (<div>
            <Button
              title="Delete"
              type="link" danger onClick={() => handleDeleteButtonClick(row)}>
              <img src={DeleteICon} alt="Edit Icon" style={{ height: '24px', width: '24px', margin: '0 0.8em' }} />
            </Button>
            {
              (deleteId && deleteId == row.id) &&
              <DeleteServiceModal row={row} />
            }
          </div>)
        }} />
      </Table>
    </>
  );

}
