import React, { useState } from "react";
import { Link } from "react-router-dom";
import { Menu } from "antd";
import {
  ShoppingCartOutlined,
  BookOutlined,
  FolderOutlined,
  UserOutlined,
  SnippetsOutlined,
  ShopOutlined,
  CopyOutlined,
  MailOutlined,
  SwapOutlined,
  AreaChartOutlined
} from "@ant-design/icons";
import styled from "styled-components";
import { RootState } from "../App";
import { useSelector } from "react-redux";
import ServiceIcon from '../resources/UI-Web-btn-service-bar-chart-graph.png';
import SubserviceIcon from '../resources/UI-Web-btn-subservice-bar-chart-graph.png';
import PropertyIcon from '../resources/UI-Web-btn-keys-property.png';


const Container = styled.div`
  height: 100%;
  box-shadow: 0 2px 8px rgba(0, 0, 0, 0.15);
`;

export const Sidebar = () => {
  const permissions = useSelector(
    (state: RootState) => state.login.authData?.claims.Permission ?? []
  );
  const [epicStatus, setEpicStatus] = useState(false)
  const handleEpicClick = () => {
    setEpicStatus(!epicStatus)
  }
  return (
    <Container>
      <Menu style={{ borderRight: "none" }} mode="inline">
        {permissions.find((p) => p === "IsAdministrator") !== undefined && (
          <Menu.Item>
            <ShoppingCartOutlined />
            <Link to="/dashboard/retailers">Retailers</Link>
          </Menu.Item>
        )}

        {permissions.find((p) => p === "IsAdministrator") !== undefined && (
          <Menu.Item>
            <BookOutlined />
            <Link to="/dashboard/categories">Categories</Link>
          </Menu.Item>
        )}

        {permissions.find((p) => p === "IsRetailer") !== undefined && (
          <Menu.Item>
            <FolderOutlined />
            <Link to="/dashboard/circular-groups">Circular Groups</Link>
          </Menu.Item>
        )}

        {permissions.find((p) => p === "IsRetailer") !== undefined && (
          <Menu.Item>
            <UserOutlined />
            <Link to="/dashboard/users">Users</Link>
          </Menu.Item>
        )}

        {permissions.find((p) => p === "IsRetailer") !== undefined && (
          <Menu.Item>
            <SnippetsOutlined />
            <Link to="/dashboard/circulars">Circulars</Link>
          </Menu.Item>
        )}

        {permissions.find((p) => p === "IsRetailer") !== undefined && (
          <Menu.Item>
            <ShopOutlined />
            <Link to="/dashboard/stores">Stores</Link>
          </Menu.Item>
        )}

        {permissions.find((p) => p === "IsAdministrator") !== undefined && (
          <Menu.Item>
            <CopyOutlined />
            <Link to="/dashboard/recipes">Recipes</Link>
          </Menu.Item>
        )}

        {permissions.find((p) => p === "IsRetailer") !== undefined && (
          <Menu.Item>
            <MailOutlined />
            <Link to="/dashboard/emails">Emails</Link>
          </Menu.Item>
        )}
        <Menu.SubMenu title={<>
          <AreaChartOutlined />
          <span>EpicCyberAnalytics</span>
        </>}>
          {permissions.find((p) => p === "IsRetailer") !== undefined && (
            <Menu.Item>
              <SwapOutlined style={{ height: '24px', width: '24px', margin: '0 0.8em', backgroundColor: 'rgb(231 231 231)', padding: '4px', borderRadius: '3px' }} />
              <Link to="/dashboard/connections">Connections</Link>
            </Menu.Item>
          )}
          {permissions.find((p) => p === "IsRetailer") !== undefined && (
            <Menu.Item>
              <img src={ServiceIcon} alt="Services Icon" style={{ height: '24px', width: '24px', margin: '0 0.8em' }} />
              <Link to="/dashboard/services">Services</Link>
            </Menu.Item>
          )}
          {permissions.find((p) => p === "IsRetailer") !== undefined && (
            <Menu.Item>
              <img src={SubserviceIcon} alt="Services Icon" style={{ height: '24px', width: '24px', margin: '0 0.8em' }} />
              <Link to="/dashboard/subservices">Subservices</Link>
            </Menu.Item>
          )}
          {permissions.find((p) => p === "IsRetailer") !== undefined && (
            <Menu.Item>
              <img src={PropertyIcon} alt="Services Icon" style={{ height: '24px', width: '24px', margin: '0 0.8em' }} />
              <Link to="/dashboard/properties">Properties</Link>
            </Menu.Item>
          )}
        </Menu.SubMenu>
      </Menu>
    </Container>
  );
};
