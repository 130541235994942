import { createSlice, PayloadAction } from "@reduxjs/toolkit";
import { getSubservices, addSubservice, editSubservice, deleteSubservice, getSubservicesByServiceId } from "./api";
import { Subservice } from "./models";

export type SubserviceState = {
  all: Subservice[];
  subServiceByServiceId: Subservice[],
  currentSubservice?: Subservice;
  loading: boolean;
  error: string | null;
  showAddModal: boolean;
  addSubserviceLoading: boolean;
  addSubserviceError: string | null;
  showEditModal: string | null;
  editSubserviceLoading: boolean;
  editSubserviceError: string | null;
  showDeleteModal: string | null;
  showViewModal: string | null;
  viewSubserviceError: string | null;
  deleteSubserviceLoading: boolean;
  deleteSubserviceError: string | null;
  addSuccess: boolean;
  addError: boolean;
  editSuccess: boolean;
  editError: boolean;
  deleteSuccess: boolean;
  deleteError: boolean;

  processDeleteStatus : boolean;
  processDeleteMessage : string;
};

const initialState: SubserviceState = {
  all: [],
  subServiceByServiceId: [],
  currentSubservice: undefined,
  loading: false,
  error: null,
  addSubserviceError: null,
  showAddModal: false,
  showViewModal: null,
  addSubserviceLoading: false,
  showEditModal: null,
  editSubserviceLoading: false,
  editSubserviceError: null,
  showDeleteModal: null,
  deleteSubserviceLoading: false,
  deleteSubserviceError: null,
  viewSubserviceError: null,
  addSuccess: false,
  addError: false,
  editSuccess: false,
  editError: false,
  deleteSuccess: false,
  deleteError: false,

  processDeleteStatus : false,
  processDeleteMessage : ""
};

export const subservicesSlice = createSlice({
  name: "subservices",
  initialState,
  reducers: {
    setCurrentSubservice: (state, action: PayloadAction<Subservice>) => {
      state.currentSubservice = action.payload;
      state.editSubserviceError = null;
    },
    showAddSubserviceModal: (state) => {
      state.showAddModal = true;
    },
    hideAddSubserviceModal: (state) => {
      state.showAddModal = false;
      state.addSubserviceError = null;
    },
    showEditSubserviceModal: (state, action) => {
      state.showEditModal = action.payload;
    },
    hideEditSubserviceModal: (state) => {
      state.showEditModal = null;
      state.editSubserviceError = null;
    },
    showViewSubserviceModal: (state, action) => {
      state.showViewModal = action.payload;
    },
    hideViewSubserviceModal: (state) => {
      state.showViewModal = null;
      state.viewSubserviceError = null;
    },
    showDeleteSubserviceModal: (state, action) => {
      state.showDeleteModal = action.payload;
    },
    hideDeleteSubserviceModal: (state) => {
      state.showDeleteModal = null;
      state.deleteSubserviceError = null;
    },

    clearSubservices: (state) => {
      state.subServiceByServiceId = [];
    },
    clearSubserviceStatus: (state) => {
      state.addSuccess = false;
      state.addError = false;
      state.editSuccess = false;
      state.editError = false;
      state.deleteSuccess = false;
      state.deleteError = false;
    },
    processDeleteSubService : (state,action)=>{
      state.processDeleteMessage = action.payload.message;
      state.processDeleteStatus = action.payload.status;
    }
  },
  extraReducers: (builder) => {
    /* get Subservices */
    builder.addCase(getSubservices.pending, (state) => {
      state.loading = true;
    });
    builder.addCase(getSubservices.fulfilled, (state, action) => {
      state.all = action.payload;
      state.loading = false;
      state.editSubserviceError = null;
      state.error = null;
    });
    builder.addCase(getSubservices.rejected, (state, action) => {
      if (typeof action.payload === "string") {
        state.error = action.payload;
      }
    });

    /* add Subservice*/
    builder.addCase(addSubservice.pending, (state) => {
      state.addSubserviceLoading = true;
      state.addSubserviceError = null;
      state.addSuccess = false;
      state.addError = false;
    });
    builder.addCase(addSubservice.fulfilled, (state, action) => {
      state.all = [...state.all, action.payload];
      state.addSubserviceLoading = false;
      state.addSubserviceError = null;
      state.showAddModal = false;
      state.addSuccess = true;
      state.addError = false;
    });
    builder.addCase(addSubservice.rejected, (state, action) => {
      if (typeof action.payload === "string") {
        state.addSubserviceError = action.payload;
        state.addSubserviceLoading = false;
        state.addSuccess = false;
        state.addError = true;
      }
    });

    /* edit Subservice*/
    builder.addCase(editSubservice.pending, (state) => {
      state.editSubserviceLoading = true;
      state.editSubserviceError = null;
      state.editSuccess = false;
      state.editError = false;
    });
    builder.addCase(editSubservice.fulfilled, (state, action) => {
      state.all = state.all.map((item) =>
        item?.id === action?.payload?.id ? action.payload : item
      );
      state.editSubserviceLoading = false;
      state.editSubserviceError = null;
      state.showEditModal = null;
      state.editSuccess = true;
      state.editError = false;
    });
    builder.addCase(editSubservice.rejected, (state, action) => {
      if (typeof action.payload === "string") {
        state.editSubserviceError = action.payload;
        state.editSubserviceLoading = false;
        state.editSuccess = false;
        state.editError = true;
      }
    });

    /* delete Subservice*/
    builder.addCase(deleteSubservice.pending, (state) => {
      state.deleteSubserviceLoading = true;
      state.deleteSubserviceError = null;
      state.deleteSuccess = false;
      state.deleteError = false;
    });
    builder.addCase(deleteSubservice.rejected, (state, action) => {
      if (typeof action.payload === "string") {
        state.deleteSubserviceError = action.payload;
        state.deleteSubserviceLoading = false;
        state.deleteSuccess = false;
        state.deleteError = true;

      }
    });
    builder.addCase(deleteSubservice.fulfilled, (state, action) => {
      state.all = state.all.filter((item) => item.id !== action.payload);
      state.deleteSubserviceLoading = false;
      state.showDeleteModal = null;
      state.deleteSubserviceError = null;
      state.deleteSuccess = true;
      state.deleteError = false;
    });

    // getsubservice by service id
    builder.addCase(getSubservicesByServiceId.pending, (state) => {
      state.loading = true;
    });
    builder.addCase(getSubservicesByServiceId.fulfilled, (state, action) => {
      state.subServiceByServiceId = action.payload.sort((a, b) => {
        let fa = a.name.toLowerCase(),
          fb = b.name.toLowerCase();
        if (fa < fb) {
          return -1;
        }
        if (fa > fb) {
          return 1;
        }
        return 0;
      });
      state.loading = false;
      state.editSubserviceError = null;
      state.error = null;
    });
    builder.addCase(getSubservicesByServiceId.rejected, (state, action) => {
      if (typeof action.payload === "string") {
        state.error = action.payload;
      }
    });
  },
});

export const {
  showAddSubserviceModal,
  hideAddSubserviceModal,
  showEditSubserviceModal,
  hideEditSubserviceModal,
  showDeleteSubserviceModal,
  hideDeleteSubserviceModal,
  showViewSubserviceModal,
  hideViewSubserviceModal,
  clearSubservices,
  clearSubserviceStatus,
  processDeleteSubService
} = subservicesSlice.actions;
