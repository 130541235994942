import React from "react";
import { useDispatch, useSelector } from "react-redux";
import { editStore } from "./api";
import StoreFormModal, { StoreFormValues } from "./StoreFormModal";
import { RootState } from "../../App";
import { hideEditStoreModal } from "./slice";
import { Store } from "./models";

export default function EditStoreModal(props: { row: Store }) {
  const { row } = props;
  const dispatch = useDispatch();
  const visible = useSelector((state: RootState) => state.stores.showEditModal);
  const error = useSelector((state: RootState) => state.stores.editError);
  const loading = useSelector((state: RootState) => state.stores.editLoading);

  const handleCancel = () => {
    dispatch(hideEditStoreModal());
  };

  const handleOk = (values: Store | StoreFormValues | undefined | null) => {
    dispatch(editStore(values as Store));
  };

  return (
    <StoreFormModal
      title="Edit Store"
      visible={visible === row.id}
      onCancel={handleCancel}
      onOk={handleOk}
      initialValues={props.row}
      okText="Update"
      cancelText="Cancel"
      loading={loading}
      error={error}
    />
  );
}
