import { createAsyncThunk } from "@reduxjs/toolkit";
import axios from "axios";
import { CircularArea, CircularPage } from "../circulars/models";
import { apiClient } from "../../ApiClient";
import { createAuthConfig } from "../auth/api";
import { Actions } from "../Reducers";
import { RootState } from "../../App";
import { AreaFormValues } from "./AreaForm";


const RAPID_API_KEY = '24cbc6d15fmshebb81cbbe6f8977p178436jsn90ca66ace69a';
const RAPID_API_HOST = 'yummly2.p.rapidapi.com';
const RAPID_HOST= 'https://yummly2.p.rapidapi.com';

export const getPageDetails = createAsyncThunk(
  "api/CircularsPage/Get",
  async (
    _params: string,
    thunkAPI
  ): Promise<CircularPage | ReturnType<typeof thunkAPI.rejectWithValue>> => {
    try {
      const res = await apiClient.get<CircularPage>(
        `/api/CircularPages/${_params}`,
        createAuthConfig()
      );
      const page = res.data;
      thunkAPI.dispatch(Actions.editPage.pageLoaded(page));
      return page;
    } catch (e) {
      if (e.response && e.response.data && e.response.data.status === 400) {
        return thunkAPI.rejectWithValue(
          [].concat.apply([], Object.values(e.response.data.errors)).join(" ")
        );
      }
      return thunkAPI.rejectWithValue("Error getting circular pages");
    }
  }
);

export const getRecipes = createAsyncThunk(
  "api/CircularArea/Recipe",
  async (
    params: {
      ingredient: string;
      nrOfRecs: number;
    },
    _
  ) => {
    try {
      const headers = {
        "X-RapidAPI-Key": RAPID_API_KEY,
        "X-RapidAPI-Host": RAPID_API_HOST,
      };
      const res = await axios.get<any>(
        `${RAPID_HOST}/feeds/search`,
        {
          headers: headers,
          params: {
            maxResult: params.nrOfRecs,
            q: params.ingredient,
          },
        }
      );
      /*const fullRecipes = await Promise.allSettled(
        res.data.map(async (recipe: any) => {
          const recInfo = await axios.get<any>(
            `${RAPID_HOST}/recipes/${recipe.id}/information`,
            { headers: headers }
          );

          const sourceUrl = recInfo.data.sourceUrl;
          let recExtracted = null;
          try {
            recExtracted = await axios.get<any>(
              `${RAPID_HOST}/recipes/extract?url=${sourceUrl}`,
              { headers: headers }
            );
          } catch (e) {
            return null;
          }
          return {
            ...recipe,
            ...recInfo.data,
            ...recExtracted.data,
          };
        })
      );*/
      return {
        // list: fullRecipes
        //   .filter((res) => res !== null && res.status === "fulfilled")
        //   // @ts-ignore
        //   .map((res) => res.value),
        list: res.data.feed,
        ingredient: params.ingredient,
        nrOfRecs: params.nrOfRecs,
      };
      //return res.data.feed;
    } catch (e) {}
  }
);

export const postPage = createAsyncThunk(
  "api/Circular/PagePost",
  async (
    params: CircularPage,
    thunkAPI
  ): Promise<CircularPage | ReturnType<typeof thunkAPI.rejectWithValue>> => {
    try {
      const res = await apiClient.put<CircularPage>(
        `api/CircularsPages/${params.id}`,
        params,
        createAuthConfig()
      );
      return res.data;
    } catch (e) {
      if (e.response && e.response.data && e.response.data.status === 400) {
        return thunkAPI.rejectWithValue(
          [].concat.apply([], Object.values(e.response.data.errors)).join(" ")
        );
      }
      return thunkAPI.rejectWithValue("Error updating circular page");
    }
  }
);

export const saveArea = createAsyncThunk(
  "api/Circular/SaveArea",
  async (
    params: { pageId: string; area: CircularArea },
    thunkAPI
  ): Promise<CircularPage | ReturnType<typeof thunkAPI.rejectWithValue>> => {
    try {
      try {
        await apiClient.delete<CircularArea>(
          `api/CircularAreas/${params.area.id}`,
          createAuthConfig()
        );
      } catch (e) {}
      const editFormValues = (thunkAPI.getState() as RootState).editPage
        .editFormValues;

      //validate
      let errors = {} as AreaFormValues;
      // if (!editFormValues?.category ?? undefined) {
      //   errors.category = {} as Category;
      // }
      // if (
      //   !editFormValues?.ingredient ||
      //   editFormValues.ingredient.length === 0
      // ) {
      //   errors.ingredient = "error";
      // }
      // if (!editFormValues?.prefix || editFormValues.prefix.length === 0) {
      //   errors.prefix = "error";
      // }
      // if (!editFormValues?.deal || editFormValues.deal.length === 0) {
      //   errors.deal = "error";
      // }
      // if (!editFormValues?.details || editFormValues.details.length === 0) {
      //   errors.details = "error";
      // }
      if (Object.keys(errors).length > 0) {
        thunkAPI.dispatch(Actions.editPage.setAreaFormErrors(errors));
        return thunkAPI.rejectWithValue("");
      }

      const recipes = (thunkAPI.getState() as RootState).editPage.recipes;
      const areaToSave = {
        ...editFormValues,
        ...params.area,
        recepiesJSON: JSON.stringify(recipes),
      };
      const res = await apiClient.post<CircularPage>(
        `/api/CircularAreas/ForCircularPage/${params.pageId}`,
        areaToSave,
        createAuthConfig()
      );
      await thunkAPI.dispatch(
        getPageDetails(
          (thunkAPI.getState() as RootState).editPage?.page?.id ?? ""
        )
      );
      return res.data;
    } catch (e) {
      if (e.response && e.response.data && e.response.data.status === 400) {
        return thunkAPI.rejectWithValue(
          [].concat.apply([], Object.values(e.response.data.errors)).join(" ")
        );
      }
      return thunkAPI.rejectWithValue("Error updating circular area");
    }
  }
);

export const deleteCircularArea = createAsyncThunk(
  "api/Circular/DeleteCircularArea",
  async (
    params: string,
    thunkAPI
  ): Promise<CircularArea | ReturnType<typeof thunkAPI.rejectWithValue>> => {
    try {
      const res = await apiClient.delete<CircularArea>(
        `api/CircularAreas/${params}`,
        createAuthConfig()
      );
      await thunkAPI.dispatch(
        getPageDetails(
          (thunkAPI.getState() as RootState).editPage?.page?.id ?? ""
        )
      );
      return res.data;
    } catch (e) {
      if (e.response && e.response.data && e.response.data.status === 400) {
        return thunkAPI.rejectWithValue(
          [].concat.apply([], Object.values(e.response.data.errors)).join(" ")
        );
      }
      return thunkAPI.rejectWithValue("Error deleting circular page");
    }
  }
);
