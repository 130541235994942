import React from "react";
import { useDispatch, useSelector } from "react-redux";
import { addStore } from "./api";
import StoreFormModal from "./StoreFormModal";
import { RootState } from "../../App";
import { hideAddStoreModal } from "./slice";

export default function AddStoreModal() {
  const dispatch = useDispatch();
  const visible = useSelector((state: RootState) => state.stores.showAddModal);

  const loading = useSelector((state: RootState) => state.stores.addLoading);
  const error = useSelector((state: RootState) => state.stores.addError);

  const handleCancel = () => {
    dispatch(hideAddStoreModal());
  };

  const handleOk = (values: any) => {
    dispatch(addStore(values));
  };

  return (
    <StoreFormModal
      title="Add Store"
      visible={visible}
      onCancel={handleCancel}
      okText="Create"
      cancelText="Cancel"
      onOk={handleOk}
      loading={loading}
      error={error}
    />
  );
}
