import React, { useEffect } from "react";
import { Modal, notification } from "antd";
import { useDispatch, useSelector } from "react-redux";
import { deleteSubservice } from "./api";
import { RootState } from "../../App";
import { clearSubserviceStatus, hideDeleteSubserviceModal } from "./slice";

export default function DeleteModal(props: {
  row: { name: string; notes: string; id: string, uniqueID: string };
}) {
  const { row } = props;
  const dispatch = useDispatch();
  const visible = useSelector(
    (state: RootState) => state.subservices.showDeleteModal === row.id
  );
  const loading = useSelector(
    (state: RootState) => state.subservices.deleteSubserviceLoading
  );
  const error = useSelector(
    (state: RootState) => state.subservices.deleteSubserviceError
  );

  const { deleteSuccess, deleteError, processDeleteMessage, processDeleteStatus } = useSelector((state: RootState) => state.subservices);


  useEffect(() => {
    if (deleteSuccess) {
      notification.success({
        message: 'Success Message',
        description: `${row.name} subservice deleted successfully.`
      })
      dispatch(clearSubserviceStatus());
      dispatch(hideDeleteSubserviceModal());
    }
    if (deleteError) {
      notification.error({
        message: 'Error Message',
        description: error
      })
      dispatch(clearSubserviceStatus());
      dispatch(hideDeleteSubserviceModal());
    }
  }, [deleteError, deleteSuccess])

  // handle delete
  const handleDelete = () => {
    dispatch(deleteSubservice(row));
  };
  // handle cancel
  const handleCancel = () => {
    dispatch(hideDeleteSubserviceModal());
  };

  return (
    <Modal
      maskClosable={false}
      title="Delete Subservice"
      visible={visible}
      onCancel={handleCancel}
      okText="Delete"
      onOk={handleDelete}
      okButtonProps={{ danger: true, loading: loading ? loading : undefined }}
      destroyOnClose={true}
    >
      {
        processDeleteStatus == true ? processDeleteMessage :
          <h3>Are you sure you want to delete {row.name}?</h3>
      }
    </Modal>
  );
}
