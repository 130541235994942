import React, { ReactElement } from "react";
import { Col, Form, Row } from "antd";

export const InputWrapper = (props: {
  label: string;
  error?: string | undefined;
  children: ReactElement;
}) => {
  const { label, children, error } = props;
  return (
    <Row style={{ marginBottom: 24 }}>
      <Col span={4}>
        <div
          style={{
            display: "flex",
            justifyContent: "flex-end",
          }}
        >
          <label style={{ marginRight: 16 }}>{label}</label>
        </div>
      </Col>
      <Col span={20}>
        <Form.Item
          style={{ marginBottom: 0 }}
          validateStatus={!!error ? "error" : undefined}
          help={error}
        >
          {children}
        </Form.Item>
      </Col>
    </Row>
  );
};
