import React, { useEffect } from "react";
import { Modal, Form, Input, Divider, Alert, Select, DatePicker, notification } from "antd";

import { useDispatch, useSelector } from "react-redux";
import { addSubservice, getSubservices, getSubservicesByServiceId } from "./api";
import { RootState } from "../../App";
import { clearSubserviceStatus, hideAddSubserviceModal } from "./slice";
import { slug } from "../common/utils/slug";
import { BASE_URL } from "../../ApiClient";
import { createAuthConfig } from "../auth/api";

export default function AddSubserviceModal(props: any) {
  const [form] = Form.useForm();
  const [service, setService] = React.useState<String | null>(null);
  const retailerId = useSelector(
    (state: RootState) => state.subservices.currentSubservice?.id
  );

  const serviceOptions = useSelector((state: RootState) => state.services.all);

  const dispatch = useDispatch();

  const visible = useSelector(
    (state: RootState) => state.subservices.showAddModal
  );
  const loading = useSelector(
    (state: RootState) => state.subservices.addSubserviceLoading
  );
  const error = useSelector(
    (state: RootState) => state.subservices.addSubserviceError
  );

  const { addError, addSuccess } = useSelector(
    (state: RootState) => state.subservices
  );

  useEffect(()=>{
    if(addSuccess){
      notification.success({
        message: 'Success Message',
        description: "New subservice created successfully."
      })
      dispatch(clearSubserviceStatus());
    }
    if(addError){
      notification.error({
        message: 'Error Message',
        description: "Unable to create new subservice."
      })
      dispatch(clearSubserviceStatus());
    }
  },[addSuccess,addError])

  //Submit form 
  const submitForm = async (values: any) => {
    await dispatch(addSubservice(values));
    dispatch(getSubservices({ retailerId: retailerId }));
    let serviceId = sessionStorage.getItem("serviceId");
    if (serviceId != undefined) {
      dispatch(getSubservicesByServiceId({ serviceId: Number(serviceId) }))
    }
  };

  useEffect(() => {
    form.resetFields();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [visible]);

  // handle modal okay 
  const handleModalOk = () => {
    //validates values then sends them to submitForm()
    form
      .validateFields()
      .then((values) => {
        if (values.notes == undefined) {
          values.notes = "";
        }
        submitForm(values);
      })
      .catch((e) => { });
  };

  //handle Cancel button click
  const handleCancel = () => {
    form.resetFields();
    dispatch(hideAddSubserviceModal());
  };

  //handle form change
  const handleFormChange = (changedValues: any, allValues: any) => {
    if (changedValues.name && slug(changedValues.name) !== allValues.slug) {
      form.setFieldsValue({
        name: allValues.name,
        slug: slug(allValues.name),
      });
    }
  };

  // layout object
  const layout = {
    labelCol: { span: 8 },
    wrapperCol: { span: 16 },
  };

  // handle service change click
  const handleChangeService = (value: string) => {
    setService(value);
    form.setFieldsValue({ serviceID: value });
  }

  return (
    <Modal
      maskClosable={false}
      title="Add Subservice"
      visible={visible}
      okText="SAVE SUBSERVICE"
      onOk={handleModalOk}
      cancelText="CANCEL"
      onCancel={handleCancel}
      okButtonProps={loading ? { loading } : undefined}
      destroyOnClose={true}
    >
      {error && (
        <>
          <Alert message="Error" description={error} type="error" showIcon />
          <Divider />
        </>
      )}
      <Form
        {...layout}
        form={form}
        name="addSubservice"
        initialValues={{ name: "" }}
        onValuesChange={handleFormChange}
      >
        <Form.Item
          label="Subservice Name"
          name="name"
          rules={[{ required: true, message: "Subservice name is required!" }]}
        >
          <Input />
        </Form.Item>
        <Form.Item
          label="Service name"
          name="serviceID"
          rules={[{ required: true, message: "Service name is required!" }]}
        >
          <Select
            style={{ width: 315 }}
            onChange={handleChangeService}
          >
            {serviceOptions.map((option) => <Select.Option key={option.id} value={option.id}>
              {option.name}
            </Select.Option>
            )}
          </Select>
        </Form.Item>

        <Form.Item
          label="Website"
          name="website"
          rules={[{ type: "url", message: "Please enter a valid website url." }]}
        >
          <Input onKeyDown={(event) => {
            if (event.keyCode == 32)
              event.preventDefault();
          }} />
        </Form.Item>
        <Form.Item
          label="Unique ID:"
          name="uniqueID"
          validateTrigger="onBlur"
          rules={[
            { required: true, message: "Unique ID is required!" },
            {
              validator: async (_, value) => {
                let regex = new RegExp("^[a-zA-Z0-9_]*$");
                if (!regex.test(value)) {
                  return Promise.reject("Space and special characters is not allowed.[underscore (_) is allowed.]")
                }
                const result = await fetch(`${BASE_URL}api/GetSubServiceUniqueID?uniqueID=${value}`,createAuthConfig());
                const data = await result.json();
                if (data.message !== "UniqueID already exists") {
                  return Promise.resolve();
                }
                else {
                  return Promise.reject(data.message);
                }
              }
            }
          ]}
        >
          <Input />
        </Form.Item>

        <Form.Item label="Notes (Optional)" name="notes">
          <Input.TextArea rows={6} />
        </Form.Item>

      </Form>
    </Modal>
  );
}
