
import React, { useEffect, useState } from "react";
import { Modal, Form, Input, Alert, Divider, Select, notification } from "antd";

import { useDispatch, useSelector } from "react-redux";
import { editProperty, getProperties } from "./api";
import { RootState } from "../../App";

import { clearPropertiesStatus, hideViewPropertyModal, showEditPropertyModal } from "./slice";
import { slug } from "../common/utils/slug";
import { getServices } from "../services/api";

import { Service } from "../services/models";
import { Subservice } from "../subservices/models";
import { BASE_URL } from "../../ApiClient";
export default function EditModal(props: {
  row: {
    name: string;
    retailerID: string;
    id: string;
    uniqueID: string;
    pageID: string;
    address1: string;
    address2: string;
    city: string;
    state: string;
    zip: string;
    notes: string;
    serviceID: number;
    subserviceID: number;
    username: string;
    password: string;
    apiKey: string;
    apikey?: string;
  };
}) {
  const { row } = props;

  const dispatch = useDispatch();

  const [form] = Form.useForm();

  const [subserviceOptions, setSubserviceOptions] = useState<Subservice[]>([]);
  const retailers = useSelector((state: RootState) => state.retailers.all);

  const visible = useSelector(
    (state: RootState) => state.properties.showViewModal === row.id
  );

  const retailerId = useSelector(
    (state: RootState) => state.properties.currentProperty?.id
  );

  const loading = useSelector(
    (state: RootState) => state.properties.editPropertyLoading
  );

  const error = useSelector(
    (state: RootState) => state.properties.editPropertyError
  );

  // Submit Form
  const { editError, editSuccess } = useSelector((state: RootState) => state.properties);
  const services = useSelector((state: RootState) => state.services.all);
  const [retailerServices, setRetailerServices] = useState<Service[]>([]);
  const [subserviceArray, setSubserviceArray] = useState<string[]>([]);
  const [isLoadingSub,setIsLoadingSub] = useState<boolean>(false);
  useEffect(() => {
    if (editSuccess) {
      notification.success({
        message: 'Success Message',
        description: `${row.name} property updated successfully.`
      })
      dispatch(clearPropertiesStatus())
    }
    if (editError) {
      notification.error({
        message: 'Error Message',
        description: `Unable to update ${row.name} property.`
      })
      dispatch(clearPropertiesStatus())
    }
  }, [editError, editSuccess])

  useEffect(() => {
    dispatch(getServices({ retailerId: "" }));
    (async () => {
      await setServices(row.retailerID, false);
    })()
  }, [])

  useEffect(() => {
    (async () => {
      await setSubservice(row.serviceID, false);
    })()
  }, [subserviceArray])

  const submitForm = async (values: any) => {
    await dispatch(editProperty({ ...row, ...values }));
    dispatch(showEditPropertyModal(null));
    dispatch(getProperties({ retailerId: retailerId }));
  };

  // Handle Modal Okay
  const handleModalOk = () => {
    //validates values then sends them to submitForm()
    form
      .validateFields()
      .then((values) => {
        if (values.serviceID == "") {
          values.serviceID = 0
        }
        if (values.subserviceID == "") {
          values.subserviceID = 0
        }
        submitForm(values);
      })
      .catch((e) => { });
  };
  // Handle Cancel
  const handleCancel = () => {
    form.resetFields();
    dispatch(hideViewPropertyModal());
  };

  // Handle Form Change

  const handleFormChange = (changedValues: any, allValues: any) => {
    if (changedValues.name && slug(changedValues.name) !== allValues.slug) {
      form.setFieldsValue({
        name: allValues.name,
        slug: slug(allValues.name),
      });
    }
  };

  // layout object
  const layout = {
    labelCol: { span: 8 },
    wrapperCol: { span: 16 },
  };

  // Handle Change Retailer
  const handleChangeRetailer = async (value: string) => {
    form.setFieldsValue({ serviceID: "" })
    form.setFieldsValue({ subserviceID: "" })
    await setServices(value, true)

  };

  const setServices = async (retailerId: string, clear: boolean = true) => {
    if (clear) {
      setRetailerServices([]);
      setSubserviceOptions([])

    }
    setIsLoadingSub(true);
    const result = await fetch(`${BASE_URL}api/Retailers/${retailerId}`);
    let data = await result.json();
    setIsLoadingSub(false);
    const serviceArray = data.service?.split(",");
    const RetsubserviceArray = data.subservice?.split(",");

    if (RetsubserviceArray) {
      setSubserviceArray(RetsubserviceArray)
    }
    else {
      setSubserviceArray([])
    }
    if (serviceArray) {
      let serviceData = services.filter(item => serviceArray.includes(item.id.toString()))
      if (serviceData.length) {
        setRetailerServices(serviceData)
      }
    }
  }

  // Handle Change Service
  const handleChangeService = async (value: number) => {

    await setSubservice(value);
  }

  const setSubservice = async (serviceId: number, clear = true) => {
    if (clear) {
      form.setFieldsValue({ subserviceID: "" });
      setSubserviceOptions([])
    }

    const result = await fetch(`${BASE_URL}api/getsubservicedetail/${serviceId}`);
    const data = await result.json();
    let subServices = data.subservice.filter((item: any) => subserviceArray.includes(item.id.toString()));

    setSubserviceOptions(subServices)
    //form.setFieldsValue({ serviceID: serviceId });
  }



  // Handle Change Subservice
  const handleChangeSubservice = (value: string) => {
    // setSubservice(value);
  };


  return (
    <Modal
      maskClosable={false}
      title="View Property"
      visible={visible  && !isLoadingSub}
      cancelText="Close"
      onOk={handleCancel}
      onCancel={handleCancel}
      okButtonProps={loading ? { loading } : undefined}
      destroyOnClose={true}
    >
      {error && (
        <>
          <Alert message="Error" description={error} type="error" showIcon />
          <Divider />
        </>
      )}
      <Form
        // row.serviceID == 0 ? "" : row.serviceID
        {...layout}
        form={form}
        name={`editProperty#${row.id}`}
        initialValues={{
          name: row.name,
          retailerID: row.retailerID,
          id: row.id,
          uniqueID: row.uniqueID,
          pageID: row.pageID,
          address1: row.address1,
          address2: row.address2,
          city: row.city,
          state: row.state,
          zip: row.zip,
          notes: row.notes,
          serviceID: row.serviceID == 0 ? "" : row.serviceID,
          subserviceID: row.subserviceID == 0 ? "" : row.subserviceID,
          username: row.username,
          password: row.password,
          apikey: row.apikey,
        }}

        onValuesChange={handleFormChange}
      >
        <Form.Item
          label="Retailer "
          name="retailerID"
          rules={[{ required: true, message: "Retailer is required!" }]}
        >
          <Select style={{ width: 315 }} disabled={true} onChange={handleChangeRetailer}>
            {retailers.length && retailers.map((option) => (
              <Select.Option key={option.id} value={option.id}>
                {option.name}
              </Select.Option>
            ))}
          </Select>
        </Form.Item>
        <Form.Item
          label="Unique ID:"
          name="uniqueID"
          rules={[{ required: true, message: "Unique ID is required!" }]}
        >
          <Input disabled />
        </Form.Item>
        <Form.Item
          label="Page ID:"
          name="pageID"
        >
          <Input disabled />
        </Form.Item>
        <Form.Item
          label="Property name"
          name="name"
          rules={[{ required: true, message: "Property name is required!" }]}
        >
          <Input disabled={true} />
        </Form.Item>
        <Form.Item
          label="Address 1"
          name="address1"
        >
          <Input disabled={true} />
        </Form.Item>
        <Form.Item
          label="Address 2"
          name="address2"
        >
          <Input disabled={true} />
        </Form.Item>
        <div style={{ display: "flex", flexDirection: "row" }}>
          <Form.Item
            label="City"
            name="city"
            validateTrigger="onBlur"
            rules={[
              {
                validator: (rule, value) => {
                  if (value == "") return Promise.resolve()
                  let regex = new RegExp("^[a-zA-Z][\sa-zA-Z]*$");
                  if (regex.test(value)) {
                    return Promise.resolve()
                  }
                  else {
                    return Promise.reject("Number are not allowed.")

                  }
                }
              }]}
          >
            <Input
              placeholder="City"
              disabled={true}
              style={{ width: "8rem", marginRight: "5.5em" }}
            />
          </Form.Item>
          <Form.Item
            label=" State"
            name="state"
            validateTrigger="onBlur"
            rules={[
              {
                validator: (rule, value) => {
                  if (value == "") return Promise.resolve()
                  let regex = new RegExp("^[a-zA-Z][\sa-zA-Z]*$");
                  if (regex.test(value)) {
                    return Promise.resolve()
                  }
                  else {
                    return Promise.reject("Number are not allowed.")

                  }
                }
              }]}
          >
            <Input
              placeholder="State"
              disabled={true}
              style={{ width: "4rem", marginRight: ".5em" }}
            />
          </Form.Item>
          <Form.Item label=" ZIP" name="zip">
            <Input disabled={true} type="number" maxLength={5} placeholder="Zip" style={{ width: "5rem" }} />
          </Form.Item>
        </div>
        <Form.Item label="Notes (Optional)" name="notes">
          <Input.TextArea disabled={true} rows={6} />
        </Form.Item>
        {/* retailerServices.length > 0 ? true : false */}
        <Form.Item
          label="Service "
          name="serviceID"
          rules={[{ required: true, message: "Service is required!" }]}
        >
          <Select
            style={{ width: 315 }}
            disabled={true}
            onChange={handleChangeService}>
            {
              retailerServices.length && retailerServices.map((item, index) => {
                return (
                  <Select.Option key={index} value={item.id}>
                    {item.name}
                  </Select.Option>
                )
              })
            }
          </Select>
        </Form.Item>
        <Form.Item
          label="Subservice"
          name="subserviceID"
          rules={[{ required: subserviceOptions.length > 0 ? true : false, message: "Subservice is required!" }]}
        >
          <Select style={{ width: 315 }}
            // value={row.subserviceID?.toString()}
            disabled={true}
            onChange={handleChangeSubservice}
          >
            {subserviceOptions.map((option, index) => (
              <Select.Option key={index} value={option.id}>
                {option.name}
              </Select.Option>
            ))}
          </Select>
        </Form.Item>
        <Form.Item
          label="Username"
          name="username"
          rules={[{ required: true, message: "Username is required!" }]}
        >
          <Input disabled={true} />
        </Form.Item>
        <Form.Item
          label="Password"
          name="password"
          rules={[{ required: true, message: "Password is required!" }]}
        >
          <Input.Password disabled />
        </Form.Item>
        <Form.Item
          label="API Key"
          name="apikey"
          rules={[{ required: true, message: "API Key is required!" }]}
        >
          <Input disabled={true} onKeyDown={(event) => {
            if (event.keyCode == 32)
              event.preventDefault();
          }} />
        </Form.Item>
      </Form>
    </Modal>
  );
}

