import React from "react";
import { Modal, Form, Input, Alert, Divider, Select } from "antd";

import { useDispatch, useSelector } from "react-redux";
import { RootState } from "../../App";

import { hideViewServiceModal } from "./slice";

export default function ViewModal(props: {
    row: {
        name: string;
        uniqueID: string;
        id: string;
        notes: string;
        created_at: string;
        updated_at: string;
        retailerId: string;
    };
}) {
    const { row } = props;
    const dispatch = useDispatch();

    const [form] = Form.useForm();

    const visible = useSelector(
        (state: RootState) => state.services.showViewModal === row.id
    );

    const loading = useSelector(
        (state: RootState) => state.services.editServiceLoading
    );

    const error = useSelector(
        (state: RootState) => state.services.editServiceError
    );

    // const retailers = useSelector((state: RootState) => state.retailers.all);

    // handle cancel
    const handleCancel = () => {
        form.resetFields();
        dispatch(hideViewServiceModal());
    };

    // handle form change
    // const handleFormChange = (changedValues: any, allValues: any) => {
    //     if (changedValues.name && slug(changedValues.name) !== allValues.slug) {
    //         form.setFieldsValue({
    //             name: allValues.name,
    //             slug: slug(allValues.name),
    //         });
    //     }
    // };
    //layout
    const layout = {
        labelCol: { span: 8 },
        wrapperCol: { span: 16 },
    };
    return (
        <Modal
            maskClosable={false}
            title="View Service"
            visible={visible}
            cancelText="Close"
            onOk={handleCancel}
            onCancel={handleCancel}
            okButtonProps={loading ? { loading } : undefined}
            destroyOnClose={true}
        >
            {error && (
                <>
                    <Alert message="Error" description={error} type="error" showIcon />
                    <Divider />
                </>
            )}
            <Form
                {...layout}
                form={form}
                name={`editService#${row.id}`}
                initialValues={{
                    name: row.name,
                    uniqueID: row.uniqueID,
                    notes: row.notes,
                    retailerId: row.retailerId,
                }}
                // onValuesChange={handleFormChange}
            >
                {/* <Form.Item
                    label="Retailer Name"
                    name="retailerId"
                    rules={[{ required: true, message: "Retailer name is required!" }]}
                >
                    <Select disabled defaultValue={row.retailerId} >
                        {
                            retailers && retailers.map((item, index) => (
                                <Select.Option value={item.id} key={index}>{item.name} </Select.Option>
                            ))
                        }
                    </Select>
                </Form.Item> */}

                <Form.Item
                    label="Service name"
                    name="name"
                    rules={[{ required: true, message: "Service name is required!" }]}
                >
                    <Input disabled />
                </Form.Item>
                <Form.Item
                    label="Unique ID:"
                    name="uniqueID"
                    rules={[{ required: true, message: "Unique ID is required!" }]}
                >
                    <Input disabled />
                </Form.Item>
                <Form.Item label="Notes" name="notes">
                    <Input.TextArea rows={6} disabled />
                </Form.Item>
            </Form>
        </Modal>
    );
}
